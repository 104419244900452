import styled from "styled-components";

export const HomePageTabContainer = styled.ul`
  display: flex;
  flex-direciton: row;
  flex-wrap: wrap;
  justify-content: center;

  li {
    padding: 12px 20px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: ${(props) => props.theme.COLOR_PALETTE.PINK_PALETTE.TERTIARY};
    cursor: pointer;

    &:first-child {
      margin-right: 22px;
      border-bottom: 4px solid
        ${(props) => props.theme.COLOR_PALETTE.PINK_PALETTE.TERTIARY};
    }
  }
`;
