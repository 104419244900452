import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';
import "./styles.css";
import { Mixpanel } from '../Mixpanel';
import { OutlinedInput } from '@material-ui/core';
import { SEARCH_ACCESED, QUERY_SEARCHED } from '../constants/mixpanel';

const useStyles = (theme) => ({
  search: {
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
    width: '100%',
    height: 50,
  },
  input: {
    padding: 16,
  }

})

class MobileSearchBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    }
    this.myRef = React.createRef();
    this.onHandleChange = this.onHandleChange.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      this.myRef.current.blur();
      Mixpanel.track(
        QUERY_SEARCHED, {
        "branch_key": this.props.user_branch_key,
        "branch_name": this.props.user_branch_name,
        "email": this.props.cookies.get('user_email'),
        "search_text": e.target.value,
      });
      this.props.history.push(`/search?q=${e.target.value}`);
    }
  }

  onHandleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  onHandleClick = (event) => {
    Mixpanel.track(
      SEARCH_ACCESED, {
        "branch_key": this.props.user_branch_key,
        "branch_name": this.props.user_branch_name,
        "email": this.props.cookies.get('user_email'),
      });
    this.props.history.push('/mobilesearch')
  }
  render() {
    return (
      <div style={{ float: 'right' }} >
        <OutlinedInput
          type='text'
          placeholder='Search'
          value={this.state.value}
          onKeyDown={this.keyDown}
          className={this.props.classes.search}
          onChange={this.onHandleChange}
          onClick={this.onHandleClick}
          inputRef={this.myRef}
          inputProps={{
            className: this.props.classes.input,
        }}
          endAdornment={
            <InputAdornment>
              <ArrowForwardIcon style={{ cursor: 'pointer' }}
                onClick={() => { 
                  Mixpanel.track(
                    QUERY_SEARCHED, {
                    "branch_key": this.props.user_branch_key,
                    "branch_name": this.props.user_branch_name,
                    "email": this.props.cookies.get('user_email'),
                    "search_text": this.state.value,
                  });
                  this.props.history.push(`/search?q=${this.state.value}`);
                }}
              />
            </InputAdornment>
          }
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(MobileSearchBarComponent);
