import React from 'react';
import { getSearchHistory } from "../utils/common";
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete'
import "./styles.css";
import { TextField, Paper } from '@material-ui/core';
import { Mixpanel } from '../Mixpanel';
import { SEARCH_ACCESED, QUERY_SEARCHED } from '../constants/mixpanel';

import { Search } from "images"

const CustomPaper = (props) => {
  return <Paper elevation={2} {...props} />;
};

const AutocompleteStyles = withStyles({
  root: {
    '& .MuiInputBase-root': {
      height: '47px'
    },
    '& .MuiAutocomplete-root': {
      borderRadius: '4px',
      color: '#959595',
      paddingRight: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      color: '#959595',
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: '#959595',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#959595'
    },
    '& .MuiInputAdornment-root': {
      display: 'inline-table'
    }
  },
})(Autocomplete)

class SearchBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOptions: [],
      value: '',
      focus: false
    }
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.onHandleClick= this.onHandleClick.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  getPastSearchData = () => {
    getSearchHistory(this.props.fetchKey())
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.success) {
          if (result.data.length > 0) {
            this.setState({
              searchOptions: result.data,
            });
          }
        }
        else {
          // this.props.enqueueSnackbar("Something went wrong", {
          //   variant: "error",
          //   autoHideDuration: 1500
          // })
        }
      })
      .catch(err => {
        // this.props.enqueueSnackbar("Something went wrong", {
        //   variant: "error",
        //   autoHideDuration: 1500
        // })
        this.props.history.push('/');
      })
  }

  onHandleChange = (event, values) => {
    this.setState({ value: values },
      () => {
        Mixpanel.track(
          QUERY_SEARCHED, {
          "branch_key": this.props.user_branch_key,
          "branch_name": this.props.user_branch_name,
          "email": this.props.cookies.get('user_email'),
          "search_text": this.state.value,
        });
        this.props.history.push(`/search?q=${this.state.value}`);
      }
    );
  }

  onInputChange = (event, values) => {
    this.setState({value: values });
    this.setState({focus:true});
  }

  keyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      Mixpanel.track(
        QUERY_SEARCHED, {
        "branch_key": this.props.user_branch_key,
        "branch_name": this.props.user_branch_name,
        "email": this.props.cookies.get('user_email'),
        "search_text": e.target.value,
      });
      this.setState({focus:false});
      this.props.history.push(`/search?q=${e.target.value}`);
    }
  }

  onHandleClick = (e) => {
    Mixpanel.track(
      SEARCH_ACCESED , {
        "branch_key": this.props.user_branch_key,
        "branch_name": this.props.user_branch_name,
        "email": this.props.cookies.get('user_email'),
      });
    this.getPastSearchData();
  }

  handleOpen = () => {
    if (this.state.value.length > 0) {
      this.setState({focus:true})
    }
  }

  componentDidMount = () => {
    this.getPastSearchData();
  }

  render() {
    return (
      <AutocompleteStyles
        freeSolo
        value={this.state.value}
        disableClearable
        options={this.state.searchOptions}
        onInputChange={this.onInputChange}
        onKeyDown={this.keyDown}
        open={this.state.focus}
        onOpen={this.handleOpen}
        onClose={()=> this.setState({focus:false})}
        onChange={this.onHandleChange}
        PaperComponent={CustomPaper}
        renderInput={(params) =>
          <TextField {...params}
            variant="outlined"
            placeholder='Find Courses'
            onClick={this.onHandleClick }
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Search className='search-icon'
                      onClick={() => {
                        this.setState({focus: false});
                        Mixpanel.track(
                          QUERY_SEARCHED, {
                          "branch_key": this.props.user_branch_key,
                          "branch_name": this.props.user_branch_name,
                          "email": this.props.cookies.get('user_email'),
                          "search_text": this.state.value,
                        });
                        this.props.history.push(`/search?q=${this.state.value}`);
                        }
                      }
                    />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              )
            }}
          />
        }
      />
    )
  }
}

export default SearchBarComponent;
