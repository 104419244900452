import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const AdminCustomerPitchPage = React.lazy(() =>
  import("pages/customerPitch/adminCustomerPitch")
);
const CreateCustomerPitchPage = React.lazy(() =>
  import("pages/customerPitch/createCustomerPitch")
);
const CreateCustomerPitchQuestionPage = React.lazy(() =>
  import("pages/customerPitch/createCustomerPitchQuestion")
);
const CreateChecklistQuestion = React.lazy(() =>
  import("components/customerPitch/createChecklistQuestion")
);

export default [
  /* =============================== Customer Pitch ===============================*/
  {
    path: "/branches/:id/customerpitch",
    Component: AdminCustomerPitchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/customerpitch/create",
    Component: CreateCustomerPitchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/customerpitch/edit/:customerPitch",
    Component: CreateCustomerPitchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/customerpitch/:customerPitch/create-question",
    Component: CreateCustomerPitchQuestionPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/customerpitch/:customerPitch/edit/:question",
    Component: CreateCustomerPitchQuestionPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/customerpitch/:customerPitch/:question/create-checklist-question",
    Component: CreateChecklistQuestion,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/customerpitch/:customerPitch/:question/edit/:check/",
    Component: CreateChecklistQuestion,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
];
