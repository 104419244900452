import React from "react";
import styled from "styled-components";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonContainer = styled.div`
  margin: 30px 0;

  > span {
    span {
      margin: ${(props) => (props.isMobile ? "0 10px 10px 0" : "0 24px 24px 0")};
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const CardSkeleton = ({ isMobile }) => {
    return (
      <SkeletonContainer isMobile={isMobile}>
        <Skeleton
          inline
          count={isMobile ? 3 : 6}
          width={isMobile ? "calc((100% - 20px) / 3)" : "calc((100% - 120px) / 6)"}
          height={isMobile ? "217px" : "288px"}
          borderRadius={12}
        />
    </SkeletonContainer>
    );
}

export default CardSkeleton;
