import React from "react";
import styled from "styled-components";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonContainer = styled.div`
  margin: 30px 0;

  > span {
    span {
      margin: ${(props) => (props.isMobile ? "0 0 10px 0" : "0 24px 10px 0")};
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const CardSkeleton = ({ isMobile, hideContentSkeleton }) => {
  return (
    <SkeletonContainer isMobile={isMobile}>
      <Skeleton
        inline
        count={isMobile ? 1 : 3}
        width={isMobile ? "100%" : "calc((100% - 48px) / 3)"}
        height={isMobile ? "150px" : "200px"}
        borderRadius={12}
      />
      {!hideContentSkeleton ? (
        <>
          <Skeleton
            inline
            count={isMobile ? 1 : 3}
            width={isMobile ? "100%" : "calc((100% - 48px) / 3)"}
            borderRadius={12}
          />
          <Skeleton
            inline
            count={isMobile ? 1 : 3}
            width={isMobile ? "100%" : "calc((100% - 48px) / 3)"}
            borderRadius={12}
          />
        </>
      ) : null}
    </SkeletonContainer>
  );
};

export default CardSkeleton;
