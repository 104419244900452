import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { PrevIcon, NextIcon } from "pages/NewHomePage/images";

import { BannerCardContainer } from "./styles/style.js";
import Container from "./container";

const SlickButtonFix = ({ currentSlide, slideCount, children, typeOfClick, arrowClickHandler, arrowSliderRef, ...props }) => {
  return <div className={props.className} onClick={() => arrowClickHandler(typeOfClick, currentSlide, arrowSliderRef)}>{children}</div>
};


const BannerCard = (props) => {
  const { bannerCard, isMobile, isBannerCardFetching, bannerClickHandler, arrowClickHandler, arrowSliderRef, slideSwipeHandler, onTouchEnd } = props;
  
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: (
      <SlickButtonFix {...props} typeOfClick="left" arrowClickHandler={arrowClickHandler} arrowSliderRef={arrowSliderRef}>
        <PrevIcon />
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix {...props} typeOfClick="right" arrowClickHandler={arrowClickHandler} arrowSliderRef={arrowSliderRef}>
        <NextIcon />
      </SlickButtonFix>
    ),
    responsive: [
      {
        breakpoint: 995,
        settings: {
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return isBannerCardFetching ? (
    <Skeleton
      count={1}
      height={isMobile ? window.innerWidth / 2 : window.innerWidth / 5}
      borderRadius={12}
    />
  ) : !isBannerCardFetching && bannerCard.length ? (
    <BannerCardContainer onTouchEnd={() => onTouchEnd()} isMobile={isMobile}>
      <Slider {...settings} ref={slider => arrowSliderRef.current = slider} beforeChange={(beforeIndex, newIndex) => slideSwipeHandler(beforeIndex, newIndex)}>
        {bannerCard.map((banner) => {
          const { id, desktop_image_url, mobile_image_url, redirection_url } =
            banner;
          return (
            <div className="slider-container" key={id}>
              <a href={redirection_url} onClick={() => bannerClickHandler(banner)}>
                <figure className="partner-image">
                  <img
                    src={isMobile ? mobile_image_url : desktop_image_url}
                    alt="Banner Card"
                  />
                </figure>
              </a>
            </div>
          );
        })}
      </Slider>
    </BannerCardContainer>
  ) : null;
};

export default Container(BannerCard);
