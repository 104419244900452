import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const Homepage = React.lazy(() => import("pages/NewHomePage"));
const CreateBranchPage = React.lazy(() => import("pages/AdminControls/CreateBranch"));
const CreateExternalBranchPage = React.lazy(() => import("pages/addBranch"));
const BranchSetUpPage = React.lazy(() => import("pages/BranchSetUp"));
const CreateCourseReportConfig = React.lazy(() => import("pages/BranchSetUp/CourseReportConfig"));
const CreateDependentCourse = React.lazy(() => import("pages/BranchSetUp/DependentCourse"));


export default [
  //Branch Create and Edit
  {
    path: "/branches/create",
    Component: CreateBranchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
    ],
  },
  {
    path: "/branches/edit/:id",
    Component: CreateBranchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id",
    Component: Homepage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.marketing,
      constants.ROLES.content,
      constants.ROLES["l&d"],
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
    fullWidth: true
  },
  //External Branch
  {
    path: "/branches/:id/courses/:courseKey/branch/create",
    Component: CreateExternalBranchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
    ],
  },
  {
    path: "/branches/:id/content/:contentKey/branch/create",
    Component: CreateExternalBranchPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      // constants.ROLES.content,
      // constants.ROLES["l&d"],
      // constants.ROLES.tech,
    ],
  },
  {
    path: "/branches/:id/set-up",
    Component: BranchSetUpPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/set-up/course-report/create",
    Component: CreateCourseReportConfig,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/set-up/course-report/edit/:reportId",
    Component: CreateCourseReportConfig,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/set-up/dependent-course/create",
    Component: CreateDependentCourse,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/set-up/dependent-course/edit/:dependentCourseId",
    Component: CreateDependentCourse,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
];
