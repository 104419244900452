import React from "react";
import { MdModeEdit, MdStarRate } from "react-icons/md";

import CustomLinearProgressBar from "components/CustomLinearProgressBar";
import ModalPopup from "components/ModalPopup";

import LazyLoadImageContainer from "components/LazyLoadImageContainer";

import {
  CourseBlock,
  RatingBlock,
  NewTagBlock,
  CompletedContainer,
} from "./style/style";

import Container from "./container";

const NewCourseTile = (props) => {
  const {
    courseInfo: {
      key,
      image_url,
      name,
      total_subscribers,
      avg_rating,
      user_progress,
      category,
      price,
      original_price,
      show_rating,
      completed,
      is_independent_item,
      branch_key,
      source_category,
      branch_name,
      primary_category_info,
    },
    courseProgress,
    colorSchema,
    loggedProfileReducer: { isCourseAdminRole },
    handleCourseClicked,
    handleEditClick,
    content_source,
    purchased,
    accessPermission,
    handleModalClose,
  } = props;

  const RenderRatingOrNewTagBlock = () => {
    return (
      <div className="course-meta">
        {show_rating && !avg_rating ? (
          <NewTagBlock> New </NewTagBlock>
        ) : (
          <>
            {avg_rating ? (
              <RatingBlock colorSchema={colorSchema}>
                <MdStarRate />
                {avg_rating}
              </RatingBlock>
            ) : null}
            {total_subscribers ? (
              <span className="total-views">
                {avg_rating ? <span>|</span> : null}
                {total_subscribers > 1000
                  ? `${Math.ceil(total_subscribers / 1000)}k`
                  : total_subscribers}{" "}
                {total_subscribers > 1 ? "views" : "view"}
              </span>
            ) : null}
          </>
        )}
        {accessPermission ? (
          <ModalPopup customClose={(e) => handleModalClose(e)}>
            <div className="modal-inner-div">
              <p>
                Current Course belongs to <b>{branch_name}</b> branch. Please Go
                to parent branch <b>{branch_name}</b> for this action
              </p>
              <div>
                <div>
                  <button onClick={handleModalClose}>Go back</button>
                </div>
              </div>
            </div>
          </ModalPopup>
        ) : null}
      </div>
    );
  };

  return (
    <CourseBlock
      className="grid-h3"
      onClick={(e) =>
        handleCourseClicked(
          branch_key,
          key,
          category,
          is_independent_item,
          content_source,
          source_category,
          primary_category_info
        )
      }
    >
      {isCourseAdminRole ? (
        <span
          className="edit-icon-container"
          title="Edit"
          onClick={(e) =>
            handleEditClick(e, branch_key, key, is_independent_item)
          }
        >
          <MdModeEdit />
        </span>
      ) : null}
      <figure>
        {completed && <CompletedContainer>Completed</CompletedContainer>}
        <LazyLoadImageContainer
          imageDetails={{
            src: image_url
              ? image_url
              : "https://d3gthpli891tsj.cloudfront.net/wp-content/uploads/2019/01/22063215/GATE-Crash-Course.jpg",
            alt: "Course Thumbnail",
          }}
        />
      </figure>
      <div className="course-info-section">
        <h4 className="content-heading">{name}</h4>
        {!completed && (user_progress || courseProgress) ? (
          <CustomLinearProgressBar progress={user_progress || courseProgress} />
        ) :
          <RenderRatingOrNewTagBlock/>
        }
         {!purchased ? (
          (category === "paid" || primary_category_info?.pc_code === 'premium_courses') && !(user_progress || courseProgress) ? (
            <div className="price-div-container">
              <span className="price-span">&#8377; {price ? price : 9999}</span>
              <span className="original-price-span">
                <s>&#8377; {original_price ? original_price : 10000}</s>
              </span>
            </div>
          ) : null
        ) : null}
      </div>
    </CourseBlock>
  );
};

export default Container(NewCourseTile);
