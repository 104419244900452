/**
 * @description Object containing all contants values for the application.
 */
export default {
  ROLES: {
    user: "user",
    admin: "super_admin",
    content: "content",
    "l&d": "l_and_d",
    tech: "tech",
    marketing: "marketing",
    branch_admin: "branch_admin",
  },

  PERMISSION_ERROR: "Permission Denied.",
  DEVICE_DROPDOWN_OPTIONS: [
    {
      label: "Web",
      value: "web",
    },
    {
      label: "App",
      value: "app",
    },
  ],
};
