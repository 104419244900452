import styled from "styled-components";

export const TopHeaderContainer = styled.div`
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);

 .header-wrapper {

  height: 85px;
  padding: 0 24px;

  @media screen and (max-width: 540px) {
    padding: 0 16px;
  }

  .header-items-container {
    min-height: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .logo-container {
    padding: 1em 0;
  }

  .search-box-container {
    width: 49%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: ${(props) => props.theme.COLOR.DARK_GREY};

    @media screen and (max-width: 767px) {
      width: 24px;
    }

    .MuiInputBase-fullWidth {
      padding: 10px 16px 16px 20px;
      border-radius: 40px;
      background-color: ${(props) => props.theme.COLOR.WHITE};
      box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
    }

    input {
      padding-top: 5px !important; //override Material UI
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      border: 1px solid #e1e3e0;
    }

    .search-icon,
    .mobile-search-icon {
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .mobile-search-icon {
    display: none;

    @media screen and (max-width: 767px) {
      padding: 12px;
      display: inline;
      margin-left: auto;

      svg {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 375px) {
      padding: 8px;
    }
  }

  .assessment-container {
    text-align: center;

    .pathway-finder {
      display: block;
      color: ${(props) => props.theme.COLOR.DARK_PINK};
      font-size: 18px;
      font-weight: bold;
    }

    .best-lp {
      color: rgba(0, 0, 0, 0.38);
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .icon-container {
    padding: 12px;
    cursor: pointer;

    @media screen and (max-width: 375px) {
      padding: 8px;
    }
  }

  .icon-container-wrapper {
    display: flex;
    flex-direction: row;
  }

  .profile-icon-container,.redirect-to-home {
    svg {
      width: 26px;
      height: 26px;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }

  }

  .redirect-to-home {
    span {
      font-size: 16px;
      font-family: Montserrat;

      @media screen and (max-width: 995px) {
        display: none;
      }
    }
  }
`;
