import React from "react";

import { fetchGet } from "utils/ajax-util";
import { TURTLE_REELS_ENDPOINT } from "constants/endpoints";
import { Mixpanel } from "Mixpanel";
import { REEL_FEED_ENTRY } from "constants/mixpanel";

const Container = (Main) =>
  class ReelStrip extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        reelsInfo: [],
        isReelLoading: true,
      };
    }

    _fetchReels = () => {
      const {
        loggedProfileReducer: { authKey, branch },
      } = this.props;

      if (!authKey) {
        this.setState({
          isReelLoading: false,
        });
        return;
      }
      this.setState({
        isReelLoading: true,
      });

      fetchGet(
        `${TURTLE_REELS_ENDPOINT}get_turtle_reels/?page_number=1&limit=5&branch_key=${
          branch.key
        }&reset_seen_in_session=${true}&fetched_keys=${0}`,
        authKey
      )
        .then((response) => {
          if (response.success && response.data && response.data.data) {
            const reelsInfo = response.data.data;

            this.setState({
              reelsInfo: reelsInfo,
              isReelLoading: false,
            });
          } else {
            this.setState({
              isReelLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.enqueueSnackbar("Unable to fetch Learn item", {
            variant: "error",
            autoHideDuration: 1500,
          });

          this.setState({
            isReelLoading: false,
          });
        });
    };

    handleReelClick = (reelId, index) => {
      const {
        history,
        loggedProfileReducer: { branch, userEmail },
      } = this.props;

      Mixpanel.track(REEL_FEED_ENTRY, {
        entry_source: "home_reel_strip",
        entry_sequence: index + 1,
        branch_key: branch?.key,
        branch_name: branch?.name,
        email: userEmail,
      });

      history.push(`/branches/${branch.key}/turtle-reels?reel_id=${reelId}`);
    };

    componentDidMount() {
      this._fetchReels();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handleReelClick={this.handleReelClick}
        />
      );
    }
  };

export default Container;
