import React from 'react'
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Branches from './NewHomePage';

class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      branches: [],
      firsTime: true,
      heading: "Home",
      tabs: false,
      selectedTab: 0,
      branchName: "",
      breadcrumbsIndex: 0,
      loading: true
    }
  }

  initialize = () => {
    const { fetchKey, history, fetchRole, fetchBranches, setBranchName, changeBranch, setBranchPage, loggedProfileReducer : { branch } } = this.props;

    if (!fetchKey()) {
      history.push('/login');
    }
    else {
      if (fetchRole() === "admin") {
        this.setState({ page: "admin" });
        const data = this.props;
        this.setState({ branches: data.branches });

        if(data.branches.length !== 0) {
          setBranchName(window.sessionStorage.getItem('branchName'));
          changeBranch(window.sessionStorage.getItem('branchKey'));
        }
        else {
          fetchBranches();
        }
      }
      else {
        this.setState({ page: "user" });
      }
    }
    setBranchPage(false);
  }

  componentDidUpdate(prevProps) {
    if (this.state.page !== this.props.fetchRole()) {
      this.initialize();
    }
    if(this.props.branches !== prevProps.branches){
      this.initialize();
    }
  }

  componentDidMount() {
    this.initialize();
  }

  render() {
    return (
      this.state.page === "user" ?
        <Branches isUser={true} {...this.props} /> :
        this.state.loading ?
          <main>
            <div>
              <div align="center"><Typography color="primary" align="center">Fetching Branches</Typography></div><br />
              <div align="center"><CircularProgress /></div>
            </div>
          </main> :
          <main>
            <div align="center">
              <Typography color="primary">No Branches Found!</Typography>
            </div>
          </main>
    );
  }
}

export default Base;