import React from "react";

import { getDeviceDetail } from "utils";
import { skillInfoData } from "utils/common";
import {
  COURSE_TILE_CLICKED,
  HOME_PAGE_COURSE_TILE_CLICKED,
} from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";
import { getRecommendedKeys } from "utils/recommendations-util";

const Container = (Main) =>
  class NewCourseTile extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        accessPermission: false,
      }
    }
    handleCourseClicked = (
      branch_key,
      itemKey,
      category,
      isIndependentItem,
      source,
      source_category,
      primary_category_info
    ) => {
      const {
        loggedProfileReducer: { branch },
        history,
        course_sequence,
        content_source,
      } = this.props;

      if (isIndependentItem) {
        let baseUrl = `/branches/${branch?.key}/content/${itemKey}`;
        history.push(baseUrl);
        return;
      }

      this.callMixpanelEvent(
        content_source === "homepage"
          ? HOME_PAGE_COURSE_TILE_CLICKED
          : COURSE_TILE_CLICKED,
        {
          ...(course_sequence && { course_sequence: course_sequence }),
          ...(content_source && { content_source: source_category ? "homepage_recommendations" : content_source }),
        }
      );

      if(source_category === 'recommended') {
        this.callMixpanelEvent(
          COURSE_TILE_CLICKED,
          {
            ...(course_sequence && { course_sequence: course_sequence }),
            ...({content_source: "homepage_recommendations"}),
            ...({isRecommended: true}),
            ...({recommendations_shown: getRecommendedKeys()}),
          }
        );
      }

      if (category === "paid" || primary_category_info?.pc_code === 'premium_courses') {
        history.push(`/branches/${branch_key}/courses/${itemKey}/premium`, {
          entrySource: source,
        });
        return;
      }

      history.push(`/branches/${branch_key}/courses/${itemKey}`);
    };

    handleEditClick = (e, branch_key, itemKey, isIndependentItem) => {
      e.stopPropagation();
      const {
        loggedProfileReducer: { branch, user_type },
        history,
        courseInfo: {
          is_external_course
        }
      } = this.props;

      if (isIndependentItem) {
        history.push(`/branches/${branch?.key}/editcontent/${itemKey}`);
        return;
      }
      if(is_external_course && user_type !== "super_admin") this.setState({ accessPermission : true }); 
      else history.push(`/branches/${branch_key}/courses/${itemKey}/edit`);
    };

    handleModalClose = (e) => {
      e.stopPropagation();
      this.setState({ accessPermission: false });
    }

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
        courseInfo,
      } = this.props;

      const {
        level,
        user_progress,
        category,
        key,
        name,
        primary_category_info,
      } = courseInfo;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
        ...skillInfoData(courseInfo),
        level: level,
        course_category: category,
        course_key: key,
        course_name: name,
        course_progress: user_progress
          ? user_progress < 100
            ? "in_progress"
            : "completed"
          : "not_started",
        primary_category: primary_category_info?.name,
      });
    };

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handleEditClick={this.handleEditClick}
          handleCourseClicked={this.handleCourseClicked}
          handleModalClose={this.handleModalClose}
        />
      );
    }
  };

export default Container;
