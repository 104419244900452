import styled from "styled-components";

export const BannerCardContainer = styled.div`
  .slick-slide {
    padding: 0;
  }

  figure {
    background: ${(props) => props.theme.COLOR.FIGURE_BACKGROUND};

    img {
      width: 100%;
      aspect-ratio: ${(props) => (props.isMobile ? 2 / 1 : 5 / 1)};
    }
  }

  .slick-prev {
    left: calc((100vw - 1140px) / 2);

    @media screen and (max-width: 1140px) {
      left: 30px;
    }

    svg {
      margin-left: -5px;
    }
  }

  .slick-next {
    right: calc((100vw - 1140px) / 2);

    @media screen and (max-width: 1140px) {
      right: 30px;
    }

    svg {
      margin-right: -5px;
    }
  }

  .slick-prev,
  .slick-next {
    top: calc(50% - 3px);
    transform: translate(0, -50%);

    @media screen and (max-width: 995px) {
      display: none;
    }
  }

  .slick-dots {
    bottom: 30px;

    li {
      width: 12px;
      height: 12px;
      margin: 0 7px 0 0;

      button {
        width: 12px;
        height: 12px;
        padding: 0;

        &::before {
          width: 12px;
          height: 12px;
          font-size: 12px;
          opacity: 1;
          color: ${(props) => props.theme.COLOR.SLIDER_DOTS_GREY};
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.slick-active {
        button {
          &::before {
            color: ${(props) => props.theme.COLOR.SLIDER_DOTS_ACTIVE_GREY};
          }
        }
      }
    }
  }

  .slider-container {
    display: block !important; //override slider styling;
  }
`;
