import styled from "styled-components";

export const PartnerStoriesContainer = styled.div`
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 24px 0 42px;
    background: linear-gradient(183deg, #dcedcb -7.88%, #eef9e3 74.58%);

    //overriding slick default styling
    .slick-slider {
      padding: 0 0 0 16px;
    }

    .slick-slide {
      transition: all ease-in-out 0.5s;
      transform: scale(0.8);
    }

    .slick-current {
      transform: scale(1);
    }

    .slick-current + .slick-active {
      margin-left: 6px;
    }

    .slick-current + .slick-active + .slick-active {
      margin-left: -12px;
    }
  }

  @media screen and (max-width: 540px) {
    .slick-current + .slick-active,
    .slick-current + .slick-active + .slick-active {
      margin-left: 0;
    }
  }

  .site-wrapper {
    padding-bottom: 0;
  }

  .slick-initialized .slick-slide {
    padding: 0;
  }

  .slick-prev {
    left: calc((100vw - 1140px) / 2);

    @media screen and (max-width: 1140px) {
      left: 30px;
    }

    svg {
      margin-left: -5px;
    }
  }

  .slick-next {
    right: calc((100vw - 1140px) / 2);

    @media screen and (max-width: 1140px) {
      right: 30px;
    }

    svg {
      margin-right: -5px;
    }
  }

  .slick-prev,
  .slick-next {
    top: calc(50% + 34px);
    transform: translate(0, -50%);

    &::before {
      display: none;
    }
  }

  .heading-container {
    padding: 0;
    position: relative;

    h3 {
      width: 100%;
      position: absolute;
      top: 40px;
      z-index: 1;
      text-align: center;

      span {
        display: block;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }

      @media screen and (max-width: 995px) {
        span {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding: 0 16px;
      h3 {
        position: static;
        margin-bottom: 27px;
        text-align: left;

        span {
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
        }
      }
    }
  }

  .slide-container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      padding-top: 0;
      position: relative;
    }

    .partner-info {
      @media screen and (max-width: 767px) {
        width: calc(100% - 20px);
        position: absolute;
        top: 14px;
        left: 20px;
        z-index: 3;
      }

      h4 {
        color: ${(props) => props.theme.COLOR.BLACK};
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;

        @media screen and (max-width: 995px) {
          font-size: 18px;
          line-height: 28px;
          font-family: Roboto;
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 22px;
          font-weight: 700;
        }
      }
    }

    .partner-location {
      color: ${(props) => props.theme.COLOR.PARTNER_STORY_GREY};
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;

      @media screen and (max-width: 995px) {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }

      @media screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    .parnter-quotes {
      max-width: 301px;

      @media screen and (max-width: 995px) {
        max-width: 201px;
      }

      p {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        color: ${(props) => props.theme.COLOR.PARTNER_STORY_GREY};

        @media screen and (max-width: 995px) {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }
`;

export const SliderContainer = styled.div`
  padding: 165px 0 98px;
  background: ${(props) =>
    props.theme.COLOR_PALETTE[`${props.colorschema}`].GRADIENT};

  @media screen and (max-width: 995px) {
    padding: 132px 0 56px;
  }

  @media screen and (max-width: 767px) {
    background: none;
    padding: 0;

    .site-wrapper {
      padding: 0;
    }
  }

  .partner-info {
    .partner-vertical {
      color: ${(props) =>
        props.theme.COLOR_PALETTE[`${props.colorschema}`].TERTIARY};
    }
  }

  figure {
    width: 291px;
    height: 291px;
    margin: 0 75px 0 80px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 995px) {
      width: 250px;
      height: 250px;
      margin: 0 70px;
    }

    @media screen and (max-width: 767px) {
      min-width: 163px;
      height: auto;
      margin: 0;
      border-radius: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: relative;
      z-index: 2;

      @media screen and (max-width: 767px) {
        border-radius: 24px;
      }
    }

    &::after {
      content: "";
      width: 291px;
      height: 291px;
      position: absolute;
      bottom: 20px;
      left: 15px;
      border-radius: 50%;
      background-color: ${(props) =>
        props.theme.COLOR_PALETTE[`${props.colorschema}`].TERTIARY};
      opacity: 0.5;
      z-index: 1;

      @media screen and (max-width: 995px) {
        width: 250px;
        height: 250px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .play-icon {
      width: 67px;
      height: 67px;
      position: absolute;
      bottom: 0;
      left: 20px;
      z-index: 2;

      path:first-of-type {
        fill: ${(props) =>
          props.theme.COLOR_PALETTE[`${props.colorschema}`].TERTIARY};
      }

      @media screen and (max-width: 995px) {
        width: 55px;
        height: 55px;
      }

      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path:first-of-type {
          fill: ${(props) => props.theme.COLOR.WHITE};
        }

        path:nth-of-type(2) {
          fill: ${(props) => props.theme.COLOR.BLACK};
        }
      }
    }
  }

  .parnter-quotes {
    position: relative;

    .quote-icon {
      width: 80px;
      height: 80px;
      position: absolute;
      top: -40px;
      left: -20px;

      path {
        fill: ${(props) =>
          props.theme.COLOR_PALETTE[`${props.colorschema}`].TERTIARY};
      }
    }
  }
`;
