import * as Actions from "../../constants/actions";

const initialState = {
    isUploading: false,
    isLoading: false,
    channelData: {},
    channelCategories: [],
    selectedChannel: null,
}

export default function channelReducer(state = initialState, action) {

    switch (action.type) {
        case Actions.SET_CHANNEL_DATA:
          return {
              ...state,
              channelData: action.payload,
          };
        case Actions.SET_CHANNEL_CATEGORIES:
          return {
            ...state,
            channelCategories: action.payload,
          }
        case Actions.SET_SELECTED_CHANNEL:
          return {
            ...state,
            selectedChannel: action.payload,
          };
        default:
          return state
    }
}
