import React from "react";

import "styles/reset.css";

import FullPageLoader from "components/FullPageLoader";

import BannerCard from "./components/BannerCard";
import PartnerStories from "./components/PartnerStories";
import ExploreAcademy from "./components/ExploreAcademy";
import CategoryCourses from "./components/CategoryCourses";
import Reels from "./components/Reels";
import PartnerChannel from "./components/PartnerChannel";
import PospCertification from "./components/PospCertification";
import GyanByTM from "./components/GyanByTM";
import LearnReelTab from "./components/Tabs";
import GyanIn2Mins from "./components/GyanIn2Mins";

import Container from "./container";
import { HomePageContainer } from "./styles/style";

const NewHomePage = (props) => {
  const {
    isMobile,
    isLoading,
    pageSkeleton,
    loggedProfileReducer: { branch, currentBranchName },
  } = props;

  return (
    <HomePageContainer>
      {isMobile &&
      (currentBranchName
        ? currentBranchName === "MintPro"
        : branch.name === "MintPro") ? (
        <LearnReelTab {...props} />
      ) : null}

      {isLoading ? (
        <FullPageLoader />
      ) : Array.isArray(pageSkeleton) && pageSkeleton.length ? (
        pageSkeleton.map((widget) => {
          const {
            widget_format_filters,
            widget_name,
            widget_category,
            widget_subtitle,
            id,
            view_more_deeplink,
          } = widget;

          switch (widget_format_filters[0]) {
            case "posp_course":
              return (
                <PospCertification
                  {...props}
                  title={widget_name}
                  category={widget_category}
                  key={id}
                />
              );

            case "action_items":
              return <ExploreAcademy {...props} key={id} />;

            case "course":
              return (
                <CategoryCourses
                  {...props}
                  title={widget_name}
                  category={widget_category}
                  key={id}
                  viewMoreLink={view_more_deeplink}
                />
              );

            case "channel":
              return (
                <PartnerChannel
                  {...props}
                  title={widget_name}
                  subTitle={widget_subtitle}
                  category={widget_category}
                  key={id}
                  viewMoreLink={view_more_deeplink}
                />
              );

            case "partner_stories":
              return (
                <PartnerStories
                  {...props}
                  title={widget_name}
                  subTitle={widget_subtitle}
                  category={widget_category}
                  key={id}
                />
              );

            case "reels":
              return isMobile ? <Reels {...props} key={id} /> : null;

            case "banner":
              return <BannerCard {...props} key={id} />;

            case "learn_item":
              return (
                <GyanByTM
                  {...props}
                  title={widget_name}
                  category={widget_category}
                  key={id}
                  viewMoreLink={view_more_deeplink}
                />
              );

              case "flashcard":
                return (
                  <GyanIn2Mins
                    {...props}
                    title={widget_name}
                    category={widget_category}
                    key={id}
                    viewMoreLink={view_more_deeplink}
                  />
                )
  

            default:
              return null;
          }
        })
      ) : null}
    </HomePageContainer>
  );
};

export default Container(NewHomePage);
