import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import '../../components/channelStyles.css';
const ChannelListHeader = (props) => {

    return (
      <>
      <Hidden smDown>
        <div className='partner-content-detail'>
            <div style={{ minHeight: '100px', maxWidth: '1140px', margin: '0 auto' }}>
                <div style={{ fontSize: '36px' }}>Partner Content</div>
                <div>Get to know all that you need to know about the topmost insurance brands! Grab a chance to get end to end insights on the processes, products and other important details of various insurers.</div>
            </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className='channel-list-header'><span style={{marginLeft:'16px'}}>Partner Content</span></div>
      </Hidden>
      </>
    );
}

export default ChannelListHeader;
