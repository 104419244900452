const getColorPalette = (color) => {
  if (color === "pink") {
    return "PINK_PALETTE";
  } else if (color === "purple") {
    return "PURPLE_PALETTE";
  } else if (color === "blue") {
    return "BLUE_PALETTE";
  } else if (color === "green") {
    return "GREEN_PALETTE";
  } else if (color === "orange") {
    return "ORANGE_PALETTE";
  }
};

export default getColorPalette;
