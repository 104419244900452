import styled from "styled-components";

export const ModalPopupContainer = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;

  .modal-popup {
    width: 50%;
    padding: 20px;
    position: absolute;
    z-index: 12;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
      0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);

    @media screen and (max-width: 995px) {
      width: 80%;
      padding: 30px;
    }

    .content {
      max-height: calc(100vh - 40px);
      overflow-y: auto;
    }
  }

  .modal-close {
    text-align: right;
    margin-bottom: 10px;
  }

  .close {
    color: #121617;
    font-size: 26px;
    line-height: 1;
    cursor: pointer;
  }

  .backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    background-color: hsla(0, 0%, 0%, 0.5);
  }
`;
