import styled from "styled-components";

export const ExploreAcademyContainer = styled.div`
  text-align: center;
  margin: 16px 0;

  @media screen and (max-width: 767px) {
    margin: 24px 0 40px;
  }

  .site-wrapper {
    padding-bottom: 0;
  }

  h3 {
    width: 82%;
    margin: 0 auto 24px;

    span {
      color: ${(props) => props.theme.COLOR_PALETTE.PINK_PALETTE.TERTIARY};
    }

    @media screen and (max-width: 995px) {
      width: 100%;
      margin-bottom: 38px;
    }

    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }

  ul {
    width: 82%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 995px) {
      width: 100%;
    }
  }

  li {
    flex-basis: 15.5%;
    margin: 0 5.5% 32px 0;
    position: relative;
    cursor: pointer;

    &:nth-child(5n) {
      margin-right: 0;
    }

    figure {
      padding: 15px;
      border-radius: 50%;
      background: linear-gradient(180deg, #eff7fe 0%, #fff 100%);

      img {
        width: 100%;
        aspect-ratio: 1/1;
      }
    }

    .action-list-title {
      margin-top: -20px;
      padding: 0 10px;
      display: block;
      color: ${(props) => props.theme.COLOR.MEDIUM_BLACK};
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      @media screen and (max-width: 995px) {
        font-family: Roboto;
      }

      @media screen and (max-width: 767px) {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-basis: 30.2%;
      margin: 0 3.8% 27px 0;
      transition: all 0.5s ease-in;

      &:last-child {
        display: none;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:nth-child(5n) {
        margin-right: 3.8%;
      }
    }
  }

  .hide-action {
    li:nth-child(7),
    li:nth-child(8),
    li:nth-child(9) {
      height: 0;
      opacity: 0;
      display: none;
    }
  }

  .show-action {
    li:nth-child(7),
    li:nth-child(8),
    li:nth-child(9) {
      display: block;
      height: auto;
      opacity: 1;
    }
  }

  .rotate-arrow {
    transform: rotate(180deg);
  }

  button {
    width: 100%;
    height: 40px;
    padding: 12px 0;
    border-radius: 12px;
    border: 1.5px solid ${(props) => props.theme.COLOR.LIGHTEST_GREY_BORDER};
    display: block;
    background-color: ${(props) => props.theme.COLOR.WHITE};
    box-shadow: 18px -18px 36px 0px rgba(237, 237, 237, 0.2),
      -18px 18px 36px 0px rgba(237, 237, 237, 0.2),
      -1px -1px 2px 0px rgba(237, 237, 237, 0.5) inset,
      1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
  }
`;

export const ActionTag = styled.div`
  max-width: 80px;
  height: 24px;
  padding: 2px 8px 0;
  background-color: ${(props) => props.colorShade};
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  color: ${(props) => props.theme.COLOR.WHITE};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;

  @media screen and (max-width: 995px) {
    font-family: Roboto;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px;
    height: 18px;
    padding: 0.5px 6px 0;
  }
`;
