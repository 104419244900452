const prefix = 'https://app.mintpro.in/vertical-redirect/lms?redirect=/';
export const COURSE_DEEPLINK = `${prefix}branches/52478614778524/courses/`;
export const WEBINAR_DEEPLINK = `${prefix}webinars/`;
export const CONTENT_DEEPLINK = `${prefix}branches/52478614778524/content/`;
export const CHANNEL_DEEPLINK = `${prefix}channels/`
export const LEARNING_PATH_DEEPLINK = `${prefix}branches/52478614778524/learningpath/`;
export const CUSTOMER_PITCH_DEEPLINK = `${prefix}branches/52478614778524/customerpitch/`;

//This is created so that we can start using the new approach of not using static branch ID
export const BASE_URL = 'https://app.mintpro.in/vertical-redirect/lms?redirect=/';
