import * as Actions from "../../constants/actions";

const initialState = {
    selectedBranchKey: null,
    certificationCourses: [],
    enggOnboardingCourses: [],
    podcastCourses: [],
    claimDiaryCourses: [],
    masterclassCourses: [],
    tag2021HighlightsCourses: [],
    motivationalTalkCourses: [],
    personalDevelopmentCourses: [],
    howToCourses: [],
    healthProductTrainingCourses: [],
    lifeProductTrainingCourses: [],
    applicationCourses: [],
    paidCourses: [],
    qaCourses: [],
}

export default function branchReducer(state = initialState, action) {

    switch (action.type) {
        case Actions.SET_SELECTED_BRANCH_KEY:
            return {
                ...state,
                selectedBranchKey: action.payload
            };
        case Actions.APPEND_CERTIFICATION_COURSES:
            return {
                ...state,
                certificationCourses: [...state.certificationCourses, ...action.payload],
            };
        case Actions.APPEND_ENGG_ONBOARDING_COURSES:
            return {
                ...state,
                enggOnboardingCourses: [...state.enggOnboardingCourses, ...action.payload],
            };
        case Actions.APPEND_PODCAST_COURSES:
            return {
                ...state,
                podcastCourses: [...state.podcastCourses, ...action.payload],
            };
        case Actions.APPEND_CLAIM_DIARY_COURSES:
            return {
                ...state,
                claimDiaryCourses: [...state.claimDiaryCourses, ...action.payload],
            };
        case Actions.APPEND_MASTERCLASS_COURSES:
            return {
                ...state,
                masterclassCourses: [...state.masterclassCourses, ...action.payload],
            };
        case Actions.APPEND_TAG_2021_HIGHLIGHT_COURSES:
            return {
                ...state,
                tag2021HighlightsCourses: [...state.tag2021HighlightsCourses, ...action.payload],
            };
        case Actions.APPEND_MOTIVATIONAL_TALK_COURSES:
            return {
                ...state,
                motivationalTalkCourses: [...state.motivationalTalkCourses, ...action.payload],
            };
        case Actions.APPEND_PERSONAL_DEVELOPMENT_COURSES:
            return {
                ...state,
                personalDevelopmentCourses: [...state.personalDevelopmentCourses, ...action.payload],
            };
        case Actions.APPEND_HOW_TO_COURSES:
            return {
                ...state,
                howToCourses: [...state.howToCourses, ...action.payload],
            };
        case Actions.APPEND_HEALTH_PRODUCT_TRAINING_COURSES:
            return {
                ...state,
                healthProductTrainingCourses: [...state.healthProductTrainingCourses, ...action.payload],
            };
        case Actions.APPEND_LIFE_PRODUCT_TRAINING_COURSES:
            return {
                ...state,
                lifeProductTrainingCourses: [...state.lifeProductTrainingCourses, ...action.payload],
            };
        case Actions.APPEND_APPLICATION_COURSES:
            return {
                ...state,
                applicationCourses: [...state.applicationCourses, ...action.payload],
            };
        case Actions.APPEND_PAID_COURSES:
            return {
                ...state,
                paidCourses: [...state.paidCourses, ...action.payload],
            };
        case Actions.APPEND_QA_COURSES:
            return {
                ...state,
                qaCourses: [...state.qaCourses, ...action.payload],
            };
        default:
            return state
    }
}
