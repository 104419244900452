import styled from "styled-components";

export const CustomProgressBar = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: #d9d9d9;
  position: relative;
  text-indent: -99999px;

  &::before {
    content: "";
    width: ${props => props.progress}%;
    border-radius: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.COLOR.MAGENTA};
  }
`;
