import styled from "styled-components";

export const SideBarContainer = styled.div`
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  font-family: Montserrat;

  .sidebar-wrapper {
    width: 50%;
    padding-bottom: 20px;
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    background: #fff;
    color: ${(props) => props.theme.COLOR.BLACK};
    animation: slide 0.5s forwards;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  @-webkit-keyframes slide {
    100% {
      right: 0;
    }
  }

  @keyframes slide {
    100% {
      right: 0;
    }
  }

  .cross-icon-container {
    padding: 31px 32px 6px 20px;
    margin-bottom: 15px;
    box-shadow: 2px 2px 1px ${(props) => props.theme.COLOR.BORDER_GREY};

    .sidebar-close-icon {
      display: inline-block;
      vertical-align: middle;

      svg {
        border-radius: 50%;
        background-color: ${(props) => props.theme.COLOR.WHITE};
        cursor: pointer;
      }
    }
  }

  .profile-name {
    width: calc(100% - 24px);
    display: inline-block;
    vertical-align: middle;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 6px;
      display: inline-block;
      vertical-align: bottom;
    }
  }

  .sidebar-content-container {
    max-height: calc(100vh - 51px);
    padding: 0 20px 20px;
    overflow-y: auto;

    h4 {
      font-size: 16px;
      margin-bottom: 8px;
      color: #cb3f89;
    }

    ul {
      margin-bottom: 30px;
    }

    li {
      border-bottom: 0.5px solid #ccc;

      &:hover {
        background: #eef9e3;
      }

      a {
        padding: 12px;
        display: block;
        color: ${(props) => props.theme.COLOR.BLACK};
        cursor: pointer;
      }
    }
  }

  .manage-cta-container {
    margin-bottom: 20px;

    .manage-users-cta {
      width: 135px;
      height: 40px;
      padding: 4px;
      border: none;
      border-radius: 24px;
      background: ${(props) => props.theme.COLOR.CTA_BG_BLACK};
      color: ${(props) => props.theme.COLOR.WHITE};
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      line-height: 24px;
      box-shadow: 8px 12px 32px 0 rgba(29, 33, 31, 0.25);
      cursor: pointer;
    }
  }
`;
