/*  eslint-disable */

import { grey, red } from '@material-ui/core/colors';
const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontSize: 'inherit',
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: 256,
        flexShrink: 0,
      },
    },
    feedbackContainer: {
      [theme.breakpoints.down('md')]: {
        width: '50%'
      },
    },
    main: {
      flex: 1,
      marginTop:'12px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9)
      },
      background: '#fff',
    },
    coursesContainer: {
      maxWidth: "1500px",
      padding: theme.spacing(2, 2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9)
      }
    },
    courses: {
      flex: 1,
      background: '#fff',
    },
    coursemain: {
      flex: 1,
      paddingTop: theme.spacing(5),
      marginTop:'16px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(9)
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
      background: '#fff',
    },
    footer: {
      padding: theme.spacing(2),
      background: '#fff',
    },
    mobileFooter :{
      border: "1px solid #E9E3DD",
      float: "left",
      height: "210px",
      margin: "2px",
      padding: "4px 2px 10px 10px",
      textAlign: "left",
      width: "117px",
      position:"relative"
    },
    secondaryBar: {
      zIndex: 0,
      [theme.breakpoints.up('sm')]: {
        paddingLeft:theme.spacing(6),
        paddingRight:theme.spacing(6),
      },
      backgroundColor:"#272929"
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    primaryButton: {
      backgroundColor: "green",
      borderColor: lightColor,
      marginTop: theme.spacing(1),
      borderRadius: "4px"
    },
    submitButton: {
      backgroundColor: "#508c6e",
      color: 'white',
      borderColor: lightColor,
      marginTop: theme.spacing(1),
      borderRadius: "4px"
    },
    cancelButton: {
      backgroundColor: "red",
      borderColor: lightColor,
      marginTop: theme.spacing(1),
      borderRadius: "4px"
    },
    secondaryButton: {
      backgroundColor: "grey",
      borderColor: lightColor,
      marginTop: theme.spacing(1),
      borderRadius: "4px"
    },
    button: {
      borderColor: lightColor,
      marginTop: theme.spacing(1),
      borderRadius: "4px"
    },
    disabledbutton: {
      borderColor: lightColor,
      marginTop: theme.spacing(1),
      borderRadius: "4px",
      backgroundColor: "grey!important",
      color: theme.palette.common.white,
    },
    greenbutton: {
      width: "182px",
      height: "40px",
      backgroundColor: "#00a465",
      borderColor: lightColor,
      color: theme.palette.common.white,
      marginTop: theme.spacing(1),
    },
    secondarybutton: {
      width: "182px",
      height: "40px",
      backgroundColor: "grey",
      borderColor: lightColor,
      color: theme.palette.common.white,
      marginTop: theme.spacing(1),
    },
    exambutton: {
      width: "182px",
      height: "40px",
      backgroundColor: "#00a465",
      borderColor: lightColor,
      color: theme.palette.common.white,
    },
    overviewbutton: {
      width: "222px",
      height: "40px",
      fontSize: 16,
      backgroundColor: "#00a465",
      borderColor: lightColor,
      color: theme.palette.common.white,
      marginTop: theme.spacing(1),
    },
    paper: {
      maxWidth: 1000,
      margin: 'auto',
      overflow: 'hidden',
    },
    formpaper: {
      maxWidth: 528,
      margin: 'auto',
      overflow: 'hidden',
      boxShadow: 'none',
      marginTop:'12px',
    },
    mcqpaper: {
      margin: 'auto',
      overflow: 'hidden',
      padding: theme.spacing(2)
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 16px',
    },
    mcqcontentWrapper: {
      padding: theme.spacing(2),
      margin: '40px 16px',
    },
    feedbackcontentWrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        width: '50%'
      },
      // margin: '40px 16px',
    },
    card: {
      border: "solid 1px #d2d5e6",
      '&:hover': {
        boxShadow: "0 0 2em 0 #d2d5e6"
      }
    },
    cardpdf: {
      maxWidth: 645,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    list: {
      backgroundColor: theme.palette.background.paper,
      width:"98%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    formControlSelect: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    search: {
      position: 'relative',
      // borderRadius: theme.shape.borderRadius,
      border:1,
      borderColor:"grey",
      borderStyle: "solid",
      marginLeft: 0,
      width: '160%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '60.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.primary,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    mobilecontainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
      bottom: "0",
      position:"fixed"
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      flex: '1 1 100%',
    },
    toggle: {
      color: "black",
      borderRadius: "2em",
      border: "solid 1px #d2d5d6",
      "&:hover": {
        border: "solid 1px #00a465",
        backgroundColor: "#fff",
      },
    },
    liveLayout: {
      marginTop:"3px",
      fontWeight: "500",
      fontSize: "12px",
      color:"#F44336",
      border: "solid 1px #F44336", 
      boxSizing: "border-box",
      width: "65px",
      height:"20px",
      padding:"2px"
    },
    preview: {
      border: "1px solid #ccc",
      backgroundColor: "lightgray"
    },
    wrapperClassName: {
      padding: "1rem",
      border: "1px solid #ccc",
    },
    editorClassName: {
      padding: "1rem",
      border: "1px solid #ccc",
    },
    toolbarClassName: {
      border: "1px solid #ccc",
    }
  });

  export default styles;