import React from "react";

import CardSkeleton from "pages/NewHomePage/components/CardSkeleton";

import { ReelsContainer } from "./styles/style.js";
import Container from "./container";

const Reels = (props) => {
  const { reelsInfo, handleReelClick, isReelLoading } = props;
  return isReelLoading ? (
    <div className="site-wrapper">
      <CardSkeleton hideContentSkeleton={true} />
    </div>
  ) : !isReelLoading && reelsInfo.length ? (
    <ReelsContainer>
      <ul>
        {reelsInfo.map((reelItem, index) => {
          const { id, thumbnail_image_url, name } = reelItem;
          return (
            <li
              className="slide-container"
              onClick={() => handleReelClick(id, index)}
              key={id}
            >
              <figure>
                <img src={thumbnail_image_url} alt="Reel Thumbnail" />
              </figure>
              <h6>{name}</h6>
            </li>
          );
        })}
      </ul>
    </ReelsContainer>
  ) : null;
};

export default Container(Reels);
