import { Mixpanel } from "Mixpanel";

const callMixPanelEvent = (eventName, attributes, addditionalProps) => {
  let branch_key = "";
  let branch_name = "";
  let email = "";

  if (addditionalProps && addditionalProps.loggedProfileReducer) {
    const {
      loggedProfileReducer: { userEmail, branch },
    } = addditionalProps;

    if (userEmail) {
      email = userEmail;
    }

    if (branch && branch.key) {
      branch_key = branch.key;
    }

    if (branch && branch.name) {
      branch_name = branch.name;
    }
  }

  Mixpanel.track(eventName, {
    ...attributes,
    ...(email && { email: email }),
    ...(branch_name && { branch_name: branch_name }),
    ...(branch_key && { branch_key: branch_key }),
  });
};

export default callMixPanelEvent;
