/**
 * @description Helper util to create reducer dynmically with action types and initial state
 * @param {Object} initialState - initial state of the reducer.
 * @param {Object} types - all action types exports
 * @return Function
 * @example
 * import { createReducer } from 'utils/createReducer'
 * import * as types from 'your-types-export-file'
 * const initialState = {}
 * createReducer(initialState, types)
 */
export default (initialState = {}, types) => {
  return (state = initialState, action) => {
    const typeKeys = typeof types === 'string' ? [types] : Object.values(types);
    for (let i = 0; i < typeKeys.length; i += 1) {
      if (action.type === typeKeys[i]) {
        return {
          ...state,
          ...action.payload,
        };
      }
    }
    return state;
  };
};
