import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  BlockQuote,
  PrevIcon,
  NextIcon,
  PlayIcon,
} from "pages/NewHomePage/images";
import { getColorPalette } from "utils";

import { PartnerStoriesContainer, SliderContainer } from "./styles/style.js";
import Container from "./container";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  prevArrow: (
    <SlickButtonFix>
      <PrevIcon />
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix>
      <NextIcon />
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        arrows: false,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
  ],
};

const PartnerStories = (props) => {
  const {
    storiesData,
    isPartnerStoryLoading,
    isMobile,
    handlePartnerStoryClick,
    title,
    subTitle,
  } = props;

  return isPartnerStoryLoading ? (
    <Skeleton
      count={1}
      height={isMobile ? window.innerWidth / 2 : window.innerWidth / 5}
      borderRadius={12}
    />
  ) : !isPartnerStoryLoading &&
    Array.isArray(storiesData) &&
    storiesData.length ? (
    <PartnerStoriesContainer>
      <>
        <div className="heading-container site-wrapper">
          <h3 className="section-heading">
            {title ? title : "Real People, Real Stories"}
            <span>
              {subTitle ? subTitle : "Hear from our star digital partners"}
            </span>
          </h3>
        </div>
        <Slider {...settings}>
          {storiesData.map((story) => {
            const {
              id,
              title,
              location,
              vertical,
              color,
              thumbnail_cloud_url,
              mobile_thumbnail_cloud_url,
              blockquote,
            } = story;
            const colorSchema = getColorPalette(color);
            return (
              <SliderContainer
                className="slider-container"
                colorschema={colorSchema}
                key={id}
              >
                <div className="site-wrapper">
                  <div className="slide-container">
                    <div className="partner-info">
                      <h4>{title}</h4>
                      <span className="partner-location">
                        {location}{" "}
                        {vertical ? (
                          <span className="partner-vertical">{vertical}</span>
                        ) : null}
                      </span>
                    </div>

                    <figure
                      className="partner-image"
                      onClick={() => handlePartnerStoryClick(story)}
                    >
                      <img
                        src={
                          isMobile
                            ? mobile_thumbnail_cloud_url
                            : thumbnail_cloud_url
                        }
                        alt="Partner Story Thumbnail"
                      />
                      <PlayIcon className="play-icon" />
                    </figure>

                    {!isMobile ? (
                      <div className="parnter-quotes">
                        <BlockQuote className="quote-icon" />
                        <p>{blockquote}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </SliderContainer>
            );
          })}
        </Slider>
      </>
    </PartnerStoriesContainer>
  ) : null;
};

export default Container(PartnerStories);
