import React from "react";
import { MdStarRate } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import LazyLoadImageContainer from "components/LazyLoadImageContainer";

import { PrevIcon, NextIcon } from "pages/NewHomePage/images";

import { PartnerChannelContainer } from "./styles/style.js";
import Container from "./container.jsx";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 4000,
  centerMode: true,
  centerPadding: "0",
  prevArrow: (
    <SlickButtonFix>
      <PrevIcon />,
    </SlickButtonFix>
  ),
  nextArrow: (
    <SlickButtonFix>
      <NextIcon />,
    </SlickButtonFix>
  ),
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        arrows: false,
        centerPadding: "20%",
      },
    },
  ],
};

const PartnerChannel = (props) => {
  const {
    channelList,
    isPartnerChannelLoading,
    onViewMoreClick,
    title,
    subTitle,
    isMobile,
    handleChannelClick,
  } = props;

  return isPartnerChannelLoading ? (
    <Skeleton
      count={1}
      height={isMobile ? window.innerWidth / 2 : window.innerWidth / 5}
      borderRadius={12}
    />
  ) : !isPartnerChannelLoading &&
    Array.isArray(channelList) &&
    channelList.length ? (
    <PartnerChannelContainer>
      <div className="site-wrapper">
        <h3 className="section-heading">
          {title ? title : "Content from our partners"}
          <span>
            {subTitle ? subTitle : "Exciting knowledge waiting for you"}
          </span>
        </h3>
        <Slider {...settings}>
          {channelList.map((channelInfo) => {
            const { image_url, total_viewers, avg_rating, key } = channelInfo;
            return (
              <div
                className="channel-info"
                key={key}
                onClick={() => {
                  handleChannelClick(channelInfo);
                }}
              >
                <div className="channel-info-container">
                  <figure>
                    <LazyLoadImageContainer
                      imageDetails={{
                        src: image_url,
                        alt: "Partner Channel Logo",
                      }}
                    />
                  </figure>
                  {total_viewers !== "0" || avg_rating ? (
                    <div className="channel-subscriber-rating-container">
                      <div className="rating-wrapper">
                        <span
                          className={`${
                            !avg_rating ? `no-rating` : null
                          } subscriber-container`}
                        >
                          {total_viewers} <span>Subscribers</span>
                        </span>
                        {avg_rating ? (
                          <span className="avg-rating-container">
                            {avg_rating}
                            <span>
                              <MdStarRate />
                            </span>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="view-more-container">
          <button
            className="default-button"
            title="View More"
            onClick={onViewMoreClick}
          >
            View More
          </button>
        </div>
      </div>
    </PartnerChannelContainer>
  ) : null;
};

export default Container(PartnerChannel);
