import React from "react";
import { fetchGet } from "utils/ajax-util";
import { PARTNER_STORIES } from "constants/endpoints";
import { getDeviceDetail } from "utils";
import { SOCIAL_PROOF_CLICKED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

const Container = (Main) =>
  class PartnerStories extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        storiesData: [],
        isPartnerStoryLoading: true,
      };
    }

    _fetchStoriesList = () => {
      const {
        loggedProfileReducer: { authKey, branch },
      } = this.props;

      if (!(branch && branch.key) || !authKey) {
        this.setState({
          isPartnerStoryLoading: false,
        });
        return;
      }

      fetchGet(
        `${PARTNER_STORIES}?branch_key=${branch.key}&hide_draft=${true}`,
        authKey
      )
        .then((data) => {
          const { success, partner_stories } = data;

          if (success) {
            this.setState({
              isPartnerStoryLoading: false,
              storiesData: partner_stories ? partner_stories : [],
            });
          } else {
            this.setState({
              isPartnerStoryLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isPartnerStoryLoading: false,
          });
          console.log(error);
        });
    };

    handlePartnerStoryClick = (storyDetails) => {
      const { sequence, title, id } = storyDetails;
      this.callMixpanelEvent(SOCIAL_PROOF_CLICKED, {
         entry_sequence: sequence,
         content_id: id,
         content_name: title
      });
      this.props.history.push(`/partner-stories/${storyDetails.key}/`);
    };

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    componentDidMount() {
      this._fetchStoriesList();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handlePartnerStoryClick={this.handlePartnerStoryClick}
        />
      );
    }
  };

export default Container;
