/*  eslint-disable */

import { createTheme } from '@material-ui/core/styles';

let theme = createTheme({
    palette: {
      primary: {
        light: '#00a465',
        main: '#00a465',
        dark: '#00a465',
      },
      secondary: {
        light: "#ffffff",
        main: "#ffffff",
        dark: "#272929"
      }
    }
  });
  
  export default theme;