import React, { useEffect, useCallback } from "react";

import { addOverflow, removeOverflow } from "utils/OverflowUtil";
import { ModalPopupContainer } from "./style/style.js";

/**
 * @description Modal component to show the product details to a existing UPC.
 * @function ModalPopup
 * @param {Function} customClose
 * @param {Array.<Object>} children - component to display on pop open.
 */

function ModalPopup({ customClose, children, preventClick }) {
  const closeModal = useCallback((e) => {
    removeOverflow();
    if (customClose) {
      customClose(e);
    }
  }, [customClose]);

  const escapeHandler = useCallback(
    (e) => {
      if (e.keyCode === 27) closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    addOverflow();
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", escapeHandler);

    //clean or unmounting component
    return function cleanup() {
      removeOverflow();
      document.removeEventListener("keyup", escapeHandler);
    };
  }, [escapeHandler]);

  return (
    <ModalPopupContainer>
      <div
        className="backdrop"
        onClick={
          preventClick
            ? () => {
                //  this is intentional
              }
            : closeModal
        }
      />
      <div className="modal-popup">
        <div className="content">
          {customClose && (
            <div className="modal-close">
              <span className="close" onClick={closeModal} title="Close">
                &times;
              </span>
            </div>
          )}
          {children}
        </div>
      </div>
    </ModalPopupContainer>
  );
}

export default React.memo(ModalPopup);
