/**
 * @description util to control overflow of body to control page scroll.
 */


  /**
   * @description restricts page scroll
   */
 export const addOverflow = () => {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';
    return null;
  }

  /**
   * @description makes page scrollable
   */
  export const removeOverflow = () => {
    const body = document.querySelector('body');
    body.style.overflow = 'auto';
    return null;
  }
