import { v4 as uuidv4 } from 'uuid';


import Base from "pages/index";
import AuthPage from 'pages/auth';

export default [
  {
    path: '/login',
    Component: AuthPage,
    id: uuidv4(),
  },
  {
    path: '/logout',
    Component: AuthPage,
    id: uuidv4(),
  },
  {
    path: '/login?:verify_key',
    Component: AuthPage,
    id: uuidv4()
  },
  {
    path: "/",
    Component: Base,
    id: uuidv4(),
    fullWidth: true
  },

];
