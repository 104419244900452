import React from "react";
import { MdModeEdit } from "react-icons/md";

const NewFlashcardTile = ({
  item,
  isCourseAdminRole,
  onContentClick,
  handleEditClick,
}) => {
  const { key, image_url, name } = item;

  return (
    <div
      className="slide-container"
      onClick={() => onContentClick(item)}
      key={key}
    >
      {isCourseAdminRole && (
        <span
          className="edit-icon-container"
          title="Edit"
          onClick={(e) => handleEditClick(e, key)}
        >
          <MdModeEdit />
        </span>
      )}
      <figure>
        <img src={image_url} alt="Content Thumbnail" />
      </figure>
      <h6>
        <span>{name}</span>
      </h6>
    </div>
  );
};

export default NewFlashcardTile;
