import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import LazyLoadImageContainer from "components/LazyLoadImageContainer";

import { DownArrow } from "pages/NewHomePage/images";
import { ExploreAcademyContainer, ActionTag } from "./styles/style.js";
import Container from "./container";

const SkeletonContainer = styled.div`
  margin: 30px 0;

  > span {
    span {
      margin: ${(props) => (props.isMobile ? "0 3.8% 5px 0" : "0 5.5% 5px 0")};
      &:nth-child(${(props) => (props.isMobile ? "3n" : "5n")}) {
        margin-right: 0;
      }
    }
  }

  .horizontal-title-loader {
    margin: ${(props) => (props.isMobile ? "0 9% 20px 5%" : "0 8% 20px 3%")};
  }
`;

const ExploreAcademy = (props) => {
  const {
    actionItemList,
    handleExpand,
    showAction,
    isMobile,
    handleRedirection,
    isActionItemLoading,
  } = props;

  return isActionItemLoading ? (
    <div className="site-wrapper explore-academy-skeleton-loader">
      <SkeletonContainer isMobile={isMobile}>
        <>
          <Skeleton
            inline
            circle
            count={isMobile ? 3 : 5}
            width={isMobile ? "30.2%" : "15.5%"}
            height={
              isMobile
                ? (window.innerWidth - 48) * 0.302
                : (window.innerWidth - 48) * 0.6 * 0.155
            }
            borderRadius={12}
          />
          <Skeleton
            inline
            count={isMobile ? 3 : 5}
            width={isMobile ? "20%" : "10%"}
            borderRadius={12}
            className="horizontal-title-loader"
          />
        </>
        <>
          <Skeleton
            inline
            circle
            count={isMobile ? 3 : 5}
            width={isMobile ? "30.2%" : "15.5%"}
            height={
              isMobile
                ? (window.innerWidth - 48) * 0.302
                : (window.innerWidth - 48) * 0.6 * 0.155
            }
            borderRadius={12}
          />
          <Skeleton
            inline
            count={isMobile ? 3 : 5}
            width={isMobile ? "20%" : "10%"}
            borderRadius={12}
            className="horizontal-title-loader"
          />
        </>
      </SkeletonContainer>
    </div>
  ) : !isActionItemLoading &&
    Array.isArray(actionItemList) &&
    actionItemList.length ? (
    <ExploreAcademyContainer>
      <div className="site-wrapper">
        <h3 className="section-heading">
          Explore
          <span> Academy</span>
        </h3>
        <ul
          className={
            isMobile && showAction
              ? "hide-action show-action"
              : isMobile
              ? "hide-action"
              : ""
          }
        >
          {actionItemList.map((item) => {
            const {
              name,
              icon,
              is_new,
              is_live,
              is_resume,
              live_color,
              new_color,
              resume_color,
              id,
            } = item;
            return (
              <li onClick={() => handleRedirection(item)} key={id}>
                {is_new || is_live || is_resume ? (
                  <ActionTag
                    colorShade={
                      is_new ? new_color : is_live ? live_color : resume_color
                    }
                  >
                    {is_new ? "New" : is_live ? "Live" : "Resume"}
                  </ActionTag>
                ) : null}
                <figure>
                <LazyLoadImageContainer
                    imageDetails={{
                      src: icon,
                      alt: "Academy Action Icon",
                    }}
                  />
                </figure>
                <span className="action-list-title">{name}</span>
              </li>
            );
          })}
        </ul>
        {isMobile && actionItemList.length > 6 ? (
          <button
            onClick={handleExpand}
            className={showAction ? "rotate-arrow" : null}
          >
            <DownArrow />
          </button>
        ) : null}
      </div>
    </ExploreAcademyContainer>
  ) : null;
};

export default Container(ExploreAcademy);
