import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import 'components/channelStyles.css'

const ChannelDetailsHeader = (props) => {

    const [descriptionState, setDescriptionState] = useState("");
    const description = props.reduxState.selectedChannel?.description;

    useEffect(() => {
        if (description) {
            setDescriptionState(description);
        }
    }, [description]);

    return <div className="channelDetailsHeader-container">
        <div className="channelDetailsHeader-main">
            <img src={ props.reduxState.selectedChannel?.image_url || "" } className="channelDetailsHeader-logo" alt = "Channel Logo"/>
            <div className="channelDetailsHeader-name">{props.reduxState.selectedChannel?.name}</div>
            <div className="channelDetailsHeader-description" > {descriptionState}</div> 
        </div>
    </div>
};

const mapStateToProps = (state) => {

    const { channelReducer } = state;

    const reduxState = {
        selectedChannel: channelReducer.selectedChannel
    };

    return { reduxState: reduxState };

};

export default connect(mapStateToProps)(ChannelDetailsHeader);
