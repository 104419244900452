import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const BranchUsersPage = React.lazy(() => import("pages/users"));
const AddUser = React.lazy(() => import("pages/AdminControls/AddUsers"));
const AssignCourseToUser = React.lazy(() => import("pages/AdminControls/AssignCourseToUser"));

export default [
  /*=============================== Users Create/Edit =============================== */
  {
    path: "/branches/:id/users/:user/edit",
    Component: AddUser,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
    ],
  },
  {
    path: "/branches/:id/users/add",
    Component: AddUser,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/users/:user/assign",
    Component: AssignCourseToUser,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
    ],
  },
  {
    path: "/branches/:id/users",
    Component: BranchUsersPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
    ],
  },
];
