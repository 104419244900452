import React from "react";

import { CustomProgressBar } from "./style";

const CustomLinearProgressBar = (props) => {
  const { hideProgressText, progress } = props;
  return (
    <div className="progress-bar-container">
      {hideProgressText ? null : <span>{progress}% completed</span>}
      <CustomProgressBar className="custom-progress-bar" progress={progress}>progress</CustomProgressBar>
    </div>
  );
};

export default CustomLinearProgressBar;
