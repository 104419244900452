import React from "react";
import { fetchGet } from "utils/ajax-util";
import { skillInfoData } from "utils/common";
import { LEARN_ITEM_ENDPOINT } from "constants/endpoints";
import { HOME_PAGE_COURSE_TILE_CLICKED } from "constants/mixpanel";
import { getDeviceDetail } from "utils";
import { Mixpanel } from "Mixpanel";
import {
  CompleteProfileMessage,
  ExamCompletedMessage,
} from "../../duck/constants";

import { DEFAULT_BRANCH_ID } from "constants/defaults";

const Container = (Main) =>
  class NewCourseTile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        examModal: false,
        examModalMessage: "",
      };
    }
    handleCourseClicked = (courseKey, courseCategory, lockCourse = false) => {
      const {
        loggedProfileReducer: { branch, isAdminRole },
        history,
        course_sequence,
        content_source,
        setExamModalAndMessage
      } = this.props;

      this.callMixpanelEvent(HOME_PAGE_COURSE_TILE_CLICKED, {
        ...(course_sequence && { course_sequence: course_sequence }),
        ...(content_source && { content_source: content_source }),
      });

      if (lockCourse) {
        setExamModalAndMessage({
          examModalMessage: CompleteProfileMessage,
        });
        return;
      }

      if (courseCategory === "exam" && !isAdminRole) this.startExam(courseKey);
      else history.push(`/branches/${branch.key}/courses/${courseKey}`);
    };

    startExam = (course) => {
      const {
        loggedProfileReducer: { branch, authKey },
        branch_support_email,
        history,
        setExamModalAndMessage,
      } = this.props;

      fetchGet(
        `${LEARN_ITEM_ENDPOINT}check_exam_dependencies/?course_key=${course}`,
        authKey
      )
        .then((data) => {
          if (data && data.success) {
            const {
              data: { completed },
            } = data;
            if (completed) {
              if (
                branch?.key ===
                "ahNzfnR1cnRsZW1pbnQtMTQzOTEzchYLEglMTVNCcmFuY2gYgICA4Y3b2QgM" ||
                branch?.key ===
                "ahhqfm1pbnRwcm8tdW5pdmVyc2l0eS11YXRyFgsSCUxNU0JyYW5jaBiAgICAmoKICww"
              ) {
                setExamModalAndMessage({
                  examModalMessage: "You have already cleared the exam.",
                });
              } else if (branch?.name !== 'MintPro') {
                setExamModalAndMessage({
                  examModalMessage: `${ExamCompletedMessage} ${branch_support_email}.`,
                });
              } else {
                history.push(`/branches/${branch?.key}/allcertificates`);
              }
            } else {
              localStorage.removeItem(`${course}-start`);
              localStorage.removeItem(`${course}-stop`);
              history.push(`/branches/${DEFAULT_BRANCH_ID}/courses/${course}`);
            }
          } else {
            if (data?.redirectUrl) {
              setExamModalAndMessage({
                examModalMessage: data.message,
              });
            } else {
              setExamModalAndMessage({
                examModalMessage: data.message
                  ? data.message
                  : "Please complete certification courses before attempting exam",
              });
            }
          }
        })
        .catch((error) => {
          setExamModalAndMessage({
            examModalMessage:
              "Please complete certification courses before attempting exam",
          });
        });
    };

    handleEditClick = (e, courseKey) => {
      e.stopPropagation();
      const {
        loggedProfileReducer: { branch },
        history,
      } = this.props;
      history.push(`/branches/${branch.key}/courses/${courseKey}/edit`);
    };

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
        courseInfo,
      } = this.props;

      const {
        level,
        user_progress,
        category,
        key,
        name,
        primary_category_info,
      } = courseInfo;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
        ...skillInfoData(courseInfo),
        level: level,
        course_category: category,
        course_key: key,
        course_name: name,
        course_progress: user_progress
          ? user_progress < 100
            ? "in_progress"
            : "completed"
          : "not_started",
        primary_category: primary_category_info?.name,
      });
    };

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handleEditClick={this.handleEditClick}
          handleCourseClicked={this.handleCourseClicked}
        />
      );
    }
  };

export default Container;
