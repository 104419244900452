import styled from "styled-components";

export const CourseBlock = styled.div`
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 996px) {
    &:hover {
      figure {
        img {
          transition: all 0.5s ease-in-out;
          transform: scale(1.05);
        }
      }
    }
  }

  .lock-course {
    position: absolute;
    color: ${props => props.theme.COLOR.BLACK};
    z-index: 1;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
  }

  .edit-icon-container {
    width: 35px;
    height: 35px;
    padding-top: 3px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: ${(props) => props.theme.COLOR.WHITE};
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    right: -1px;
    text-align: center;

    svg {
      width: 23px;
      height: 23px;
      margin-top: 1px;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      svg {
        transition: all 0.5s ease-in-out;
        fill: ${(props) => props.theme.COLOR.MEDIUM_GREY};
      }
    }
  }

  figure {
    margin-bottom: 8px;
    overflow: hidden;
    opacity: ${props => props.lockCourse ? 0.4 : 1};

    img {
      display: block;
      transition: all 0.5s ease-in-out;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .course-info-section {
    h4 {
      margin-bottom: 8px;
    }

    .course-meta {
      display: flex;
      flex-direction: row;
      align-items: center;

      .total-views {
        color: ${(props) => props.theme.COLOR.TEXT_BLACk};
        font-family: Montserrat;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;

        > span {
          margin: 0 14px;
          color: ${(props) => props.theme.COLOR.LIGHT_GREY};
        }

        @media screen and (max-width: 995px) {
          font-family: Roboto;
        }

        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 16px;

          > span {
            margin: 0 8px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .course-info-section {
      padding: 9px 20px 0 0;
      position: relative;

      .course-meta {
        position: absolute;
        right: 20px;
        bottom: 10px;
        left: 0;
      }
    }
  }

  //Note:: unstandardizeD media query is used to handle the use case of text getting overlapped with meta contents
  @media screen and (max-width: 370px) {
    .course-info-section {
      .course-meta {
        position: static;
      }
    }
  }
`;

export const RatingBlock = styled.span`
  padding: 3px 8px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.theme.COLOR_PALETTE[`${props.colorSchema}`].PRIMARY};
  color: ${(props) => props.theme.COLOR.TEXT_BLACk};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  svg {
    width: 19px;
    height: 19px;
    margin-right: 8px;
    fill: ${(props) => props.theme.COLOR.TEXT_BLACk};
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }

  @media screen and (max-width: 995px) {
    font-family: Roboto;
  }

  @media screen and (max-width: 767px) {
    padding: 2px 4px;
    font-size: 12px;
    line-height: 16px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
`;
