import React from "react";
import { fetchGet } from "utils/ajax-util";
import { COURSE_ENTITIES_ENDPOINT_V2 } from "constants/endpoints";
import { getDeviceDetail } from "utils";
import { HOME_PAGE_SEE_MORE_CLICKED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

const Container = (Main) =>
  class CategoryCourses extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        courseData: [],
        isCourseLoading: false,
        has_more: false,
      };
    }

    _fetchCourses = () => {
      const {
        loggedProfileReducer: { authKey, branch, isAdminRole },
        category,
        match,
      } = this.props;

      if (!(branch && branch.key) || !authKey) {
        this.setState({
          isCourseLoading: false,
        });
        return;
      }

      this.setState({
        isCourseLoading: true,
      });

      const branchKey = isAdminRole ? match.params.id : branch?.key;

      fetchGet(
        `${COURSE_ENTITIES_ENDPOINT_V2}fetch_category_based_courses/?branch_key=${branchKey}&no_of_tile=${3}&page=${1}&category=${category}&is_primary_category=${false}`,
        authKey
      )
        .then((response) => {
          const { success, data } = response;

          if (success) {
            if (category === "Recommended for you") {
              const courseList = data?.courses || [];
              const trimmedCourseList =
                courseList.length > 3 ? courseList.slice(0, 3) : courseList;
              
              trimmedCourseList.forEach((course) => {
                  course.source_category = 'recommended'
              });
              this.setState({
                isCourseLoading: false,
                courseData: trimmedCourseList,
                has_more: courseList.length > 3,
              });
            } else {
              if (category === "Continue Learning") {
                data.courses.forEach((course) => {
                  course.info.user_progress = course.progress;
                  course.info.is_independent_item = course.learn_item;
                });
              }
              this.setState({
                isCourseLoading: false,
                courseData: data.courses ? data.courses : [],
                has_more: data.has_more,
              });
            }
          } else {
            this.setState({
              isCourseLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isCourseLoading: false,
          });
          console.log(error);
        });
    };

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    handleViewMoreClick = () => {
      const { history, viewMoreLink, category } = this.props;
      this.callMixpanelEvent(HOME_PAGE_SEE_MORE_CLICKED, {
        category: category,
      });
      if (viewMoreLink) {
        history.push(viewMoreLink, {
          categoryDetails: this.state.courseData[0].primary_category_info,
        });
      }
    };

    componentDidMount() {
      this._fetchCourses();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handleViewMoreClick={this.handleViewMoreClick}
        />
      );
    }
  };

export default Container;
