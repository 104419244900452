import styled from "styled-components";
import configureTheme from "theme";
const theme = configureTheme();

export const SiteWrapper = styled.div`
  .grid-container-h3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .grid-h3 {
      width: calc((100% - 48px) / 3);
      margin: 0 24px 24px 0;

      figure {
        border-radius: 12px;
        border: ${(props) => `0.5px solid ${props.theme.COLOR.BORDER_GREY}`};

        img {
          width: 100%;
          border-radius: 12px;
        }
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0 0 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 1px;
          margin: 16px 0 0;
          display: block;
          background: ${(props) => props.theme.COLOR.GRID_SEPERATOR_GREY};
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        figure {
          flex-basis: 45%;
          margin-right: 16px;
        }

        .course-info-section {
          flex-basis: calc(55% - 16px);
        }
      }
    }
  }

  .content-heading {
    color: ${theme.COLOR.TEXT_BLACk};
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /*For clamping title after 2lines*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: 995px) {
      font-family: Roboto;
    }

    @media screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .category-title-heading-primary {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;

    @media screen and (max-width: 995px) {
      font-family: Roboto;
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .section-heading {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    color: ${(props) => props.theme.COLOR.TEXT_BLACK};

    @media screen and (max-width: 995px) {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .course-section-heading {
    font-family: Montserrat;
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
    color: ${(props) => props.theme.COLOR.TEXT_BLACK};

    @media screen and (max-width: 995px) {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }

    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .default-button {
    height: 48px;
    padding: 8px 6px;
    border: none;
    border-radius: 24px;
    background: ${(props) => props.theme.COLOR.CTA_BG_BLACK};
    color: ${(props) => props.theme.COLOR.WHITE};
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    box-shadow: 8px 12px 32px 0 rgba(29, 33, 31, 0.25);
    cursor: pointer;
  }

  .explore-academy-skeleton-loader {
    width: 60%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .lazy-load-image-background {
    display: block !important;
  }

  .advanced-description {
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: ${(props) => props.theme.COLOR.LINK_BLUE};
      text-decoration: underline;
    }

    ol li {
      list-style: number;
    }

    ul li {
      list-style: disc;
    }

    ol,
    ul {
      padding-left: 15px;
    }
  }
`;
