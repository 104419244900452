import { AppBar, Grid, Toolbar, withStyles } from '@material-ui/core';
import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MobileSearchBarComponent from './mobileSearchBar';

const useStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "1em 0"
    },
    rowLayout: {
        width: "100%",
        display: "flex",
        minHeight: "80px",
        maxWidth: "1140px",
        margin: "auto"
    },
    icon: {
        transform: 'translate(25%, 10%);'
    }
});

class MobileSearchHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onHandleClick = () => {
        this.props.history.push('/');
    }

    render() {
        return <AppBar position="static" style={{ backgroundColor: "#272929" }}>
            <Toolbar>
                <Grid className={this.props.classes.root}>
                    <Grid container alignItems="center" className={this.props.classes.rowLayout}>
                        <Grid item xs={2}>
                            <ArrowBackIcon className={this.props.classes.icon} onClick={() => this.onHandleClick()} />
                        </Grid>
                        <Grid item xs={6} >
                            <MobileSearchBarComponent {...this.props} />
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>;
    }
}

export default withStyles(useStyles)(MobileSearchHeader);
