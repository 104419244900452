import React from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {
    return <Grid style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "75vh"
    }}
        item
        md={12}
        xs={12}
    >
        <div align="center">
            <CircularProgress size={25} color="primary" />
        </div>
    </Grid>
};

export default Loader;
