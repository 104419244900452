import React from "react";
import { MdModeEdit, MdStarRate } from "react-icons/md";
import { IoIosLock } from "react-icons/io";

import ExamPopupContainer from "../ExamPopUp";
import Container from "./container";

import { CourseBlock, RatingBlock } from "./style/style";

const PospCourseTile = (props) => {
  const {
    courseInfo: {
      key,
      image_url,
      name,
      total_subscribers,
      avg_rating,
      category,
    },
    colorSchema,
    lockCourse,
    loggedProfileReducer: { isCourseAdminRole },
    handleCourseClicked,
    handleEditClick,
    modal,
  } = props;

  return (
    <>
      <CourseBlock
        className="grid-h3"
        onClick={(e) => handleCourseClicked(key, category, lockCourse)}
        lockCourse={lockCourse}
      >
        {isCourseAdminRole ? (
          <span
            className="edit-icon-container"
            title="Edit"
            onClick={(e) => handleEditClick(e, key)}
          >
            <MdModeEdit />
          </span>
        ) : null}
        <figure>
          <img
            src={
              image_url
                ? image_url
                : "https://d3gthpli891tsj.cloudfront.net/wp-content/uploads/2019/01/22063215/GATE-Crash-Course.jpg"
            }
            alt="Course Thumbnail"
          />
        </figure>
        {lockCourse ? <div className="lock-course">
           <IoIosLock/>
        </div> : null}
        <div className="course-info-section">
          <h4 className="content-heading">{name}</h4>
          {avg_rating || total_subscribers ? (
            <div className="course-meta">
              {avg_rating ? (
                <RatingBlock colorSchema={colorSchema}>
                  <MdStarRate />
                  {avg_rating}
                </RatingBlock>
              ) : null}
              {total_subscribers ? (
                <span className="total-views">
                  {avg_rating ? <span>|</span> : null}
                  {total_subscribers > 1000
                    ? `${Math.ceil(total_subscribers / 1000)}k`
                    : total_subscribers}{" "}
                  {total_subscribers > 1 ? "views" : "view"}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </CourseBlock>
      {modal ? <ExamPopupContainer /> : null}
    </>
  );
};

export default Container(PospCourseTile);
