import React from "react";
import { fetchGet } from "utils/ajax-util";
import { LEARN_ITEM_ENDPOINT } from "constants/endpoints";
import { getDeviceDetail } from "utils";
import { Mixpanel } from "Mixpanel";

const Container = (Main) =>
  class GyanIn2Mins extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        independentContentData: [],
        isContentLoading: false,
        hasMore: false,
      };
    }

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    _fetchContent = () => {
      const {
        loggedProfileReducer: { authKey, branch },
        category,
      } = this.props;

      if (!(branch && branch.key) || !authKey) {
        return;
      }

      this.setState({
        isContentLoading: true,
      });

      fetchGet(
        `${LEARN_ITEM_ENDPOINT}get_category_wise_independent_learnitems/?branch=${
          branch.key
        }&category=${category}&page_number=${1}&results_per_page=${10}`,
        authKey
      )
        .then((response) => {
          const { success, data } = response;

          if (success) {
            this.setState({
              isContentLoading: false,
              independentContentData: data.learn_items ? data.learn_items : [],
              hasMore: data.has_more,
            });
          } else {
            this.setState({
              isContentLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isContentLoading: false,
          });
          console.log(error);
        });
    };

    handleViewMoreClick = () => {
      const { history, viewMoreLink } = this.props;
      if (viewMoreLink) {
        history.push(viewMoreLink);
      }
    };

    componentDidMount() {
      this._fetchContent();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          callMixpanelEvent={this.callMixpanelEvent}
          handleViewMoreClick={this.handleViewMoreClick}
        />
      );
    }
  };

export default Container;
