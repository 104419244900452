/*  eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "../styles/style";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import { withCookies } from "react-cookie";
import { Mixpanel } from "../Mixpanel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  USER_LOGIN_SUCCESSFUL,
  USER_LOGIN_UNSUCCESSFUL,
  USER_LOGOUT,
  USER_MAGICLINK_REQUESTED,
  DEEP_LINK_ACCESSED,
} from "../constants/mixpanel";

import { connect } from "react-redux";

import{ REACT_APP_API_BASE_URL } from 'global';
import { loggedProfile } from "store/actions/loggedProfile";

const mapDispatchToProps = (dispatch) => ({
  loggedProfile: (data) => dispatch(loggedProfile(data)),
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  loggedProfileReducer: state.loggedProfileReducer,
});

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.signIn = this.signIn.bind(this);
    if (this.props.verify) {
      this.props.hideHeaders(true);
    } else {
      this.props.hideHeaders(false);
    }
  }

  componentWillUnmount() {
    this.props.hideHeaders(false);
  }

  getExtraAttributeFromNextUrl = (nextUrl) => {
    // this nextUrl condition checks only for dynamic deeplink.
    // dynamic deeplink url always contains item/:itemName/category/:categoryName
    if (nextUrl.includes('item')) {
      const urlParts = nextUrl.split('/');
    // Get the index of 'item' and 'category' in the array
      const itemIndex = urlParts.indexOf('item') + 1;
      const categoryIndex = urlParts.indexOf('category') + 1;
      // Get the values of 'item' and 'category'
      const contentType = urlParts[itemIndex];
      const category = urlParts[categoryIndex]
      let extraAttribute;
      if (contentType)
          extraAttribute = {
              'deeplink_category': category,
              'deeplik_content_type': contentType
          }
      return extraAttribute;
    }
  }

  verifyUser = (data) => {
    if (data.XPGUID) {
      //mixpanel event track
      const branchKey = data.meta.branch_key;
      const { cookies, history, changeUserName, loggedProfile, fetchBranches, fetchLogo, back_url, next_url,
        utm_source, utm_campaign, utm_medium, posp_type } = this.props;
      if (data.meta) {
        const { meta: {branch_key, branch_name }} = data;
        Mixpanel.track(USER_LOGIN_SUCCESSFUL, {
          email: data.user.email,
          branch_key: branch_key,
          branch_name: branch_name,
          partnerId: data.user.partner_id,
        });
        window.sessionStorage.setItem("branchName", branch_name);
        window.sessionStorage.setItem("branchKey", branch_key);
      }
      cookies.set("key", data.XPGUID, { path: "/" });
      if (data.user) {
        const {
          user: { email, key, full_name, partner_id, meta, user_type, user_cohort },
        } = data;

        cookies.set("user_email", email, { path: "/" });
        cookies.set("user_key", key, { path: "/" });
        cookies.set("user_name", full_name, { path: "/" });
        cookies.set("partner_id", partner_id, {
          path: "/",
        });
        changeUserName(full_name);

        const loginProfile = {
          userEmail: email,
          authKey: data.XPGUID[0],
          userKey: key,
          fullName: full_name,
          partnerId: partner_id,
          role:
            user_type === "super_admin" || user_type === "branch_admin"
              ? "admin"
              : user_type,
          hasSwitchBranchAccess:
            user_type === "super_admin" || user_type === "tech",
          isAdminRole:
            user_type === "super_admin" ||
            user_type === "tech" ||
            user_type === "content" ||
            user_type === "l_and_d" ||
            user_type === "branch_admin"
              ? true
              : false,
          isCourseAdminRole:
            user_type === "super_admin" ||
            user_type === "admin" ||
            user_type === "tech" ||
            user_type === "content" ||
            user_type === "l_and_d" ||
            user_type === "branch_admin"
              ? true
              : false,
          hasUserManagementAccess:
            user_type === "super_admin" ||
            user_type === "branch_admin" ||
            user_type === "tech",
          ...(meta &&
            meta.is_webinar_admin && {
              webinarAdmin: true,
            }),
          ...(data.meta && {
            branch: {
              key: data.meta.branch_key,
              name: data.meta.branch_name,
            },
            broker: data.meta.broker,
            tenant: data.meta.tenant
          }),
          user_type: user_type,
          logo_url: data.logo_url,
          user_cohort: user_cohort
        };

        loggedProfile(loginProfile);

        if (
          user_type === "superadmin" ||
          user_type === "super_admin" ||
          user_type === "branch_admin"
        ) {
          cookies.set("role", "admin", { path: "/" });
          fetchBranches();
        } else {
          cookies.set("role", user_type, { path: "/" });
          fetchLogo();
        }
        if (data.meta && data.meta.is_webinar_admin) {
          cookies.set("webinar_admin", "true", { path: "/" });
        }
        Mixpanel.identify({ partnerId: data.user.partner_id });
        if (back_url)
          sessionStorage.setItem("back_url", decodeURI(this.props.back_url));
        else sessionStorage.removeItem("back_url");
        if (next_url) {
          const extraAttribute = this.getExtraAttributeFromNextUrl(next_url);
          Mixpanel.track(DEEP_LINK_ACCESSED, {
            email: data.user.email,
            next_url: next_url,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            ...extraAttribute
          });
          history.replace(next_url);
        } else {
          if (history && posp_type && data.meta.branch_name !== 'IPPB Training') {
            if (posp_type.toLowerCase() === 'gi') {
              history.push(`branches/${branchKey}/allcertificates`);
            } else {
              history.push(`branches/${branchKey}/allcertificates#posp-${this.props.posp_type.toLowerCase()}`);
            }
          } else if (history) {
            history.replace("/");
          } else {
            window.location.href = "/";
          }
        }

        this.setState({
          authenticatingUserFlag: false,
        });
      }
    } else {
      this.setState({
        authenticatingUserFlag: false,
      });
      if (window.mpIsApp) {
        window.postMessage(JSON.stringify({ type: "close" }));
      } else {
        Mixpanel.track(USER_LOGIN_UNSUCCESSFUL, { email: "" });
        this.props.enqueueSnackbar("Something went wrong! Please try again", {
          variant: "error",
          autoHideDuration: 1500,
        });
      }
    }
  };

  componentDidMount() {
    this.setState({ classes: this.props.classes });

    if (this.props.verify) {
      this.setState({
        authenticatingUserFlag: true,
      });
      localStorage.clear();
      this.props.cookies.remove("key", { path: "/" });
      this.props.cookies.remove("role", { path: "/" });
      this.props.cookies.remove("webinar_admin", { path: "/" });
      this.props.cookies.remove("mode", { path: "/" });
      this.props.cookies.remove("user_key", { path: "/" });
      this.props.changeUserName("");
      this.props.cookies.remove("user_name", { path: "/" });
      this.props.cookies.remove("user_email", { path: "/" });
      this.props.cookies.remove("partner_id", { path: "/" });
      fetch(
        `${REACT_APP_API_BASE_URL}/api/lms/magiclogin/?url_auth_token=${this.props.verify_key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 503) {
            this.props.enqueueSnackbar("Request limit exceeded", {
              variant: "error",
              autoHideDuration: 1500,
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            this.verifyUser(data.data);
          } else {
            this.props.enqueueSnackbar(
              "Something went wrong, Please try again",
              {
                variant: "error",
                autoHideDuration: 1500,
              }
            );
          }
        });
    } else {
      this.props.setHeading("Authentication");
    }
    if (this.props.auth === "logout") {
      //logout mixpanel
      const email = this.props.cookies.get("user_email");
      this.props.cookies.remove("key", { path: "/" });
      this.props.cookies.remove("role", { path: "/" });
      this.props.cookies.remove("webinar_admin", { path: "/" });
      this.props.cookies.remove("mode", { path: "/" });
      this.props.cookies.remove("user_key", { path: "/" });
      this.props.changeUserName("");
      this.props.cookies.remove("user_name", { path: "/" });
      this.props.cookies.remove("user_email", { path: "/" });
      this.props.cookies.remove("partner_id", { path: "/" });
      this.props.enqueueSnackbar("Successfully Logged Out", {
        variant: "success",
        autoHideDuration: 1500,
      });
      Mixpanel.track(USER_LOGOUT, {
        email: email,
        branch_key: this.props.user_branch_key,
        branch_name: this.props.user_branch_name,
      });
    }
  }

  signIn() {
    try {
      fetch(`${REACT_APP_API_BASE_URL}/api/lms/magiclogin/request_magic_link/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.state.email,
        }),
      })
        .then((response) => {
          if (response.status === 503) {
            this.props.enqueueSnackbar("Request limit exceeded", {
              variant: "error",
              autoHideDuration: 1500,
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            this.props.enqueueSnackbar("Check Your Mail for Login Link", {
              variant: "success",
              autoHideDuration: 1500,
            });
          } else {
            this.props.enqueueSnackbar(
              "Something went wrong, Please try again",
              {
                variant: "error",
                autoHideDuration: 1500,
              }
            );
          }
        });
      // this.setState({message:"Check Your Mail for Login Link"});

      Mixpanel.track(USER_MAGICLINK_REQUESTED, { email: this.state.email });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        {!this.state.authenticatingUserFlag ? (
          this.state.classes ? (
            <Grid container>
              <Grid item md={3}></Grid>
              <Grid className={this.state.classes.content} item md={6} xs={12}>
                <div className={this.state.classes.content}>
                  <div className={this.state.classes.contentBody}>
                    {this.props.auth === "login" ||
                    (this.props.loggedProfileReducer &&
                      this.props.loggedProfileReducer.role) ? (
                      this.props.verify ? (
                        // <Typography
                        // className={this.state.classes.title}
                        // variant="h4"
                        // align="center"
                        // >
                        // <Link to={'/'}>Go To Home Page</Link>
                        // </Typography>
                        <div
                          style={{
                            lineHeight: "100vh",
                            textAlign: "center",
                          }}
                          align="center"
                        >
                          <CircularProgress size={25} color="primary" />
                        </div>
                      ) : (
                        <>
                          <Typography
                            className={this.state.classes.title}
                            variant="h4"
                            align="center"
                          >
                            Sign in
                          </Typography>
                          <br />
                          <TextField
                            className={this.state.classes.textField}
                            fullWidth
                            label="Email address"
                            name="email"
                            // onChange={handleChange}
                            type="text"
                            // value={formState.values.email || ''}
                            variant="outlined"
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                          <br />
                          <br />
                          <Button
                            // className={classes.signInButton}
                            color="primary"
                            // disabled={!formState.isValid}
                            onClick={this.signIn}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Sign In
                          </Button>
                        </>
                      )
                    ) : (
                      <Typography
                        className={this.state.classes.title}
                        variant="h4"
                        align="center"
                      >
                        <Link to={"/"}>Go To Home Page</Link>
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )
        ) : (
          <div>Loading</div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(withSnackbar(withStyles(styles)(Auth))));
