import styled from "styled-components";
import Slider from "react-slick";

export const MainContainer = styled.div`
  .edit-icon-container {
    width: 35px;
    height: 35px;
    padding-top: 3px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: ${(props) => props.theme.COLOR.WHITE};
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    text-align: center;

    svg {
      width: 23px;
      height: 23px;
      margin-top: 1px;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      svg {
        transition: all 0.5s ease-in-out;
        fill: ${(props) => props.theme.COLOR.MEDIUM_GREY};
      }
    }
  }

  .slide-container {
    border-radius: 12px;
    border: 0.5px solid ${(props) => props.theme.COLOR.BORDER_GREY};
    position: relative;
    cursor: pointer;

    h6 {
      padding: 10px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${(props) => props.theme.COLOR.FLASHCARD_TILE_BG};
      color: ${(props) => props.theme.COLOR.WHITE};
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      max-height: 60px;

      span {
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }

    figure {
      height: 100%;
      width: 162px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }
  }
`;

export const DesktopContainer = styled(Slider)`
  .edit-icon-container {
    width: 35px;
    height: 35px;
    padding-top: 3px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: ${(props) => props.theme.COLOR.WHITE};
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    text-align: center;

    svg {
      width: 23px;
      height: 23px;
      margin-top: 1px;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      svg {
        transition: all 0.5s ease-in-out;
        fill: ${(props) => props.theme.COLOR.MEDIUM_GREY};
      }
    }
  }

  .slick-slide {
    padding: 0 20px 0 0;

    &:last-child {
      padding-right: 0;
    }

    @media screen and (max-width: 1140px) {
      padding: 0 16px 0 0;
    }
  }

  .slick-track {
    display: flex;
    margin-left: unset !important;
    .slick-slide {
      height: auto;

      > div {
        height: 100%;
      }
    }
  }

  .slide-container {
    height: 100%;
  }
  .slick-disabled {
    display: none !important;
  }

  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;
    padding-top: 10px;
    border-radius: 8px;
    background: ${(props) => props.theme.COLOR.DARK_GREY};
    z-index: 1;
    text-align: center;

    &::before {
      display: none;
    }
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  .slide-container {
    flex: 0 0 200px;
    margin-right: 10px;

    h6 {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      max-height: 42px;
    }
    figure {
      height: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 995px) {
    .slide-container {
      h6 {
        padding: 5px 10px;
        font-family: Roboto;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .slide-container {
      flex: 0 0 122px;
      margin-right: 10px;
    }
  }
`;
