import React from 'react';
import './courseStyles.css';

class CourseListHeader extends React.Component {
    render() {
    return <div className="course-list-header"><span style={{ marginLeft: '5%', paddingRight:'10px' }}>{this.props.heading}</span></div>;
    }
}

export default CourseListHeader;
