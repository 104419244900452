import React from "react";
import { fetchGet } from "utils/ajax-util";
import { CHANNEL_ENDPOINT } from "constants/endpoints";
import { getDeviceDetail } from "utils";
import { HOME_PAGE_CHANNEL_SEE_MORE_CLICKED, HOME_PAGE_CHANNEL_TILE_CLICKED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

const Container = (Main) =>
  class PartnerChannel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        channelList: [],
        isPartnerChannelLoading: true,
      };
    }

    _fetchChannelList = () => {
      const {
        loggedProfileReducer: { authKey, branch },
      } = this.props;

      if (!(branch && branch.key) || !authKey) {
        this.setState({
          isPartnerChannelLoading: false,
        });
        return;
      }

      fetchGet(
        `${CHANNEL_ENDPOINT}?branch_key=${branch.key}&results_per_page=10`,
        authKey
      )
        .then((data) => {
          const { success, channels } = data;

          if (success) {
            this.setState({
              isPartnerChannelLoading: false,
              channelList: channels ? channels : [],
            });
          } else {
            this.setState({
              isPartnerChannelLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isPartnerChannelLoading: false,
          });
          console.log(error);
        });
    };

    _onViewMoreClick = () => {
      const { history, viewMoreLink } = this.props;

      this.callMixpanelEvent(HOME_PAGE_CHANNEL_SEE_MORE_CLICKED);

      if (viewMoreLink) {
        history.push(viewMoreLink);
      }
    };

    handleChannelClick = (channelInfo) => {
      const { history } = this.props;
      const { name, deeplink, key } = channelInfo;
      this.callMixpanelEvent(HOME_PAGE_CHANNEL_TILE_CLICKED, {
         channel_name: name,
         channel_key: key,
         deeplink: deeplink
      });
      history.push(`/channels/${key}`);
    }
    
    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    componentDidMount() {
      this._fetchChannelList();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          onViewMoreClick={this._onViewMoreClick}
          handleChannelClick={this.handleChannelClick}
        />
      );
    }
  };

export default Container;
