/*  eslint-disable */
import React from 'react'
import styles from '../styles/style';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Auth from '../components/auth';
import queryString from 'query-string';

class AuthPage extends React.Component {

  constructor(props) {
  	super(props);
    this.state = {
      mobileOpen: false,
      branches:[],
      firsTime: true,
      heading: "Home",
      tabs: false,
      selectedTab: 0,
      branchName: "",
      breadcrumbsIndex:0
    }
  }

  componentDidMount(){
    const { url_auth_token, back_url, next_url, 
      utm_medium, utm_source, utm_campaign, utm_content, posp_type } = queryString.parse(this.props.location.search);
    let modifiedUrl = next_url;
    let hashValue = this.props.location.hash;
      if (hashValue.startsWith('#')) {
        hashValue = hashValue.slice(1);
        if (hashValue) {
          modifiedUrl = `${next_url}#${hashValue}`
        }
      }
    this.setState({ next_url: modifiedUrl  });
    this.setState({url_auth_token, back_url, utm_medium, utm_source, utm_campaign, utm_content, posp_type });
    this.props.setBranchPage(false);
    this.props.setSearchBarBox(false);
    this.props.setTabs(false);
    this.setState({classes:this.props.classes});
  }
  
  render(){

    const { classes, back_url, next_url, url_auth_token, utm_source, utm_medium, utm_campaign, posp_type } = this.state
    return (
      classes? <main className={classes.main}>
              <Auth back_url={back_url} next_url={next_url} {...this.props} 
              verify_key={url_auth_token} 
              utm_source={typeof utm_source === 'undefined' ? 'not-defined' : utm_source}
              utm_medium={ typeof utm_medium === 'undefined' ? 'not-defined' : utm_medium} 
              utm_campaign={typeof utm_campaign === 'undefined' ? 'not-defined' : utm_campaign}
              utm_content={typeof utm_content === 'undefined' ? 'not-defined' : utm_content}
              posp_type={posp_type}
              auth={this.props.auth} verify={url_auth_token ? true : false}/>
      </main>
      :""
    )
  }
}

export default withStyles(styles)(AuthPage);