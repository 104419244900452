import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const CreateCoursePage = React.lazy(() => import("pages/createCourse"));
const CreateIndependentContentPage = React.lazy(() =>
  import("pages/createIndependentContent")
);

const CreateChannel = React.lazy(() => import("pages/channel/createChannel"));
const CreateChannelCategoryPage = React.lazy(() =>
  import("pages/channel/createCategory")
);

const CreateFlashcardPage = React.lazy(() => import("pages/AdminControls/CreateFlashCard"))

export default [
  {
    path: "/branches/:branchKey/channels/create",
    Component: CreateChannel,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:branchKey/channels/:channelKey/edit",
    Component: CreateChannel,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/channels/:channelKey/channel-category/create",
    Component: CreateChannelCategoryPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/channels/:channelKey/channel-category/:channelcategoryKey/edit",
    Component: CreateChannelCategoryPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/channels/:channelKey/courses/create",
    Component: CreateCoursePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/channels/:channelKey/courses/:course/edit",
    Component: CreateCoursePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/channels/:channelKey/createcontent",
    Component: CreateIndependentContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/channels/:channelKey/editcontent/:content",
    Component: CreateIndependentContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/channels/:channelKey/flashcards/create",
    Component: CreateFlashcardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/channels/:channelKey/flashcards/:flashcard/edit",
    Component: CreateFlashcardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
];
