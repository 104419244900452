import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FlashcardCarousel from "components/FlashcardCarousel";

import { NextIcon } from "pages/NewHomePage/images";

import Container from "./container";
import CardSkeleton from "./Skeleton";
import { FlashContainer } from "./styles/style.js";

const GyanIn2Mins = (props) => {
  const {
    isContentLoading,
    independentContentData,
    loggedProfileReducer: { isCourseAdminRole },
    isMobile,
    title,
    handleViewMoreClick,
    hasMore,
    callMixpanelEvent,
    history,
    loggedProfileReducer,
  } = props;

  return isContentLoading ? (
    <div className="site-wrapper">
      <CardSkeleton isMobile={isMobile} />
    </div>
  ) : !isContentLoading &&
    Array.isArray(independentContentData) &&
    independentContentData.length ? (
    <FlashContainer>
      <div className="site-wrapper">
        <div className="heading-container">
          <h3 className="course-section-heading">
            {title ? title : "Gyan In 2 Mins"}
          </h3>
          {hasMore ? (
            <button title="View More Content" onClick={handleViewMoreClick}>
              <span className="view-more-text">View More</span>
              <span className="icon-container">
                <NextIcon />
              </span>
            </button>
          ) : null}
        </div>
        <FlashcardCarousel
          independentContentData={independentContentData}
          isCourseAdminRole={isCourseAdminRole}
          history={history}
          contentClickMixpanelEvent={(event, attributes) =>
            callMixpanelEvent(event, attributes)
          }
          loggedProfileReducer={loggedProfileReducer}
        />
      </div>
    </FlashContainer>
  ) : null;
};

export default Container(GyanIn2Mins);
