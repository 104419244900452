import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyLoadImageContainer = ({ imageDetails }) => {
  return (
    <LazyLoadImage
      effect="blur"
      alt={imageDetails.alt}
      height={imageDetails?.height}
      src={imageDetails.src}
    />
  );
};

export default LazyLoadImageContainer;

