import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const CreateContentPage = React.lazy(() => import("pages/createContent"));
const CreateModulePage = React.lazy(() => import("pages/createModule"));
const LearningPathCreatePage = React.lazy(() =>
  import("pages/learningPath/createLearningPath")
);
const CreateRewardPage = React.lazy(() => import("pages/createRewards"));

export default [
  /* =============================== Learning Path =============================== */
  {
    path: "/branches/:id/learningpath/create",
    Component: LearningPathCreatePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/edit",
    Component: LearningPathCreatePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/modules/create",
    Component: CreateModulePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/modules/:module/edit",
    Component: CreateModulePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/modules/:module/createcontent",
    Component: CreateContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/modules/:module/editcontent/:content",
    Component: CreateContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/reward/create",
    Component: CreateRewardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/reward/:rewardKey/edit",
    Component: CreateRewardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },

  {
    path: "/branches/:id/learningpath/:learningPathKey/module/:moduleKey/reward/create",
    Component: CreateRewardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/module/:moduleKey/reward/:rewardKey/edit",
    Component: CreateRewardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/module/:moduleKey/learn-item/:learnItemKey/reward/create",
    Component: CreateRewardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/learningpath/:learningPathKey/module/:moduleKey/learn-item/:learnItemKey/reward/:rewardKey/edit",
    Component: CreateRewardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
];
