import { _gtmDataLayer, _appInfo, _deviceInfo, _extraInfo, _commonData } from './services';

// NOTE MXP Identifying
export const IdentifyUserForMXP = params => {
    const { p_id, source } = params || {};
    const { partnerId } = _commonData({ source }) || {};
    let _obj = {};
    _obj.userName = p_id || partnerId;
    // _obj.name = name;
    // _obj.email = email;
    // _obj.lastName = lastName;
    // _obj.id = id;
    _gtmDataLayer('IdentifyUser', _obj);
}

// NOTE Push to GTM
export const pushToGTMDataLayer = (paramObj) => {
    // NOTE Initiate method
    const { source } = paramObj || {};
    const commonData = _commonData({ source });
    const deviceInfo = _deviceInfo();
    const appInfo = _appInfo();
    const extraInfo = _extraInfo(paramObj);

    const _cdata = {...commonData, ...deviceInfo, ...appInfo, ...extraInfo};
    const _joinData = { ...paramObj, ..._cdata };
    _gtmDataLayer('track_events', _joinData);
}

export default {
    pushToGTMDataLayer,
    IdentifyUserForMXP,
}