import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NewCourseTile from "components/NewCourseTile";

import CardSkeleton from "pages/NewHomePage/components/CardSkeleton";

import { NextIcon } from "pages/NewHomePage/images";
import { CategoryCourseContainer } from "./styles/style.js";
import Container from "./container";

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 140,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 40,
      },
    },
  ],
};

const ConditionalParent = ({ isMobile, children }) => {
  return isMobile ? (
    <Slider {...settings}>{children}</Slider>
  ) : (
    <div className="grid-container-h3">{children}</div>
  );
};

const CategoryCourses = (props) => {
  const {
    isCourseLoading,
    courseData,
    loggedProfileReducer,
    history,
    match,
    isMobile,
    category,
    title,
    handleViewMoreClick,
    has_more
  } = props;

  return isCourseLoading ? (
    <div className="site-wrapper">
      <CardSkeleton isMobile={isMobile} />
    </div>
  ) : !isCourseLoading && Array.isArray(courseData) && courseData.length ? (
    <CategoryCourseContainer>
      <div className="site-wrapper">
        <div className="heading-container">
          <h3 className="course-section-heading">{title}</h3>
          {has_more ? (
            <button title="View More Courses" onClick={handleViewMoreClick}>
              <span className="view-more-text">View More</span>
              <span className="icon-container">
                <NextIcon />
              </span>
            </button>
          ) : null}
        </div>

        <ConditionalParent isMobile={isMobile}>
          {courseData.map((item, index) => {
            return (
              <NewCourseTile
                courseInfo={category === "Continue Learning" ? item.info : item}
                colorSchema={"GREEN_PALETTE"}
                loggedProfileReducer={loggedProfileReducer}
                key={item.key}
                history={history}
                match={match}
                content_source="homepage"
                course_sequence={index + 1}
              />
            );
          })}
        </ConditionalParent>
      </div>
    </CategoryCourseContainer>
  ) : null;
};

export default Container(CategoryCourses);
