import React from 'react'
import { TransitionScreenHat } from "images";
import logo from "images/TurtlemintAcademyLogo.svg";
import 'styles/default.css';


function TransitionPageLoader() {
    return (
        <div className="transition-page-loader">
            <figure>
                <TransitionScreenHat />
                <img src={logo} alt="logo" />
            </figure>
        </div>
  )
}

export default TransitionPageLoader;
