import{ REACT_APP_API_BASE_URL } from 'global';

const prefix = `${REACT_APP_API_BASE_URL}/api/lms`;
export const MODULES_FEED_ENDPOINT = `${prefix}/feeds/modules`;
export const VQA_ENDPOINT = `${prefix}/vqa`;
export const USERS_INFO_ENDPOINT = `${REACT_APP_API_BASE_URL}/api/users/info`;

export const BRANCHES_ENDPOINT_V2 = `/api/lms/branch/`;
export const USERS_ENDPOINT = `/api/lms/users/`;
export const COURSE_ENTITIES_ENDPOINT_V2 = `/api/lms/entities/courses/`;
export const LEARN_ITEM_ENDPOINT = '/api/lms/learn-items/';
export const FEEDBACK_ENDPOINT = '/api/lms/feedback/';
export const INTEREST_ENDPOINT = '/api/lms/interest/';
export const MODULE_ENDPOINT = '/api/lms/modules/';
export const CATEGORIES_ENDPOINT = '/api/lms/categories/';
export const CHANNEL_ENDPOINT = '/api/lms/channels/';
export const CHANNEL_CATEGORY_ENDPOINT = '/api/lms/channel-category/';
export const COURSES_IN_CHANNEL_ENDPOINT = '/api/lms/courses/get_courses_by_channel_category/';
export const USERCHANNEL_ENDPOINT = `/api/lms/user-channel/`;
export const FLASHCARD_ENDPOINT = `/api/lms/flashcards/`;
export const QUESTIONS_ENDPOINT = `/api/lms/questions/`;
export const CUSTOMERPITCH_ENDPOINT = `/api/lms/customer-pitch/`;
export const CUSTOMERPITCH_QUESTION_ENDPOINT = `/api/lms/customer-pitch-question/`;
export const CUSTOMERPITCH_CHECKLIST_ENDPOINT = `/api/lms/customer-pitch-question-checklist/`;
export const USER_CUSTOMERPITCH_ENDPOINT = '/api/lms/user-customer-pitch/';
export const USER_CUSTOMERPITCH_QUESTION_ENDPOINT = '/api/lms/user-customer-pitch-question/';
export const SEARCH_ENDPOINT = `/api/lms/search/`;
export const ASSESSMENT_QUESTION_ENDPOINT = `/api/lms/assessment-question/`;
export const ASSESSMENT_ANSWER_ENDPOINT = `/api/lms/assessment-answer/`;
export const ASSESSMENT_STATE_ENDPOINT = `/api/lms/assessment-state/`;
export const ASSESSMENT = '/api/lms/assessment/';
export const USER_ASSESSMENT = '/api/lms/user-assessment/';
export const MCQ_ENDPOINT = '/api/lms/mcq';
export const LEARNINGPATH_ENDPOINT = '/api/lms/learning-paths';
export const USERLEARNINGPATH_ENDPOINT = '/api/lms/user-learning-paths';
export const REWARD_ENDPOINT = '/api/lms/reward-config/';
export const AB_FRAMEWORK_ASSIGN_VARIANT = '/api/lms/ab-framework-gateway/assign-variant/';
export const RECOMMENDED_COURSES_ENDPOINT = '/api/lms/courses/recommended/';
export const EXTERNAL_BRANCH_ENDPOINT = '/api/lms/branch-content/';
export const INPROGRESS_COURSES_ENDPOINT = '/api/lms/courses/';
export const RECOMMENDED_ITEMS_ENDPOINT = '/api/lms/courses/get_recommended_courses_and_learn_items/'
export const EXTERNAL_INDEPENDENT_LI_ENDPOINT = '/api/lms/branch-content/external-independent-li/';
export const USER_LP_RECOMMENDATIONS = '/api/lms/learning-paths/active_learning_path_list/';
export const USER_ALL_CERTIFICATES = '/api/lms/learning-paths/completed_user_learning_path/';
export const ASSIGNED_COURSES_ENDPOINT = '/api/lms/courses/';
export const GET_ROLES = '/api/lms/user-type/';

export const MARK_INPROGRESS_COURSE_ENDPOINT = '/api/lms/courses/';
export const UPDATE_DEFAULT_COURSE_PROGRESS = '/api/lms/courses/';

export const TURTLE_REELS_ENDPOINT = '/api/lms/turtle-reels/';
export const QUIZ_ENDPOINT = '/api/lms/quizzes/';
export const GET_UNCOMPLETED_ITEM = '/api/lms/courses/uncompleted-user-items/';

export const PREMIUM_PAYMENT_COLLECTION = '/api/lms/courses/get_payment_collection/';
export const PREMIUM_USER_COURSES = '/api/lms/courses/premium_user_course/';
export const SAVE_PAYMENT_STATUS = '/api/lms/courses/save_payment_status/';
export const GET_PAYMENT_STATUS = '/api/lms/courses/get_payment_status';
export const GET_PAID_COURSES = '/api/lms/courses/get_premium_paginated_courses/';

export const MANUAL_TASK_ENDPOINT = '/api/lms/manual-task/';
export const FORCED_LIST_ENDPOINT = '/api/lms/forced-list/';
export const SKILL_ENDPOINT = '/api/lms/skill-hierarchy/'
export const CATEGORY_ENDPOINT = '/api/lms/primary-category/';
export const PARTNER_STORIES = '/api/lms/partner-stories/';

export const WEBINAR_ENDPOINT = '/api/lms/webinars/'

export const COURSE_COMPLETION_ENDPOINT = '/api/lms/courses/mark_course_complete/'

export const BANNER_CARD = '/api/lms/banner-card/'
export const ACTION_ITEMS = '/api/lms/action-items/'
export const PAGE_SKELETON = '/api/lms/page-skeleton/'

export const PAYMENT_COLLECTION = '/api/lms/premium-transactions/get_payment_collection';

export const AGREEMENT_ACCEPATANCE_ENDPOINT = '/api/lms/courses/accept_posp_agreement/';
export const GENERATE_AGREEMENT_ENDPOINT = '/api/lms/courses/generate_posp_agreement/';
export const GENERATE_CERTIFICATE_ENDPOINT = '/api/lms/courses/generate_posp_certificate/';
export const POSP_CERTIFICATION_COURSE_ENDPOINT = '/api/lms/courses/posp_certificate_course/';
export const USER_AGREEMENT_STATUS = '/api/lms/courses/user_agreement_status/';

export const PAGE_COHORT = '/api/lms/cohort/'

export const SKILL_BASED_COURSE_RECOMMENDATION_ENDPOINT = '/api/lms/courses/skill_based_course_recommendations/';
