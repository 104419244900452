const formatUtcDate = (inputDate) => {
  const utcdate = new Date(inputDate);

  return (
    [
      utcdate.getFullYear(),
      padTo2Digits(utcdate.getMonth() + 1),
      padTo2Digits(utcdate.getDate()),
    ].join("-") +
    "T" +
    [
      padTo2Digits(utcdate.getHours()),
      padTo2Digits(utcdate.getMinutes()),
      padTo2Digits(utcdate.getSeconds()),
    ].join(":")
  );
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export default formatUtcDate;
