import styled from "styled-components";

export const MainPopUpContainer = styled.div`
  position: fixed;
  z-index: 2;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);

  .modal-content {
    background: ${props => props.theme.COLOR.WHITE};
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 22px;
    width: 50%;
    text-align: center;
    overflow: hidden;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 995px) {
      width: 90%;
      padding: 26px;
    }
  }

  .close-cta {
    width: 15%;
    margin: 22px 0 0;
    background: ${(props) => props.theme.COLOR.BLACK};
    padding: 12px;
    color: ${props => props.theme.COLOR.WHITE};
    border: none;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;

    @media screen and (max-width: 995px) {
      width: 25%;
    }
  }
`;
