import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const AdminPospCourses = React.lazy(() =>
  import("pages/AdminControls/PospCertificationCourse")
);
const ReelsListing = React.lazy(() =>
  import("pages/AdminControls/TurtleReels/ReelsListing")
);
const CreateTurtleReelsPage = React.lazy(() =>
  import("pages/AdminControls/TurtleReels/CreateReels")
);
const CreateQuizPage = React.lazy(() =>
  import("pages/AdminControls/Quiz/CreateQuiz")
);
const QuizListingPage = React.lazy(() =>
  import("pages/AdminControls/Quiz/QuizListing")
);
const CreateQuestionPage = React.lazy(() =>
  import("pages/AdminControls/Quiz/CreateQuestion")
);
const QuestionListingPage = React.lazy(() =>
  import("pages/AdminControls/Quiz/QuestionListing")
);

const CategoryList = React.lazy(() =>
  import("pages/AdminControls/PrimaryCategory/CategoryList")
);

const CreateCategory = React.lazy(() =>
  import("pages/AdminControls/CreateCategoryForm")
);

const PartnerStoriesList = React.lazy(() =>
  import("pages/AdminControls/PartnerStories/PartnerStoriesList")
);

const CreatePartnerStories = React.lazy(() =>
  import("pages/AdminControls/PartnerStories/CreatePartnerStories")
);

const CreateForcedListPage = React.lazy(() =>
  import("pages/AdminControls/ForcedList/CreateForcedList")
);

const ForcedListPage = React.lazy(() =>
  import("pages/AdminControls/ForcedList")
);
const BannerListing = React.lazy(() =>
  import("pages/AdminControls/BannerCard/BannerListing")
);
const CreateBanner = React.lazy(() =>
  import("pages/AdminControls/BannerCard/CreateBanner")
);
const CreateActionItem = React.lazy(() =>
  import("pages/AdminControls/ActionItems/CreateActionItem")
);

const ActionItemsListing = React.lazy(() =>
  import("pages/AdminControls/ActionItems/ActionItemsListing")
);

const SkeletonSectionList = React.lazy(() =>
  import("pages/AdminControls/HomePageSkeleton/SkeletonSectionList")
);

const CreateSkeletonSection = React.lazy(() =>
  import("pages/AdminControls/HomePageSkeleton/CreateSkeletonSection")
);


export default [
  {
    path: "/branches/:id/posp-post-completion",
    Component: AdminPospCourses,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/reels/create",
    Component: CreateTurtleReelsPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/reels/:turtleReelsKey/edit",
    Component: CreateTurtleReelsPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/reels-listing",
    Component: ReelsListing,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/quiz/create",
    Component: CreateQuizPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/quiz/:quizKey/edit",
    Component: CreateQuizPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/quiz-listing",
    Component: QuizListingPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/quiz/question/create",
    Component: CreateQuestionPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/quiz/question/:questionKey/edit",
    Component: CreateQuestionPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/quiz/question-listing",
    Component: QuestionListingPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/admin-category-list",
    Component: CategoryList,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/create-category",
    Component: CreateCategory,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/category/:categoryId/edit",
    Component: CreateCategory,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/admin-stories-list",
    Component: PartnerStoriesList,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
    ],
  },
  {
    path: "/branches/:id/create-stories",
    Component: CreatePartnerStories,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
    ],
  },
  {
    path: "/branches/:id/stories/:storyId/edit",
    Component: CreatePartnerStories,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
    ],
  },
  {
    path: "/branches/:id/forced-list/create",
    Component: CreateForcedListPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/forced-list/:forcedListId/edit",
    Component: CreateForcedListPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/forced-list",
    Component: ForcedListPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/banner-listing",
    Component: BannerListing,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
      constants.ROLES["marketing"],
    ],
  },
  {
    path: "/branches/:id/create-banner",
    Component: CreateBanner,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
      constants.ROLES["marketing"],
    ],
  },
  {
    path: "/branches/:id/banner-card/:bannerCardId/edit",
    Component: CreateBanner,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
      constants.ROLES.content,
      constants.ROLES["l&d"],
      constants.ROLES["marketing"],
    ],
  },
  {
    path: "/branches/:id/create-action-item/",
    Component: CreateActionItem,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/action-item-list/",
    Component: ActionItemsListing,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/action-item/:actionItemId/edit",
    Component: CreateActionItem,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/create-homepage-skeleton-section",
    Component: CreateSkeletonSection,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/homepage-section-list",
    Component: SkeletonSectionList,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
  {
    path: "/branches/:id/skeleton-section/:sectionId/edit",
    Component: CreateSkeletonSection,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.tech,
      constants.ROLES.branch_admin,
    ],
  },
];
