import { REACT_APP_LOCKED_COURSE_THUMB } from "global";

export const LockedCourses = [{
  name: "GI POSP Certification Course",
  key:"",
  category: "compliance",
  image: "2020_08_13_VNlVs_MintAcademy Course Banner 1.png",
  image_url: REACT_APP_LOCKED_COURSE_THUMB,
  course_type: "Compliance Course",
  lockCourse: true
}];

export const CompleteProfileMessage = "Please complete your profile on TurtlemintPro to start these certfication courses";

export const ExamCompletedMessage = "You have already cleared the exam. Please download the certificate from TurtlemintPro account certification page. If you have any issue to download the certificate please write an email to";
