export const SET_SELECTED_BRANCH_KEY = 'BRANCH/SET_SELECTED_BRANCH_KEY';

export const APPEND_CERTIFICATION_COURSES = 'BRANCH/APPEND_CERTIFICATION_COURSES';
export const APPEND_ENGG_ONBOARDING_COURSES = 'BRANCH/APPEND_ENGG_ONBOARDING_COURSES';
export const APPEND_PODCAST_COURSES = 'BRANCH/APPEND_PODCAST_COURSES';
export const APPEND_CLAIM_DIARY_COURSES = 'BRANCH/APPEND_CLAIM_DIARY_COURSES';
export const APPEND_MASTERCLASS_COURSES = 'BRANCH/APPEND_MASTERCLASS_COURSES';
export const APPEND_TAG_2021_HIGHLIGHT_COURSES = 'BRANCH/APPEND_TAG_2021_HIGHLIGHT_COURSES';
export const APPEND_MOTIVATIONAL_TALK_COURSES = 'BRANCH/APPEND_MOTIVATIONAL_TALK_COURSES';
export const APPEND_PERSONAL_DEVELOPMENT_COURSES = 'BRANCH/APPEND_PERSONAL_DEVELOPMENT_COURSES';
export const APPEND_HOW_TO_COURSES = 'BRANCH/APPEND_HOW_TO_COURSES';
export const APPEND_HEALTH_PRODUCT_TRAINING_COURSES = 'BRANCH/APPEND_HEALTH_PRODUCT_TRAINING_COURSES';
export const APPEND_LIFE_PRODUCT_TRAINING_COURSES = 'BRANCH/APPEND_LIFE_PRODUCT_TRAINING_COURSES';
export const APPEND_APPLICATION_COURSES = 'BRANCH/APPEND_APPLICATION_COURSES';
export const APPEND_PAID_COURSES = 'BRANCH/APPEND_PAID_COURSES';
export const APPEND_QA_COURSES = 'BRANCH/APPEND_QA_COURSES';

export const SET_CHANNEL_DATA = 'CHANNEL/SET_CHANNEL_DATA';
export const SET_CHANNEL_CATEGORIES = 'CHANNEL/SET_CHANNEL_CATEGORIES';
export const SET_SELECTED_CHANNEL = 'CHANNEL/SET_SELECTED_CHANNEL';
