import React from "react";

import { fetchGet } from "utils/ajax-util";

import { ACTION_ITEMS } from "constants/endpoints";
import { getDeviceDetail } from "utils";
import { ACTION_SECTION_ITEM_CLICKED, ACTIONABLE_EXPAND_CLICKED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

const Container = (Main) =>
  class ExploreAcademyContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        actionItemList: [],
        showAction: false,
        isActionItemLoading: false,
      };
    }


    _fetchActionItems = () => {
      const {
        loggedProfileReducer: { authKey, branch, currentBranchName },
        match,
      } = this.props;
  
      if (!authKey || !branch.name) return;

      this.setState({
        isActionItemLoading: true,
      });

      const branchKey = currentBranchName
      ? branch.name === currentBranchName
        ? branch.key
        : match.params.id
      : branch.key;

      fetchGet(`${ACTION_ITEMS}?branch_key=${branchKey}&device=web`, authKey)
        .then((data) => {
          const { success, action_items_list } = data;

          if (success) {
            this.setState({
              isActionItemLoading: false,
              actionItemList: action_items_list ? action_items_list : [],
            });
          } else {
            this.setState({
              isActionItemLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isActionItemLoading: false,
          });
          console.log(error);
        });
    };

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    handleExpand = () => {
      this.setState((prevState) => {
        this.callMixpanelEvent(ACTIONABLE_EXPAND_CLICKED, {
          action: !prevState.showAction ? 'expand' : 'collapse'
        });
        return {
          showAction: !!!prevState.showAction,
        };
      });
    };

    handleRedirection = (itemDetails) => {
      const { name, sequence, is_live, is_new, is_resume } = itemDetails;
      this.callMixpanelEvent(ACTION_SECTION_ITEM_CLICKED, {
         actionable_name: name,
         actionable_sequence: sequence,
         actionable_tag: {
            live: is_live,
            new: is_new,
            resume: is_resume
         }
      });
      this.props.history.push(itemDetails.redirection_url);
    };

    componentDidMount() {
      this._fetchActionItems();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          handleExpand={this.handleExpand}
          handleRedirection={this.handleRedirection}
        />
      );
    }
  };

export default Container;
