import { cloneDeep } from "lodash";

import { ROUTER_CONFIG } from "./routes";

/**
 * @description - Component to inject authenticated routes from ROUTER_CONFIG
 * @function PrivateRoutes
 * @param {Object} loggedProfileReducer - logged in user's details.
 */
function AuthorizedRoutes(props) {
  const packageName = props.loggedProfileReducer && props.loggedProfileReducer.user_type;
  if (!packageName) return;

  //Deep cloning router config so that array/object manupulation would not persist on different logins.
  const routerConfig = cloneDeep(ROUTER_CONFIG);

  const filterRoutes = routerConfig[packageName].filter(({ permission }) => {
    if (Array.isArray(permission)) {
      return permission.includes(packageName);
    } else {
      return false;
    }
  });
  return filterRoutes;
}

export default AuthorizedRoutes;
