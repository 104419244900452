import { css } from "styled-components";

const theme = {
  COLOR: {
    WHITE: "#fff",
    BLACK: "#000",
    TEXT_BLACK: "#191C1B",
    LIGHT_GREY: "#D3D3D3",
    MEDIUM_GREY: "#B8BAB9",
    DARK_GREY: "#757875",
    GRID_SEPERATOR_GREY: "#D2D5D6",
    DARK_PINK: "#BC006E",
    MAGENTA: "#D43FA8",
    PARTNER_STORY_GREY: "#444845",
    MEDIUM_BLACK: "#3C3A3A",
    LIGHTEST_GREY_BORDER: "#F0F0F0",
    BORDER_GREY: "#ccc",
    CTA_BG_BLACK: "#303733",
    LINK_BLUE: "#0000ee",
    GREEN_PRIMARY: "#00a465",
    GREEN_SECONDARY: '#007D64',
    SLIDER_DOTS_ACTIVE_GREY: "#6C7375",
    SLIDER_DOTS_GREY: "#D9D9D9",
    REEL_TITLE_BG: "rgba(83, 77, 77, 0.6)",
    BLUR_GREY: "#fefefe",
    TEXT_GREY: "#757875",
    PRICE_GREEN: "#009F69",
    FORM_DATE_BORDER: "#bdbdbd",
    FORM_DATE_BORDER_FOCUS: "#3889d9",
    MANDATORY_STAR: "#f00",
    RED: "#ff0000",
    RED_PULSE: "#ffcbcb",
    FILTER_PILL_BORDER: "#A9ACA9",
    YELLOW_SECONDARY: "#FFF7E5",
    MODAL_BUTTON_COLOR: "#508c6e",
    BLACK_OPAQUE: "rgba(0, 0, 0, 0.4)",
    CLOSE_GREEN: "#ff0404",
    PLAIN_GREY: "#888",
    FAILURE_TEXT: "#c1043d",
    ACTIVE_BACKGROUND: "#007D64",
    LIGHTEST_GREY: "#f9f5f5",
    LP_MODAL_BG: "#D3E5FC",
    FIGURE_BACKGROUND: "rgba(0, 0, 0, 0.6)",
    FLASHCARD_TILE_BG: "linear-gradient(360deg, #534D4D 18.45%, rgba(83, 77, 77, 0) 99.27%);",
    SHADOW_GREY: "#E1E3E0",
    DISABLED_GREY: "#A9ACA9",
    WEBINAR_CATEGORY_COLOR: "rgb(219 211 255)",
    REQUIRED_STAR_RED: "#f00",
    COSMIC_LATTE: "#E2FAEF",
    DARK_PURPLE: "#643BA6"
  },
  COLOR_PALETTE: {
    PINK_PALETTE: {
      PRIMARY: "#FFEEF7",
      SECONDARY: "#F2CDE1",
      TERTIARY: "#CB3F89",
      GRADIENT:
        "linear-gradient(354deg, #CB3F89 -161.95%, #FFF 60.82%, #FFF 60.85%)",
    },
    PURPLE_PALETTE: {
      PRIMARY: "#F6F4FE",
      SECONDARY: "#E1DFFC",
      TERTIARY: "#8D7BF6",
      GRADIENT:
        "linear-gradient(354deg, #8D7BF6 -161.95%, #FFF 60.82%, #FFF 60.85%)",
    },
    BLUE_PALETTE: {
      PRIMARY: "#EFF7FE",
      SECONDARY: "#D3E5FC",
      TERTIARY: "#69A9F5",
      GRADIENT:
        "linear-gradient(355deg, #71C1F1 -114.57%, #FFF 70.74%, #FFF 70.76%)",
    },
    GREEN_PALETTE: {
      PRIMARY: "#EEF9E3",
      SECONDARY: "#DCEDCB",
      TERTIARY: "#91C571",
      GRADIENT:
        "linear-gradient(354deg, #91C571 -161.95%, #FFF 60.82%, #FFF 60.85%)",
    },
    ORANGE_PALETTE: {
      PRIMARY: "#FCEDE7",
      SECONDARY: "#F7D6CD",
      TERTIARY: "#E96B53",
      GRADIENT:
        "linear-gradient(358deg, #FFE0DA 1.62%, #FFF 104.95%, #FFF 104.96%)",
    },
  },
  SIZE: {
    MAIN: "14px",
    SECONDARY: "14px",
  },
  SNIPPETS: {
    BOX_SHADOW: css`
      box-shadow: 4px 8px 15px 0 rgba(0, 0, 0, 0.14);
    `,
  },
};

export default function configureTheme() {
  return theme;
}
