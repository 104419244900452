import { fetchGet } from "./ajax-util";
import { RECOMMENDED_COURSES_ENDPOINT, RECOMMENDED_ITEMS_ENDPOINT } from "../constants/endpoints";

export function fetchRecommended(fetchPlayNext = false) {
    this.setState({ loadingRecommendations: true });
    let cached_courses = JSON.parse(sessionStorage.getItem("recommendedCourses"));
    if (cached_courses) {
        this.setRecommended(cached_courses, true);
        return;
    }
    fetchGet(`${RECOMMENDED_COURSES_ENDPOINT}?&external_id=${this.props.cookies.get("partner_id")}`, this.props.fetchKey())
        .then(response => {
            if (response.success) {
                this.setRecommended(response);
                sessionStorage.setItem("recommendedCourses", JSON.stringify(response.data.courses));
            }
            else {
                this.setState({loadingRecommendations: false});
                fetchPlayNext && this.removeURLParameter("playNext")
            }
        })
        .catch(err => {
            this.setState({loadingRecommendations: false});
            fetchPlayNext && this.removeURLParameter("playNext")
        })
    return;
}


export const getRecommendedKeys = () => {
    if(JSON.parse(sessionStorage.getItem("recommendedCourses"))){
        let keys = JSON.parse(sessionStorage.getItem("recommendedCourses")).map(course => course.key);
        return keys;
    }
    return null;
  }

  export const getRecommendedItems = () => {
    const recommended = JSON.parse(sessionStorage.getItem("playNextRecommended")) || [];
    return recommended.map(item => item.key);
}

export const fetchStaticRecommendations = (queryParams, authKey, playNext = false) => {
    const queryString = new URLSearchParams(queryParams).toString()
    return fetchGet(`${RECOMMENDED_ITEMS_ENDPOINT}?${queryString}`, authKey);
  }
