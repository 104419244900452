import React from "react";

import "./style/style.css";

const Loader = props => {
  return (
    <div className="full-paage-backdrop">
      <div className="full-page-loader-container">
        <div className="custom-loader"></div>
        <span>{props.text ? props.text : "Loading..."}</span>
      </div>
    </div>
  );
};

export default Loader;
