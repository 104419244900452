import React from "react";
import { MdModeEdit } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CardSkeleton from "pages/NewHomePage/components/CardSkeleton";

import { NextIcon } from "pages/NewHomePage/images";
import { IndependentContentContainer } from "./styles/style.js";
import Container from "./container";

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 140,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 40,
      },
    },
  ],
};

const ConditionalParent = ({ isMobile, children }) => {
  return isMobile ? (
    <Slider {...settings}>{children}</Slider>
  ) : (
    <div className="grid-container-h3">{children}</div>
  );
};

const GyanByTM = (props) => {
  const {
    isContentLoading,
    independentContentData,
    loggedProfileReducer: { isCourseAdminRole },
    isMobile,
    title,
    handleEditClick,
    onContentClick,
    handleViewMoreClick,
    has_more,
  } = props;

  return isContentLoading ? (
    <div className="site-wrapper">
      <CardSkeleton isMobile={isMobile} />
    </div>
  ) : !isContentLoading &&
    Array.isArray(independentContentData) &&
    independentContentData.length ? (
    <IndependentContentContainer>
      <div className="site-wrapper">
        <div className="heading-container">
          <h3 className="course-section-heading">
            {title ? title : "Gyan By Turtlemint"}
          </h3>
          {has_more ? (
            <button title="View More Content" onClick={handleViewMoreClick}>
              <span className="view-more-text">View More</span>
              <span className="icon-container">
                <NextIcon />
              </span>
            </button>
          ) : null}
        </div>
        <ConditionalParent isMobile={isMobile}>
          {independentContentData.map((item, index) => {
            const { key, image_url, name } = item;
            return (
              <div
                className="grid-h3"
                onClick={(e) => onContentClick(item)}
                key={key}
              >
                {isCourseAdminRole ? (
                  <span
                    className="edit-icon-container"
                    title="Edit"
                    onClick={(e) => handleEditClick(e, key)}
                  >
                    <MdModeEdit />
                  </span>
                ) : null}
                <figure>
                  <img
                    src={
                      image_url
                        ? image_url
                        : "https://d3gthpli891tsj.cloudfront.net/wp-content/uploads/2019/01/22063215/GATE-Crash-Course.jpg"
                    }
                    alt="Content Thumbnail"
                  />
                </figure>
                <div className="course-info-section">
                  <h4 className="content-heading">{name}</h4>
                </div>
              </div>
            );
          })}
        </ConditionalParent>
      </div>
    </IndependentContentContainer>
  ) : null;
};

export default Container(GyanByTM);
