import{ REACT_APP_API_BASE_URL } from 'global';

const getDefaultHeaders = (token) => {
    return {
        "Content-Type": "application/json",
        "X-PG-User-ID": token
    };
}

export const fetchGet = (endpoint, token) => {

    return fetch(REACT_APP_API_BASE_URL + endpoint, {
        method: 'GET',
        headers: getDefaultHeaders(token),
    })
        .then((response) => response.json())
}

export const fetchPost = (endpoint, token, body) => {

    // TODO: get token from cookies, do not pass as method param here

    return fetch(REACT_APP_API_BASE_URL + endpoint, {
        method: 'POST',
        headers: getDefaultHeaders(token),
        body: JSON.stringify(body)
    })
        .then((response) => response.json());

}

export const fetchPut = (endpoint, token, body) => {

    return fetch(REACT_APP_API_BASE_URL + endpoint, {
        method: 'PUT',
        headers: getDefaultHeaders(token),
        body: JSON.stringify(body)
    })
        .then((response) => response.json());

}

export const fetchDelete = (endpoint, token, body) => {

    return fetch(REACT_APP_API_BASE_URL + endpoint, {
        method: 'DELETE',
        headers: getDefaultHeaders(token),
        body: JSON.stringify(body)
    })
        .then((response) => response.json());

}
