import React from "react";
import { fetchGet } from "utils/ajax-util";
import { BANNER_CARD } from "constants/endpoints";
import { getDeviceDetail } from "utils";
import { BANNER_CLICKED, BANNER_INTERACTION } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

let prevIndex = 0;
let nextIndex = 0;

const Container = (Main) =>
  class BannerCardContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isBannerCardFetching: false,
        bannerCard: [],
      };
      this.arrowSliderRef = React.createRef();
    }

    _fetchBanner = () => {
      const {
        loggedProfileReducer: { authKey, branch, currentBranchName, user_cohort },
        match,
      } = this.props;

      if (!authKey || !branch.name) return;

      this.setState({
        isBannerCardFetching: true,
      });

      const branchKey = currentBranchName
        ? branch.name === currentBranchName
          ? branch.key
          : match.params.id
        : branch.key;

      const branchName = currentBranchName
        ? branch.name === currentBranchName
          ? branch.name
          : currentBranchName
        : branch.name;

      fetchGet(
        `${BANNER_CARD}?branch_key=${branchKey}&device=web${
          branchName === "MintPro" ? `&cohort=${user_cohort}` : ""
        }`,
        authKey
      )
        .then((data) => {
          const { success, banner_card_list } = data;

          if (success) {
            this.setState({
              isBannerCardFetching: false,
              bannerCard: banner_card_list ? banner_card_list : [],
            });
          } else {
            this.setState({
              isBannerCardFetching: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isBannerCardFetching: false,
          });
          console.log(error);
        });
    };

    _bannerClickHandler = (bannerData) => {
      const { sequence, redirection_url, name } = bannerData;
      this.callMixpanelEvent(BANNER_CLICKED, {
        banner_sequence: sequence,
        banner_deeplink: redirection_url,
        banner_name: name,
      });
    };

    _arrowClickHandler = (typeOfClick, currentSlide, arrowSliderRef) => {
      this.callMixpanelEvent(BANNER_INTERACTION, {
        interaction_type: typeOfClick,
        current_banner_sequence: currentSlide,
      });
      if (typeOfClick === "right") arrowSliderRef.current.slickNext();
      else arrowSliderRef.current.slickPrev();
    };

    slideSwipeHandler = (oldIndex, newIndex) => {
      prevIndex = oldIndex;
      nextIndex = newIndex;
    };

    onTouchEnd = () => {
      this.callMixpanelEvent(BANNER_INTERACTION, {
        interaction_type:
          prevIndex > nextIndex && nextIndex !== 0 ? "right" : "left",
        current_banner_sequence: nextIndex,
      });
    };

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    componentDidMount() {
      this._fetchBanner();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          bannerClickHandler={this._bannerClickHandler}
          arrowClickHandler={this._arrowClickHandler}
          arrowSliderRef={this.arrowSliderRef}
          slideSwipeHandler={this.slideSwipeHandler}
          onTouchEnd={this.onTouchEnd}
        />
      );
    }
  };

export default Container;
