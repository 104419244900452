import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CardSkeleton from "pages/NewHomePage/components/CardSkeleton";
import ExamPopUp from "./components/ExamPopUp";
import PospCourseTile from "./components/PospCourseTile";

import Container from "./container";
import { MainDivContainer } from "./styles/style";

import { PrevIcon, NextIcon } from "pages/NewHomePage/images";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);

const ConditionalParent = ({
  isMobile,
  courseDataLength,
  settings,
  slideCtaClickHandler,
  children,
}) => {
  return courseDataLength > 3 || (isMobile && courseDataLength > 1) ? (
    <Slider {...settings} afterChange={(e) => slideCtaClickHandler(e)}>
      {children}
    </Slider>
  ) : (
    <div className={isMobile ? "" : "grid-container-h3"}>{children}</div>
  );
};

const PospCertification = (props) => {
  const {
    isPospLoading,
    courseData,
    loggedProfileReducer,
    history,
    match,
    title,
    slideCtaClickHandler,
    currentIndex,
    setExamModalAndMessage,
    examModal,
    examModalMessage,
    closeClickHandler,
    isMobile,
  } = props;

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    cursor: PointerEvent,
    nextArrow: (
      <SlickButtonFix>
        <NextIcon />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <PrevIcon />
      </SlickButtonFix>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          rows: 1,
          slidesToShow: 1.6,
        },
      },
      {
        breakpoint: 540,
        settings: {
          arrows: false,
          rows: 1,
          slidesToShow: 1.2,
        },
      },
    ],
  };

  return isPospLoading ? (
    <div className="site-wrapper">
      <CardSkeleton isMobile={isMobile} />
    </div>
  ) : courseData && courseData.length ? (
    <MainDivContainer
      pospCourseLength={courseData && courseData.length}
      currentIndex={currentIndex}
      isMobile={isMobile}
    >
      <div className="heading-container site-wrapper">
        <h3 className="course-section-heading">{title}</h3>
      </div>
      <div className="purchased-div-container site-wrapper">
        <ConditionalParent
          isMobile={isMobile}
          courseDataLength={courseData.length}
          settings={settings}
          slideCtaClickHandler={slideCtaClickHandler}
        >
          {courseData.map((item, index) => {
            return (
              <PospCourseTile
                {...props}
                courseInfo={item}
                colorSchema={"GREEN_PALETTE"}
                loggedProfileReducer={loggedProfileReducer}
                key={item.key}
                history={history}
                match={match}
                content_source="homepage"
                course_sequence={index + 1}
                setExamModalAndMessage={setExamModalAndMessage}
                lockCourse={item.lockCourse}
              />
            );
          })}
        </ConditionalParent>
      </div>
      {examModal ? (
        <ExamPopUp
          examModalMessage={examModalMessage}
          clickHandler={closeClickHandler}
        />
      ) : null}
    </MainDivContainer>
  ) : null;
};

export default Container(PospCertification);
