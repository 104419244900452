/*  eslint-disable */
import React, { Component, lazy, Suspense } from "react";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import theme from "./styles/theme";
import styles from "./styles/style";
import "styles/fonts.css";
import "styles/default.css";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Header from "./components/header";
import TopHeader from "components/TopHeader/index";
import { CookiesProvider } from "react-cookie";
import { withCookies } from "react-cookie";
import "react-dates/initialize";
import "./styles.css";
import CacheBuster from "./CacheBuster";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import * as BranchActions from "./pages/branch/branch.actions";
import Loader from "./components/loader";
import { Mixpanel } from "./Mixpanel";
import { fetchGet } from "utils/ajax-util";
import { BRANCHES_ENDPOINT_V2 } from "constants/endpoints";
import TransitionPageLoader from "components/transitionPageLoader";

import AuthorizedRoutes from "Router/authorizedRoutes";
import PageNotFound from "pages/PageNotFound";

const tabsList = ["Courses", "Users"];

const courseTabList = ["Modules", "Questions", "Answers"];

import { ROUTER_CONFIG } from "Router/routes";

import { loggedProfile } from "store/actions/loggedProfile";

import { SiteWrapper } from "styles/default"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      branches: [],
      firsTime: true,
      heading: "",
      tabs: false,
      selectedTab: 0,
      branchName: "",
      tabsList: tabsList,
      breadcrumbsIndex: 0,
      admin: false,
      contents: [],
      current_content: 0,
      all_contents: [],
      courses: [],
      branchInfo: null,
      browseWebinarButton: false,
      searchBarBox: false,
      qa_url: null,
      pageCount: 0,
      flashcard: {},
      courseTimer: true,
      assessmentFlow: false,
      enableSharing: false,
    };
  }

  setAssessmentFlow = (value) => {
    this.setState({ assessmentFlow: value });
  };

  setPageCount = (count) => {
    this.setState({ pageCount: count });
  };

  setCourse = (branch, course, qa = false) => {
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    this.setState({ qa });
  };

  setAllContents = (contents) => {
    this.setState({ all_contents: contents });
  };

  setRatingsData = (
    show_rating,
    total_ratings,
    avg_rating,
    total_subscribers
  ) => {
    this.setState({
      show_rating,
      total_ratings,
      avg_rating,
      total_subscribers,
    });
  };

  resetRatingsData = () => {
    this.setState({
      show_rating: false,
    });
  };

  nextContent = () => {
    try {
      let current_content = this.state.current_content + 1;
      let content = this.state.all_contents[current_content];
      if (content.type === "flashcard") {
        // hack for apester component load
        window.location = window.location.href.replace(
          `content/${this.state.all_contents[current_content - 1].key}`,
          `content/${content.key}`
        );
      } else {
        this.openContent(
          this.state.branch,
          this.state.course,
          content.module_id,
          content.key
        );
      }
      this.setState({ current_content });
    } catch (e) {
      console.log(e);
    }
  };

  fetchBranches = (key) => {
    const authkey = this.fetchKey();
    if (this.fetchRole() !== "admin") {
      return;
    }
    fetchGet(`${BRANCHES_ENDPOINT_V2}`, authkey).then((data) => {
      if (data.success) {
        data = data.data;
        if (data.length === 0) {
          this.props.history.push("/branches/create");
        }
        data.map((item, index) => {
          if (key && item.key === key) {
            this.changeBranch(key);
          }
          if (item.key == this.props.currentBranch) {
            this.setState({ branchName: item.name });
          }
        });

        this.setState({ branches: data });
      }
    });
  };

  setBackButton = (backButton) => {
    this.setState({ backButton });
  };

  setBrowseWebinarButton = (browseWebinarButton) => {
    this.setState({ browseWebinarButton });
  };

  setSearchBarBox = (searchBarBox) => {
    this.setState({ searchBarBox });
  };

  fetchUserKey = () => {
    return this.props.cookies.get("user_key");
  };

  fetchRole = () => {
    const { loggedProfileReducer } = this.props;

    if (!loggedProfileReducer) return;

    return loggedProfileReducer.isAdminRole ? "admin" : "user";
  };

  fetchKey = () => {
    return this.props.cookies.get("key");
  };

  setBranchPage = (isBranchPage, branch) => {
    if (branch) {
      this.setState({ branch });
    }
    this.setState({ isBranchPage: isBranchPage });
  };

  setBranchName = (branchName) => {
    this.setState({ branchName });
  };

  setCurrentBranch = (currentBranch) => {
    this.setState({ currentBranch });
  };

  changePath = (path) => {
    this.props.history.push(path);
  };

  createExam = (branch, course, module) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/createexam`
    );
  };

  editExam = (branch, course, module, exam) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/exam/${exam}/edit`
    );
  };

  createContent = (branch, course, module) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/createcontent`
    );
  };

  editContent = (branch, course, module, content) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/editcontent/${content}`
    );
  };

  editIndependentContent = (branch, content, channelKey) => {
    if (channelKey) {
      this.changePath(
        `/branches/${branch}/channels/${channelKey}/editcontent/${content}`
      );
    } else {
      this.changePath(`/branches/${branch}/editcontent/${content}`);
    }
  };

  createWebinar = (branch) => {
    this.changePath(`/webinars/create`);
  };

  createFlashcard = (branch, channel) => {
    if (!channel) {
      this.changePath(`/flashcards/create`);
    } else {
      this.changePath(
        `/channels/${channel}/flashcards/create`
      );
    }
    if (branch) {
      this.setState({ branch });
    }
  };

  editFlashcard = (flashcardKey, branch, channel) => {
    if (!channel) {
      this.changePath(`/flashcards/${flashcardKey}/edit`);
    } else {
      this.changePath(
        `/channels/${channel}/flashcards/${flashcardKey}/edit`
      );
    }
    if (flashcardKey) {
      this.setState({ flashcardKey });
    }
    if (branch) {
      this.setState({ branch });
    }
  };
  

  editWebinar = (webinar) => {
    this.changePath(`/webinars/${webinar}/edit`);
    if (webinar) {
      this.setState({ webinar });
    }
  };

  webinarDetail = (webinarKey) => {
    this.changePath(`/webinars/${webinarKey}`);
  };

  createBranch = () => {
    this.changePath("/branches/create");
  };

  editBranch = (id) => {
    this.changePath(`/branches/edit/${id}`);
  };

  changeBranch = (id, reload, refetch, logo_url, updateLogoUrl, name = "") => {
    if (refetch) {
      this.fetchBranches(id);
    } else if (id) {
      this.setState({ branch: id, });
      this.setUserBranch(id, name);
      this.changePath(`/branches/${id}`);
    } else if (this.state.branch) {
      this.changePath(`/branches/${this.state.branch}`);
    } else {
      this.changePath("/");
    }

    if (updateLogoUrl) {
      this.props.loggedProfile({
        ...this.props.loggedProfileReducer,
        logo_url: logo_url,
        currentBranchName: name,
        currentBranchKey: id,
      });
    }

    if (reload && !refetch) {
      window.location.reload();
    }
  };

  openCourse = (branch, course, completed, qa) => {
    if (completed) {
      this.setState({ completed });
    }
    qa
      ? this.changePath(`/branches/${branch}/courses/${course}/qa`)
      : this.changePath(`/branches/${branch}/courses/${course}`);
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    if (qa) {
      this.setState({ qa: true });
    } else {
      this.setState({ qa: false });
    }
  };

  openLearningPath = (branch, learningPath) => {
    this.props.history.push(`/branches/${branch}/learningpath/${learningPath}`);
  };

  openChannel = (channelKey) => {
    this.changePath(`/channels/${channelKey}`);
    if (channelKey) {
      this.setState({ channelKey });
    }
  };

  createModule = (branch, course) => {
    this.changePath(`/branches/${branch}/courses/${course}/modules/create`);
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
  };

  openModule = (branch, course, module) => {
    this.changePath(`/branches/${branch}/courses/${course}/modules/${module}`);
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    if (module) {
      this.setState({ module });
    }
  };

  editCourse = (branch, course, channelKey) => {
    if (channelKey) {
      this.changePath(
        `/branches/${branch}/channels/${channelKey}/courses/${course}/edit`
      );
    } else {
      this.changePath(`/branches/${branch}/courses/${course}/edit`);
    }
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
  };

  editModule = (branch, course, module) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/edit`
    );
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    if (module) {
      this.setState({ module });
    }
  };

  addMCQ = (branch, course, module, exam) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/exam/${exam}/addmcq`
    );
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    this.setState({ module, exam });
  };

  addUser = (branch) => {
    this.changePath(`/branches/${branch}/users/add`);
    if (branch) {
      this.setState({ branch });
    }
  };

  assignUser = (branch, user) => {
    this.changePath(`/branches/${branch}/users/${user}/assign`);
    if (branch) {
      this.setState({ branch });
    }
    if (user) {
      this.setState({ user });
    }
  };

  openExam = (branch, course, module, exam, section) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/modules/${module}/exam/${exam}/section/${section}`
    );
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    this.setState({ module, exam });
  };

  openContent = (branch, course, module, content, fullscreen) => {
    this.state.all_contents.map((item, i) => {
      if (item.key === content) {
        this.setState({ current_content: i });
      }
    });
    if (fullscreen) {
      this.changePath(
        `/branches/${branch}/courses/${course}/modules/${module}/content/${content}/fullscreen`
      );
    } else {
      this.changePath(
        `/branches/${branch}/courses/${course}/modules/${module}/content/${content}`
      );
    }
    if (branch) {
      this.setState({ branch });
    }
    if (course) {
      this.setState({ course });
    }
    this.setState({ module, content });
  };

  openUsers = (branch) => {
    this.changePath(`/branches/${branch}/users`);
    if (branch) {
      this.setState({ branch });
    }
  };

  editUser = (branch, user) => {
    this.changePath(`/branches/${branch}/users/${user}/edit`);
    if (branch) {
      this.setState({ branch });
    }
    this.setState({ user });
  };

  setEnableSharing = (flag) => {
    this.setState({ enableSharing: flag });
  };

  openEditQuestion = (branch, course, question) => {
    this.changePath(
      `/branches/${branch}/courses/${course}/qa/${question}/edit`
    );
    this.setState({ branch, course, question });
  };

  setBreadCrumbsIndex = (breadcrumbsIndex) => {
    this.setState({ breadcrumbsIndex });
  };

  changeUserName = (user_name) => {
    this.setState({ user_name });
  };

  componentDidMount = () => {
    if (window.location.href.includes("login")) {
      this.hideHeaders(true);
    } else {
      if (this.props.cookies.get("partner_id")) {
        Mixpanel.identify({ partnerId: this.props.cookies.get("partner_id") });
      }
    }
    if (this.fetchRole() === "admin") {
      this.fetchBranches();
    } else {
      this.fetchKey() &&
        localStorage.getItem("branchInfo") &&
        this.setBranchInfo(JSON.parse(localStorage.getItem("branchInfo")));
    }
    this.setState({
      classes: this.props.classes,
      user_name: this.props.cookies.get("user_name"),
    });
  };

  setHeading = (heading) => {
    this.setState({ heading });
  };

  changePage = (index) => {
    if (index === 1) {
      if (this.fetchRole() === "admin") {
        this.changeBranch(this.state.branch);
      } else {
        this.props.history.push("/");
      }
    } else if (index === 2) {
      this.openCourse(this.state.branch, this.state.course);
    } else if (index === 3) {
      this.openModule(this.state.branch, this.state.course, this.state.module);
    }
  };

  selectTab = (tab, setOnly) => {
    this.setState({ selectedTab: tab });
    if (setOnly) {
      this.setState({ selectedTab: tab });
    } else {
      if (this.state.tabsType === "branch" && tab === 1) {
        this.props.history.push(`/branches/${this.state.branch}/users`);
      }
      if (this.state.tabsType === "branch" && tab === 0) {
        this.props.history.push(`/branches/${this.state.branch}`);
      }
      if (this.state.tabsType === "courses" && tab === 0) {
        this.props.history.push(
          `/branches/${this.state.branch}/courses/${this.state.course}${
            this.state.qa ? "/qa" : ""
          }`
        );
      }
      if (this.state.tabsType === "courses" && tab === 1) {
        this.props.history.push(
          `/branches/${this.state.branch}/courses/${this.state.course}/qa/questions`
        );
      }
      if (this.state.tabsType === "courses" && tab === 2) {
        this.props.history.push(
          `/branches/${this.state.branch}/courses/${this.state.course}/qa/answers`
        );
      }
    }
  };

  setTabs = (tabs, type, select) => {
    let newTabsList = tabsList;
    if (type === "courses") {
      newTabsList = courseTabList;
    }
    if (type) {
      this.setState({ tabsType: type });
    }
    this.setState({ tabs, tabsList: newTabsList }, () => {
      if (select) {
        this.setState({ selectedTab: 0 });
      }
    });
  };

  setCourses = (courses) => {
    const updatedCourses = courses.map((course) => {
      return { id: course.id, value: course.name };
    });
    this.setState({ courses: updatedCourses });
  };

  setBackButtonCoursePage = (backButtonCoursePage) => {
    this.setState({ backButtonCoursePage });
  };

  login = () => {
    this.changePath("/login");
  };

  logout = () => {
    this.changePath("/logout");
  };

  setCourseName = (courseName) => {
    this.setState({ courseName });
  };

  goBack = () => {
    this.openCourse(this.state.branch, this.state.course);
  };

  openFeedback = (
    branch = this.state.branch,
    course = this.state.course,
    completed = false
  ) => {
    if (completed) {
      this.changePath(
        `/branches/${branch}/courses/${course}/feedback?completed=true`
      );
    } else {
      this.changePath(`/branches/${branch}/courses/${course}/feedback`);
    }
  };

  setQA = (qaPage) => {
    this.setState({ qaPage });
  };

  hideHeaders = (hideHeader) => {
    this.setState({ hideHeader });
  };

  resetComplete = () => {
    this.setState({ completed: false });
  };

  setSupportEmail = (branch_support_email) => {
    this.setState({ branch_support_email });
  };

  setBranchHomeUrl = (branch_home_url) => {
    this.setState({ branch_home_url });
  };

  setCourseTimer = (isCourseTimer) => {
    this.setState({ courseTimer: isCourseTimer });
  };

  fetchLogo = () => {
    if (this.fetchRole() !== "admin") {
      const key = this.fetchKey();
      fetchGet(`${BRANCHES_ENDPOINT_V2}get_user_branch/`, key)
        .then((data) => {
          if (data.success) {
            this.setBranchInfo(data);
            localStorage.setItem("branchInfo", JSON.stringify(data));
          }
        })
        .catch((_data) => {
          console.log("err", _data);
        });
    }
  };

  setBranchInfo = (data) => {
    this.setState({
      branchInfo: data.data,
      courseTimer: data.data.enable_course_timer,
    });
    if (data.data && data.data.home_url) {
      this.setBranchHomeUrl(data.data.home_url);
    } else {
      this.setBranchHomeUrl("/");
    }
    if (data.data) {
      this.setState({
        user_branch_key: data.data.key,
        user_branch_name: data.data.name,
        qa_url: data.data.qa_url,
      });
    }
    if (data.data && data.data.support_email) {
      this.setSupportEmail(data.data.support_email);
    } else {
      this.setSupportEmail("pospsupport@turtlemint.com");
    }
  };

  setLogo = (logo) => {
    localStorage.setItem("logo_url", logo);
  };

  setBackHomeButton = (backToHome) => {
    this.setState({ backToHome });
  };

  setQAModerator = (moderator) => {
    this.setState({ moderator });
  };

  setUserBranch = (user_branch_key, user_branch_name) => {
    this.setState({ user_branch_key, user_branch_name });
    this.props.dispatch(BranchActions.setSelectedBranchAction(user_branch_key));
  };

  Snackbar = (() => {
    const defaultDuration = 1500;
    return {
      error: (message, duration) => {
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: duration || defaultDuration,
        });
      },
    };
  })();

  filteredRoutes = () => {
    if (!this.props.loggedProfileReducer) return;
    if (this.state.routesFiltered) {
      return this.state.filteredRoutesArray;
    } else {
      this.setState({
        routesFiltered: true,
      });
      const filteredRoutesArray = AuthorizedRoutes(this.props);
      this.setState({
        filteredRoutesArray: filteredRoutesArray,
      });
      return filteredRoutesArray;
    }
  };
  render() {
    const { history, location, match, ...other } = { ...this.props };

    const rootState = { ...this.state };
    const handlers = {
      setAssessmentFlow: this.setAssessmentFlow,
      setPageCount: this.setPageCount,
      setCourse: this.setCourse,
      setAllContents: this.setAllContents,
      setRatingsData: this.setRatingsData,
      resetRatingsData: this.resetRatingsData,
      nextTextContent: this.nextContent,
      fetchBranches: this.fetchBranches,
      setBackButton: this.setBackButton,
      setBrowseWebinarButton: this.setBrowseWebinarButton,
      setSearchBarBox: this.setSearchBarBox,
      fetchUserKey: this.fetchUserKey,
      fetchRole: this.fetchRole,
      fetchKey: this.fetchKey,
      setBranchPage: this.setBranchPage,
      setBranchName: this.setBranchName,
      setCurrentBranch: this.setCurrentBranch,
      changePath: this.changePath,
      createExam: this.createExam,
      editExam: this.editExam,
      createContent: this.createContent,
      editContent: this.editContent,
      editIndependentContent: this.editIndependentContent,
      createWebinar: this.createWebinar,
      createFlashcard: this.createFlashcard,
      editFlashcard: this.editFlashcard,
      editWebinar: this.editWebinar,
      webinarDetail: this.webinarDetail,
      createBranch: this.createBranch,
      editBranch: this.editBranch,
      changeBranch: this.changeBranch,
      openCourse: this.openCourse,
      openLearningPath: this.openLearningPath,
      openChannel: this.openChannel,
      createModule: this.createModule,
      openModule: this.openModule,
      editCourse: this.editCourse,
      editModule: this.editModule,
      addMCQ: this.addMCQ,
      addUser: this.addUser,
      assignUser: this.assignUser,
      openExam: this.openExam,
      openContent: this.openContent,
      openUsers: this.openUsers,
      openUsers: this.openUsers,
      setEnableSharing: this.setEnableSharing,
      openEditQuestion: this.openEditQuestion,
      setBreadCrumbsIndex: this.setBreadCrumbsIndex,
      changeUserName: this.changeUserName,
      setHeading: this.setHeading,
      changePage: this.changePage,
      selectTab: this.selectTab,
      setTabs: this.setTabs,
      setCourses: this.setCourses,
      setBackButtonCoursePage: this.setBackButtonCoursePage,
      login: this.login,
      logout: this.logout,
      setCourseName: this.setCourseName,
      goBack: this.goBack,
      openFeedback: this.openFeedback,
      setQA: this.setQA,
      hideHeaders: this.hideHeaders,
      resetComplete: this.resetComplete,
      setSupportEmail: this.setSupportEmail,
      setBranchHomeUrl: this.setBranchHomeUrl,
      setCourseTimer: this.setCourseTimer,
      fetchLogo: this.fetchLogo,
      setBranchInfo: this.setLogo,
      setLogo: this.setLogo,
      fetchCourses: this.fetchCourses,
      setBackHomeButton: this.setBackHomeButton,
      setQAModerator: this.setQAModerator,
      setUserBranch: this.setUserBranch,
      Snackbar: this.Snackbar,
      editUser: this.editUser,
    };

    const authRoutes = this.props.loggedProfileReducer
      ? this.filteredRoutes()
      : null;
    const { loggedProfileReducer, history: browserHistory } = this.props;

    const currentUrl = window.location.href.split("&");

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            this.state.classes && (
              <CookiesProvider>
                <ThemeProvider theme={theme}>
                  <div>
                    <CssBaseline />
                    <div>
                      {Array.isArray(currentUrl) &&
                      currentUrl.length &&
                      currentUrl.length > 1 &&
                      currentUrl[1].includes("branch_name=MintPro") ? (
                        <TransitionPageLoader />
                      ) : this.props.location.pathname.includes(
                          "/turtle-reels"
                        ) ? null : (
                        <TopHeader
                          branch_home_url={this.state.branch_home_url}
                          user_name={this.state.user_name}
                          disable_breadcrumbs={this.state.disable_breadcrumbs}
                          fetchRole={this.fetchRole}
                          courses={this.state.courses}
                          logout={this.logout}
                          login={this.login}
                          fetchKey={this.fetchKey}
                          assessmentFlow={this.state.assessmentFlow}
                          history={this.props.history}
                          loggedProfileReducer={loggedProfileReducer}
                          searchBarBox={this.state.searchBarBox}
                          branchKey={this.state.branch}
                          user_branch_name={this.state.user_branch_name}
                        />
                      )}
                      {!this.state.admin && (
                        <Header
                          setPageCount={this.setPageCount}
                          pageCount={this.state.pageCount}
                          setBranchName={this.setBranchName}
                          browseWebinarButton={this.state.browseWebinarButton}
                          searchBarBox={this.state.searchBarBox}
                          backWebinarButton={this.state.backWebinarButton}
                          backToHome={this.state.backToHome}
                          moderator={this.state.moderator}
                          show_rating={this.state.show_rating}
                          avg_rating={this.state.avg_rating}
                          total_ratings={this.state.total_ratings}
                          total_subscribers={this.state.total_subscribers}
                          backButtonCoursePage={this.state.backButtonCoursePage}
                          qaPage={this.state.qaPage}
                          goBack={this.goBack}
                          backButton={this.state.backButton}
                          courseName={this.state.courseName}
                          disable_breadcrumbs={this.state.disable_breadcrumbs}
                          branchName={this.state.branchName}
                          fetchBranches={this.fetchBranches}
                          branches={this.state.branches}
                          createBranch={this.createBranch}
                          editBranch={this.editBranch}
                          isBranchPage={this.state.isBranchPage}
                          fetchRole={this.fetchRole}
                          fetchKey={this.fetchKey}
                          currentBranch={this.state.currentBranch}
                          changeBranch={this.changeBranch}
                          changePage={this.changePage}
                          breadcrumbsIndex={this.state.breadcrumbsIndex}
                          selectedTab={this.state.selectedTab}
                          selectTab={this.selectTab}
                          tabs={this.state.tabs}
                          tabsList={this.state.tabsList}
                          heading={this.state.heading}
                          history={this.props.history}
                          pathName={
                            this.props.history &&
                            this.props.history.location &&
                            this.props.history.location.pathname
                          }
                        />
                      )}
                      <Suspense fallback={<Loader />}>
                        <Switch>
                          {ROUTER_CONFIG.common_routes.map(
                            ({ path, Component, id, fullWidth }) => (
                              <Route
                                exact
                                path={path}
                                key={id}
                                render={(props) => {
                                  return (
                                    <SiteWrapper className={`${fullWidth ? 'full-width-site-wrapper' : 'site-wrapper'}`}>
                                      <Component
                                        {...props}
                                        {...other}
                                        {...rootState}
                                        {...handlers}
                                      />
                                    </SiteWrapper>
                                  );
                                }}
                              />
                            )
                          )}
                          {Array.isArray(authRoutes) &&
                            authRoutes.length &&
                            authRoutes.map(
                              ({ path, Component, id, fullWidth }) =>
                                path && (
                                  <Route
                                    exact
                                    path={path}
                                    render={(props) => {
                                      return (
                                        <SiteWrapper className={`${fullWidth ? 'full-width-site-wrapper' : 'site-wrapper'}`}>
                                          <Component
                                            {...props}
                                            {...other}
                                            {...rootState}
                                            {...handlers}
                                            {...(path.includes("edit")
                                              ? { edit: true }
                                              : null)}
                                          />
                                        </SiteWrapper>
                                      );
                                    }}
                                    key={id}
                                  />
                                )
                            )}
                          <Route
                          path={"/404"} 
                          render={() => {
                            return (
                              <SiteWrapper className='site-wrapper'>
                                <PageNotFound
                                  loggedProfileReducer = {loggedProfileReducer}
                                  history = {browserHistory}
                                />
                              </SiteWrapper>
                            );
                          }} />
                          <Redirect to={{pathname: "/404", state: {from: window.location.href}}} />
                        </Switch>
                      </Suspense>
                    </div>
                  </div>
                </ThemeProvider>
              </CookiesProvider>
            )
          );
        }}
      </CacheBuster>
    );
  }
}

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  loggedProfileReducer: state.loggedProfileReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    loggedProfile: (data) => dispatch(loggedProfile(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withCookies(withRouter(withStyles(styles)(App)))));
