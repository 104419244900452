import React from "react";
import { Link as NavLink } from "react-router-dom";
import { withCookies } from "react-cookie";

import AccountCircle from "@material-ui/icons/AccountCircle";
import ReplyIcon from "@material-ui/icons/Reply";
import CancelIcon from "@material-ui/icons/Cancel";

import SearchBarComponent from "components/searchBar";
import ExitModal from "../exitModal";

import Sidebar from "./components/SideBar";

import Container from "./container";
import { TopHeaderContainer } from "./styles/style";
import { Search } from "images";

const TopHeader = (props) => {
  const {
    searchBarBox,
    assessmentFlow,
    history,
    onExit,
    onLogoClick,
    logo_src,
    onCancel,
    exitModal,
    loggedProfileReducer,
    onBackToHomeClick,
    onCrossClick,
    showSideBar,
    onProfileIconClick,
    onCloseSideBar,
    manageCreation,
    branchKey,
    sidebarClicked
  } = props;

  //to show search only oon homepage
  const currentPageUrl = window.location.pathname;
  const showSearch =
    window.location.pathname === "/" ||
    /^\/branches\/[a-zA-Z0-9]+$/.test(currentPageUrl);

  return (
    <TopHeaderContainer>
      <div className="header-wrapper">
        <div className="header-items-container">
          <div className="logo-container">
            <NavLink to="/" onClick={onLogoClick}>
              <img
                style={{ height: "52px", width: "100%" }}
                src={logo_src}
                alt="logo"
              />
            </NavLink>
          </div>
          {assessmentFlow ? (
            <div className="assessment-container">
              <span className="pathway-finder">Pathway Finder</span>
              <span className="best-lp">
                Find the best learning path for you{" "}
              </span>
            </div>
          ) : null}
          {searchBarBox && !assessmentFlow && showSearch && (
            <>
              <div className="search-box-container">
                <SearchBarComponent {...props} />
              </div>
            </>
          )}
          <div className="icon-container-wrapper">
            {!assessmentFlow ? (
              <>
                <div className="mobile-search-icon">
                  <Search onClick={() => history.push("/mobilesearch")} />
                </div>
                <div
                  className="icon-container redirect-to-home"
                  onClick={onBackToHomeClick}
                >
                  <ReplyIcon />
                  <span>Back To Home</span>
                </div>
              </>
            ) : (
              <div
                className="icon-container cancel-block"
                onClick={onCrossClick}
              >
                <CancelIcon style={{ color: "rgba(126, 134, 158, 0.25)" }} />
              </div>
            )}

            {loggedProfileReducer?.isAdminRole && !assessmentFlow ? (
              <div
                className="icon-container profile-icon-container"
                onClick={onProfileIconClick}
              >
                <AccountCircle />
              </div>
            ) : null}
          </div>
        </div>
        {showSideBar ? (
          <Sidebar
            onCloseSideBar={onCloseSideBar}
            manageCreation={manageCreation}
            branchKey={window.sessionStorage.getItem("branchKey") || branchKey}
            user_type={loggedProfileReducer?.user_type}
            branchName={window.sessionStorage.getItem("branchName")}
            loggedProfileReducer={loggedProfileReducer}
            fullName={loggedProfileReducer?.fullName}
            sidebarClicked={sidebarClicked}
          />
        ) : null}
      </div>
      <ExitModal onOpen={exitModal} onCancel={onCancel} onExit={onExit} />
    </TopHeaderContainer>
  );
};

export default withCookies(Container(TopHeader));
