import React from "react";

import NewFlashcardTile from "./components/FlashcardTile";

import { PrevIcon, NextIcon } from "pages/NewHomePage/images";
import Container from "./container";

import {
  MobileContainer,
  DesktopContainer,
  MainContainer,
} from "./styles/style.js";

function FlashcardCarousel({
  independentContentData,
  isMobile,
  isCourseAdminRole,
  handleEditClick,
  onContentClick,
}) {
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <div {...props}>{children}</div>
  );

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    variableWidth: true,
    slidesToShow: 6,
    cursor: PointerEvent,
    prevArrow: (
      <SlickButtonFix>
        <PrevIcon />
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <NextIcon />
      </SlickButtonFix>
    ),
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 995,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const ConditionalParent = ({ isMobile, children }) => {
    return !isMobile ? (
      <DesktopContainer {...settings}>{children}</DesktopContainer>
    ) : (
      <MobileContainer>{children}</MobileContainer>
    );
  };

  return (
    <MainContainer>
      <ConditionalParent isMobile={isMobile}>
        {independentContentData.map((item) => (
          <NewFlashcardTile
            key={item.key}
            item={item}
            isCourseAdminRole={isCourseAdminRole}
            onContentClick={onContentClick}
            handleEditClick={handleEditClick}
          />
        ))}
      </ConditionalParent>
    </MainContainer>
  );
}

export default Container(FlashcardCarousel);
