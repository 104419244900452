import styled from "styled-components";

export const FlashContainer = styled.div`

  
  .heading-container {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    @media screen and (max-width: 767px){
          padding-right: 24px;
    }

    h3 {
      flex-basis: calc(100% - 160px);

      @media screen and (max-width: 995px) {
        flex-basis: calc(100% - 115px);
      }

      @media screen and (max-width: 767px) {
        flex-basis: calc(100% - 94px);
      }
    }

    button {
      padding: 0;
      border: none;
      background: none;
      color: ${(props) => props.theme.COLOR_PALETTE.PINK_PALETTE.TERTIARY};
      cursor: pointer;

      @media screen and (min-width: 996px) {
        &:hover {
          .view-more-text {
            &::before {
              transition: 0.3s all ease-in-out;
              width: 100%;
            }
          }
        }
      }

      .view-more-text {
        margin-right: 8px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        &::before {
          content: "";
          width: 0;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: ${(props) =>
    props.theme.COLOR_PALETTE.PINK_PALETTE.TERTIARY};
          transition: 0.3s all ease-in-out;
        }

        @media screen and (max-width: 995px) {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }

        @media screen and (max-width: 767px) {
          font-weight: 500;
        }
      }

      .icon-container {
        width: 45px;
        height: 45px;
        padding-top: 8px;
        border-radius: 8px;
        display: inline-block;
        vertical-align: middle;
        background-color: ${(props) =>
    props.theme.COLOR_PALETTE.PINK_PALETTE.PRIMARY};
        text-align: center;

        svg {
          width: 14px;
          path {
            stroke: ${(props) =>
    props.theme.COLOR_PALETTE.PINK_PALETTE.TERTIARY};
          }
        }

        @media screen and (max-width: 995px) {
          width: 28px;
          height: 28px;
          padding-top: 6px;
          border-radius: 4px;

          svg {
            width: 8px;
            height: 15px;
          }
        }

        @media screen and (max-width: 767px) {
          width: 20px;
          height: 20px;
          padding-top: 3px;

          svg {
            width: 5px;
            height: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) { 
    padding: 0 0 0 16px;
    .site-wrapper {
      padding: 0;
    }
	}
`;
