import React from "react";

import logo from "images/TurtlemintAcademyLogo.svg";

import { addOverflow, removeOverflow } from "utils/OverflowUtil";

import { Mixpanel } from "Mixpanel";
import {
  LP_Assessment_Close_Clicked,
  MA_Logo_Clicked,
} from "constants/mixpanel";

const Container = (Main) =>
  class TopHeaderContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        exitModal: false,
        showSideBar: false,
      };
    }

    callMixpanelEvent = (eventName, attributes) => {
      Mixpanel.track(eventName, {
        ...attributes,
        email: this.props.cookies.get("user_email"),
        branch_key: this.props.user_branch_key,
        branch_name: this.props.user_branch_name,
      });
    };

    _onLogoClick = (e) => {
      e.preventDefault();
      this.callMixpanelEvent(MA_Logo_Clicked, {
        referring_page_link: window.location.pathname,
      });

      if (window.mpIsApp) {
        const featurePayload = {
          type: "close",
          payload: {
            screenUrl: "https://app.mintpro.in/academy", // screen to which app should navigate
          },
        };
        window.postMessage(JSON.stringify(featurePayload));
      } else {
        this.props.history.push("/");
      }
    };

    onCrossClick = () => {
      this.setState({ exitModal: true });
    };

    onCancel = () => {
      this.setState({ exitModal: false });
    };

    onExit = () => {
      this.callMixpanelEvent(LP_Assessment_Close_Clicked);
      this.setState({ exitModal: false });
      this.props.history.push("/");
    };

    onBackToHomeClick = () => {
      if (window.mpIsApp) {
        const featurePayload = {
          type: "close",
          payload: {
            screenUrl: "https://app.mintpro.in/academy", // screen to which app should navigate
          },
        };
        window.postMessage(JSON.stringify(featurePayload));
      } else {
        window.location.href =
          sessionStorage.getItem("back_url") || this.props.branch_home_url;
      }
    };

    onProfileIconClick = () => {
      this.setState({
        showSideBar: true,
      });
      addOverflow();
    };

    onCloseSideBar = () => {
      this.setState({
        showSideBar: false,
      });
      removeOverflow();
    };

    manageCreation = (e, url) => {
      e.preventDefault();
      if (!url) return;
      this.setState({
        showSideBar: false,
      });
      removeOverflow();
      this.props.history.push(url);
    };

    sidebarClicked = (e) => {
      e.stopPropagation();
      if (e.target.id === "sidebar-container") {
        this.setState({
          showSideBar: false,
        });
        removeOverflow();
      }
    };

    componentDidMount() {
      const { loggedProfileReducer } = this.props;

      if (loggedProfileReducer) {
        this.setState({
          logo_src: loggedProfileReducer.logo_url
            ? loggedProfileReducer.logo_url
            : logo,
        });
      }
    }

    componentDidUpdate() {
      const { loggedProfileReducer } = this.props;
      if (loggedProfileReducer) {
        const currentLogoUrl = this.state.logo_src;
        if (!!loggedProfileReducer.logo_url) {
          if (loggedProfileReducer.logo_url !== currentLogoUrl) {
            this.setState({
              logo_src: loggedProfileReducer.logo_url
                ? loggedProfileReducer.logo_url
                : logo,
            });
          }
        } else {
          const currentLogoUrl = this.state.logo_src;
          if (currentLogoUrl !== logo) {
            this.setState({
              logo_src: logo,
            });
          }
        }
      }
    }

    render() {
      const handlers = {
        onLogoClick: this._onLogoClick,
        onExit: this.onExit,
        onCancel: this.onCancel,
        onBackToHomeClick: this.onBackToHomeClick,
        onCrossClick: this.onCrossClick,
        onProfileIconClick: this.onProfileIconClick,
        onCloseSideBar: this.onCloseSideBar,
        manageCreation: this.manageCreation,
        sidebarClicked: this.sidebarClicked,
      };

      return <Main {...this.state} {...this.props} {...handlers} />;
    }
  };

export default Container;
