import styled from "styled-components";

export const ReelsContainer = styled.div`
  padding: 16px 0 16px 24px;
  background-color: ${(props) =>
    props.theme.COLOR_PALETTE.GREEN_PALETTE.SECONDARY};

  li {
    border-radius: 12px;
    position: relative;

    h6 {
      padding: 10px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${(props) => props.theme.COLOR.REEL_TITLE_BG};
      color: ${(props) => props.theme.COLOR.WHITE};
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    figure {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 12px;
      }
    }
  }

  @media screen and (max-width: 995px) {
    li {
      h6 {
        padding: 5px 10px;
        font-family: Roboto;
      }
    }
  }

  @media screen and (max-width: 767px) {
    ul {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        flex: 0 0 200px;
        margin-right: 10px;

        h6 {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          height: 42px;
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    ul {
      li {
        flex: 0 0 122px;
        margin-right: 10px;
      }
    }
  }
`;
