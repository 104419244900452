import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const CreateWebinar = React.lazy(() => import("pages/AdminControls/Webinar/CreateWebinar"));

export default [
  {
    path: "/webinars/create",
    Component: CreateWebinar,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.marketing,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/webinars/:webinarKey/edit",
    Component: CreateWebinar,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.marketing,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
];
