import styled from "styled-components";

export const HomePageContainer = styled.div`
  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;
    padding-top: 10px;
    border-radius: 8px;
    background: ${(props) => props.theme.COLOR.DARK_GREY};
    z-index: 1;
    text-align: center;

    &::before {
      display: none;
    }
  }
`;
