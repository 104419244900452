import React from "react";

import { getDeviceDetail } from "utils";
import { FEATURE_TAB_CLICKED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

import { HomePageTabContainer } from "./styles/style";

const LearnReelTab = (props) => {

  const callMixpanelEvent = (eventName, attributes) => {
    const device = getDeviceDetail();
    const {
      loggedProfileReducer: { userEmail, branch },
    } = props;

    Mixpanel.track(eventName, {
      ...attributes,
      email: userEmail,
      device: device,
      branch_key: branch?.key,
      branch_name: branch?.name
        ? branch.name
        : window.sessionStorage.getItem("branchName"),
    });
  };

  const handleReelClick = () => {
    const {
      loggedProfileReducer: { branch },
      history,
    } = props;
    callMixpanelEvent(FEATURE_TAB_CLICKED, {
      title: 'reels'
    })
    history.push(`/branches/${branch.key}/turtle-reels`);
  };

  return (
    <HomePageTabContainer className="homepage-tabs">
      <li>Learn</li>
      <li onClick={() => handleReelClick()}>Reels</li>
    </HomePageTabContainer>
  );
};

export default LearnReelTab;
