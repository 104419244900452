import React from "react";

import { MainPopUpContainer } from "./styles/style";

export const ExamPopUp = (props) => {
  const { examModalMessage, clickHandler } = props;
  return (
    <MainPopUpContainer class="modal-container" onClick={() => clickHandler()}>
      <div class="modal-content">
        <p>{examModalMessage}</p>
        <button className="close-cta" onClick={() => clickHandler()}>
          Ok
        </button>
      </div>
    </MainPopUpContainer>
  );
};

export default ExamPopUp;
