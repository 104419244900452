import styled from "styled-components";
import configureTheme from "theme";
const theme = configureTheme();

export const CourseBlock = styled.div`
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 996px) {
    &:hover {
      figure {
        img {
          width: 100%;
          transition: all 0.5s ease-in-out;
          transform: scale(1.05);
        }
      }
    }
  }

  .modal-inner-div {
    background: ${(props) => props.theme.COLOR.WHITE};
    text-align: center;
    outline: none;

    button {
      color: ${(props) => props.theme.COLOR.WHITE};
      border-radius: 4px;
      background-color: ${(props) => props.theme.COLOR.MODAL_BUTTON_COLOR};
      margin-top: 12px;
      border: none;
      cursor: pointer;
      padding: 12px;
      text-transform: uppercase;
    }
  }

  .edit-icon-container {
    width: 35px;
    height: 35px;
    padding-top: 3px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color: ${(props) => props.theme.COLOR.WHITE};
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    text-align: center;

    svg {
      width: 23px;
      height: 23px;
      margin-top: 1px;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      svg {
        transition: all 0.5s ease-in-out;
        fill: ${(props) => props.theme.COLOR.MEDIUM_GREY};
      }
    }
  }

  figure {
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    align-self: center;
    background: ${(props) => props.theme.COLOR.FIGURE_BACKGROUND};

    img {
      width: 100%;
      display: block;
      transition: all 0.5s ease-in-out;
      aspect-ratio: 16 / 9;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .course-info-section {
    h4 {
      margin-bottom: 8px;
    }

    .course-meta {
      display: flex;
      flex-direction: row;
      align-items: center;

      .total-views {
        color: ${(props) => props.theme.COLOR.TEXT_BLACk};
        font-family: Montserrat;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;

        > span {
          margin: 0 14px;
          color: ${(props) => props.theme.COLOR.LIGHT_GREY};
        }

        @media screen and (max-width: 995px) {
          font-family: Roboto;
        }

        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 16px;

          > span {
            margin: 0 8px;
          }
        }
      }
    }

    .price-div-container {
      margin-top: 10px;
      .price-span {
        margin-right: 12px;
        color: ${theme.COLOR.DARK_PINK};
        font: normal 700 18px Montserrat;

        @media screen and (max-width: 995px) {
          font: normal 600 16px Roboto;
        }
      }

      .original-price-span {
        color: ${(props) => props.theme.COLOR.DARK_GREY};
        font: normal 500 16px Montserrat;

        @media screen and (max-width: 995px) {
          font: normal 600 14px Roboto;
        }
      }
    }

    .progress-bar-container {
      > span {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: ${(props) => props.theme.COLOR.MEDIUM_GREY};

        @media screen and (max-width: 995px) {
          font-family: Roboto;
        }
      }

      .custom-progress-bar {
        height: 10px;

        &::before {
          height: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .course-info-section {
      padding: 9px 20px 0 0;
      position: relative;

      .course-meta,
      .progress-bar-container {
        position: absolute;
        right: 20px;
        bottom: 10px;
        left: 0;
      }
    }
  }

  //Note:: unstandardizeD media query is used to handle the use case of text getting overlapped with meta contents
  @media screen and (max-width: 370px) {
    .course-info-section {
      .course-meta,
      .progress-bar-container {
        position: static;
      }
    }
  }
`;

export const RatingBlock = styled.span`
  padding: 3px 8px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.theme.COLOR_PALETTE[`${props.colorSchema}`].SECONDARY};
  color: ${(props) => props.theme.COLOR.TEXT_BLACk};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  svg {
    width: 19px;
    height: 19px;
    margin-right: 8px;
    fill: ${(props) => props.theme.COLOR.TEXT_BLACk};
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
  }

  @media screen and (max-width: 995px) {
    font-family: Roboto;
  }

  @media screen and (max-width: 767px) {
    padding: 2px 4px;
    font-size: 12px;
    line-height: 16px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }
  }
`;

export const NewTagBlock = styled.span`
  padding: 3px 8px;
  border-radius: 6px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${(props) => props.theme.COLOR.WHITE};
  background: ${(props) => props.theme.COLOR.MAGENTA};
  @media screen and (max-width: 995px) {
    font-family: Roboto;
  }

  @media screen and (max-width: 767px) {
    padding: 2px 4px;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const CompletedContainer = styled.span`
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background: linear-gradient(88deg, #91c571 -1.75%, #009e69 122.96%);
  color: ${(props) => props.theme.COLOR.WHITE};
  position: absolute;
  z-index: 1;
  top: 0;
  right: -1px;
  padding: 8px 16px;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  @media screen and (max-width: 995px) {
    font-family: Roboto;
  }

  @media screen and (max-width: 767px) {
    right: 0;
    font-size: 12px;
    padding: 4px 8px;
  }
`;
