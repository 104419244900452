import styled from "styled-components";

export const PartnerChannelContainer = styled.div`
  padding: 40px 0 50px;
  position: relative;
  background: ${(props) => props.theme.COLOR_PALETTE.PURPLE_PALETTE.PRIMARY};

  img {
    max-width: 100%;
  }

  .site-wrapper {
    padding-bottom: 0;
  }

  h3 {
    margin-bottom: 48px;
    text-align: center;

    span {
      display: block;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }

    @media screen and (max-width: 995px) {
      span {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 27px;
      text-align: left;

      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
      }
    }
  }

  .channel-info-container {
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 3.5px 3.5px 0 rgba(141, 123, 246, 0.25);

    figure {
      width: 100%;
      display: block;
      overflow: hidden;

      img {
        width: 100%;
        max-height: 90%;
        border-radius: 12px;
        aspect-ratio: 1/1;
      }
    }

    .channel-subscriber-rating-container {
      width: 100%;
      padding: 15px 8px;
      border-bottom-right-radius: 28px;
      border-bottom-left-radius: 28px;
      display: none;
      background: ${(props) =>
        props.theme.COLOR_PALETTE.PURPLE_PALETTE.SECONDARY};
      color: ${(props) => props.theme.COLOR.DARK_GREY};
      font-family: Montserrat;
      text-align: center;
    }

    .rating-wrapper {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (max-width: 995px) {
        width: 100%;
      }
    }

    .subscriber-container {
      width: 60%;
      padding-right: 20px;
      position: relative;
      color: ${(props) => props.theme.COLOR.PARTNER_STORY_GREY};
      font-family: Montserrat;
      font-size: 20px;
      line-height: 24px;

      span {
        display: block;
        color: ${(props) => props.theme.COLOR.DARK_GREY};
        font-size: 16px;
      }

      &:before {
        content: "";
        width: 2px;
        height: 60px;
        margin: 0 auto 16px;
        display: block;
        background: ${(props) => props.theme.COLOR.WHITE};
        position: absolute;
        right: 0;
        top: -5px;
      }

      @media screen and (max-width: 995px) {
        padding-right: 10px;
        font-family: Roboto;
        font-size: 18px;
      }

      @media screen and (max-width: 540px) {
        padding-right: 15px;
        font-size: 14px;

        span {
          font-size: 11px;
        }
      }
    }

    .avg-rating-container {
      width: 40%;
      color: ${(props) => props.theme.COLOR.PARTNER_STORY_GREY};
      padding-left: 20px;
      align-self: center;
      font-size: 20px;
      line-height: 24px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      @media screen and (max-width: 995px) {
        padding-left: 10px;
        font-family: Roboto;
        font-size: 18px;
      }

      @media screen and (max-width: 540px) {
        padding-right: 15px;
        font-size: 14px;
      }
    }
  }

  .slick-slide {
    padding: 0 16px;
  }

  .slick-slide {
    transition: all 0.3s ease-in-out;
    transform: scale(0.75);

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .slick-current {
    transform: scale(1);
  }

  .slick-prev,
  .slick-next {
    top: 50%;
    transform: translate(0, -50%);

    &::before {
      display: none;
    }
  }

  .slick-prev {
    left: -24px;

    @media screen and (max-width: 1140px) {
      left: 5px;
    }

    svg {
      margin-left: -5px;
    }
  }

  .slick-next {
    right: -24px;

    @media screen and (max-width: 1140px) {
      right: 5px;
    }

    svg {
      margin-right: -5px;
    }
  }

  .slick-current {
    .channel-info-container {
      border-radius: 28px;

      figure {
        padding: 10px 8px;
        background-color: ${(props) => props.theme.COLOR.WHITE};
        border-radius: 28px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        max-height: 220px;

        @media screen and (max-width: 767px) {
          max-height: 350px;
        }

        @media screen and (max-width: 540px) {
          max-height: 220px;
        }

        //Used non-standard media query to adjust the shape of the slider item
        @media screen and (max-width: 450px) {
          max-height: 150px;
        }

        img {
          width: auto;
          max-height: 220px;
          border-radius: 28px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin: 0 auto;

          @media screen and (max-width: 767px) {
            max-height: 350px;
          }

          //Used non-standard media query to adjust the shape of the slider item
          @media screen and (max-width: 450px) {
            max-height: 150px;
          }
        }
      }
    }

    .channel-subscriber-rating-container {
      display: block;
    }

    .no-rating {
      padding: 12px 0;

      &::before {
        display: none;
      }
    }
  }

  .view-more-container {
    margin-top: 15px;
    text-align: center;

    @media screen and (max-width: 995px) {
      margin-top: 25px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 25px;
    }

    button {
      width: 250px;

      @media screen and (max-width: 995px) {
        width: 150px;
      }
    }
  }
`;
