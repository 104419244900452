import mixpanel from 'mixpanel-browser';
import { pushToGTMDataLayer, IdentifyUserForMXP } from './analytics-wrapper/index';
import { deviceType, osName } from "react-device-detect";
import { getDeviceDetail } from 'utils'

let env_check = process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'stage';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

let actions = {
  identify: (params) => {
    const { partnerId } = params || {};
    const _gtmObj = {};
    _gtmObj.source = 'turtlemintacademy';
    _gtmObj.p_id = partnerId;
    window.console.log('identify_mint', _gtmObj);
    IdentifyUserForMXP(_gtmObj);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) {
      const partnerIdFromCookie = getCookie('partner_id');
      props['source'] = 'turtlemintacademy';
      props['eventName'] = name;
      if (partnerIdFromCookie) {
        props['partnerId'] = partnerIdFromCookie;
      }
      if (deviceType) {
        props['platform'] = deviceType;
      }
      if (osName) {
        props['os'] = osName;
      }

      //get branch details and add default mixpanel properties
      let branchData = localStorage.getItem('persist:admin-root');
      if (branchData) {
        branchData = JSON.parse(branchData);
        const loggedUserDetails = branchData.loggedProfileReducer && JSON.parse(branchData.loggedProfileReducer)

        if (loggedUserDetails) {
          if (!props['email'] && loggedUserDetails.userEmail) {
            props['email'] = loggedUserDetails.userEmail
          }

          if (!props['branch_key'] && loggedUserDetails.branch && loggedUserDetails.branch.key) {
            props['branch_key'] = loggedUserDetails.branch.key
          }

          if (!props['branch_name'] && loggedUserDetails.branch && loggedUserDetails.branch.name) {
            props['branch_name'] = loggedUserDetails.branch.name
          }
        }
      }

      props['device'] = getDeviceDetail()
      props['isAndroidApp'] = false
      //mixpanel.track(name, props);
      pushToGTMDataLayer(props);
      
      if (window.Apxor && window.Apxor.logAppEvent) {
        window.console.log('APXOR_EVENTS', name, props);
        window.Apxor.logAppEvent(name, props);
      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
