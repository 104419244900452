import React from "react";
import { MdOutlineAccountCircle } from "react-icons/md";

import { SideBarContainer } from "./styles/style.js";

import { CloseIcon } from "images";

const Sidebar = (props) => {
  const {
    onCloseSideBar,
    branchKey,
    manageCreation,
    user_type,
    branchName,
    fullName,
    sidebarClicked
  } = props;

  return (
    <SideBarContainer id="sidebar-container" onClick={sidebarClicked}>
      <div className="sidebar-wrapper">
        <div className="cross-icon-container">
          <span className="profile-name">
            <MdOutlineAccountCircle />
            {fullName}
          </span>
          <span className="sidebar-close-icon" onClick={onCloseSideBar}>
            <CloseIcon />
          </span>
        </div>
        <div className="sidebar-content-container">
          {user_type === "super_admin" ||
          user_type === "branch_admin" ||
          user_type === "tech" ? (
            <div className="manage-cta-container">
              <h4>User Management</h4>
              <ul>
                <li>
                  <a
                    href="/#"
                    title="Manage Users"
                    onClick={(e) =>
                      manageCreation(e, `/branches/${branchKey}/users`)
                    }
                  >
                    Manage Users
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
          <h4>Content Creation:</h4>
          <ul>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/createcontent`)
                }
                title="Create Content"
              >
                Create Content
              </a>
            </li>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/flashcards`)
                }
                title="Create Flashcard"
              >
                Create Flashcard
              </a>
            </li>
            {branchName === "MintPro" ? (
              <li>
                <a
                  href="/#"
                  onClick={(e) =>
                    manageCreation(e, `/branches/${branchKey}/reels-listing`)
                  }
                  title="Create Turtle-Reels"
                >
                  Create Turtle-Reels
                </a>
              </li>
            ) : null}
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/customerpitch`)
                }
                title="Create Customer Pitch"
              >
                Create Customer Pitch
              </a>
            </li>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/courses/create`)
                }
                title="Create Course"
              >
                Create Course
              </a>
            </li>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/learningpath`)
                }
                title="Create & Manage Learning Path"
              >
                Create & Manage Learning Path
              </a>
            </li>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/quiz-listing`)
                }
                title="Create & Manage Quizzes"
              >
                Create & Manage Quizzes
              </a>
            </li>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/webinars/create`)
                }
                title="Create Webinar"
              >
                Create Webinar
              </a>
            </li>
          </ul>

          <h4>Content Categorisation:</h4>
          <ul>
            {user_type === "super_admin" ||
            user_type === "branch_admin" ||
            user_type === "tech" ? (
              <li>
                <a
                  href="/#"
                  onClick={(e) =>
                    manageCreation(
                      e,
                      `/branches/${branchKey}/admin-category-list`
                    )
                  }
                  title="Create & Manage Categories"
                >
                  Create & Manage Categories
                </a>
              </li>
            ) : null}
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/channels/create`)
                }
                title="Create Partner Channel"
              >
                Create Partner Channel
              </a>
            </li>
          </ul>

          <h4>Homepage Management:</h4>
          <ul>
            <li>
              <a
                href="/#"
                onClick={(e) =>
                  manageCreation(e, `/branches/${branchKey}/banner-listing`)
                }
                title="Create Banner"
              >
                Create Banner
              </a>
            </li>
            {user_type === "super_admin" ||
            user_type === "branch_admin" ||
            user_type === "tech" ||
            user_type === "content" ? (
              <li>
                <a
                  href="/#"
                  onClick={(e) =>
                    manageCreation(
                      e,
                      `/branches/${branchKey}/admin-stories-list`
                    )
                  }
                  title="Create Partner Stories"
                >
                  Create Partner Stories
                </a>
              </li>
            ) : null}
            {user_type === "super_admin" ||
            user_type === "branch_admin" ||
            user_type === "tech" ? (
              <li>
                <a
                  href="/#"
                  onClick={(e) =>
                    manageCreation(e, `/branches/${branchKey}/action-item-list`)
                  }
                  title="Create and Manage Explore Academy"
                >
                  Create and Manage Explore Academy
                </a>
              </li>
            ) : null}
            {user_type === "super_admin" ||
            user_type === "branch_admin" ||
            user_type === "tech" ? (
              <li>
                <a
                  href="/#"
                  onClick={(e) =>
                    manageCreation(e, `/branches/${branchKey}/homepage-section-list`)
                  }
                  title="Create and Manage Page Skeleton"
                >
                  Create and Manage Page Skeleton
                </a>
              </li>
            ) : null}
          </ul>

          {branchName === "MintPro" ? (
            <>
              <h4>Miscellaneous:</h4>
              <ul>
                <li>
                  <a
                    href="/#"
                    onClick={(e) =>
                      manageCreation(
                        e,
                        `/branches/${branchKey}/posp-post-completion`
                      )
                    }
                    title="POSP Post-completion"
                  >
                    POSP Post-completion
                  </a>
                </li>
              </ul>
            </>
          ) : null}
        </div>
      </div>
    </SideBarContainer>
  );
};

export default Sidebar;
