import * as Actions from "../../constants/actions";

export const setSelectedBranchAction = (branchKey) => {
    return {
        type: Actions.SET_SELECTED_BRANCH_KEY,
        payload: branchKey,
    };
}

export const appendCertificationCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_CERTIFICATION_COURSES,
        payload: courses,
    }
}

export const appendPodcastCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_PODCAST_COURSES,
        payload: courses,
    }
}

export const appendEnggOnboardingCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_ENGG_ONBOARDING_COURSES,
        payload: courses,
    }
}

export const appendClaimDiaryCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_CLAIM_DIARY_COURSES,
        payload: courses,
    }
}

export const appendMasterclassCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_MASTERCLASS_COURSES,
        payload: courses,
    }
}

export const appendTag2021HighlightsCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_TAG_2021_HIGHLIGHT_COURSES,
        payload: courses,
    }
}

export const appendMotivationalTalkCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_MOTIVATIONAL_TALK_COURSES,
        payload: courses,
    }
}

export const appendPersonalDevelopmentCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_PERSONAL_DEVELOPMENT_COURSES,
        payload: courses,
    }
}

export const appendHowToCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_HOW_TO_COURSES,
        payload: courses,
    }
}

export const appendHealthProductTrainingCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_HEALTH_PRODUCT_TRAINING_COURSES,
        payload: courses,
    }
}

export const appendLifeProductTrainingCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_LIFE_PRODUCT_TRAINING_COURSES,
        payload: courses,
    }
}

export const appendApplicationCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_APPLICATION_COURSES,
        payload: courses,
    }
}

export const appendPaidCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_PAID_COURSES,
        payload: courses,
    }
}

export const appendQaCoursesAction = (courses) => {
    return {
        type: Actions.APPEND_QA_COURSES,
        payload: courses,
    }
}
