const convertToUtcDate = (inputDate) => {
  const newDate = new Date(inputDate);

  const formattedDate =
    [
      newDate.getUTCFullYear(),
      padTo2Digits(newDate.getUTCMonth() + 1),
      padTo2Digits(newDate.getUTCDate()),
    ].join("-") +
    "T" +
    [
      padTo2Digits(newDate.getUTCHours()),
      padTo2Digits(newDate.getUTCMinutes()),
      padTo2Digits(newDate.getUTCSeconds()),
    ].join(":");

  return formattedDate;
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export default convertToUtcDate;
