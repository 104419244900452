import COMMON_ROUTES from "./commonRoutes";
import SHARED_ROUTES from "./sharedRoutes";
import USER_ROUTES from "./userRoutes";

import {
  BRANCH_ROUTES,
  CHANNEL_ROUTES,
  COURSE_ROUTES,
  CUSTOMER_PITCH_ROUTES,
  INDEPENDENT_CONTENT_ROUTES,
  LEARNING_PATH_ROUTES,
  MARKETING_ROUTES,
  USER_MANAGEMENT_ROUTES,
  ADMIN_CONTROLS_ROUTES,
} from "./CMS";

/**
 * @description - Contains all the configurations for all the login roles inclusive of routes/containers/icons/titles etc.
 */

export const ROUTER_CONFIG = {
  super_admin: [
    ...SHARED_ROUTES,
    ...MARKETING_ROUTES,
    ...BRANCH_ROUTES,
    ...CHANNEL_ROUTES,
    ...COURSE_ROUTES,
    ...CUSTOMER_PITCH_ROUTES,
    ...INDEPENDENT_CONTENT_ROUTES,
    ...LEARNING_PATH_ROUTES,
    ...USER_MANAGEMENT_ROUTES,
    ...ADMIN_CONTROLS_ROUTES,
    ...USER_ROUTES,
  ],
  user: [...SHARED_ROUTES, ...USER_ROUTES],
  marketing: [
    ...SHARED_ROUTES,
    ...BRANCH_ROUTES,
    ...MARKETING_ROUTES,
    ...USER_ROUTES,
  ],
  content: [
    ...SHARED_ROUTES,
    ...MARKETING_ROUTES,
    ...BRANCH_ROUTES,
    ...CHANNEL_ROUTES,
    ...COURSE_ROUTES,
    ...CUSTOMER_PITCH_ROUTES,
    ...INDEPENDENT_CONTENT_ROUTES,
    ...LEARNING_PATH_ROUTES,
    ...USER_MANAGEMENT_ROUTES,
    ...ADMIN_CONTROLS_ROUTES,
    ...USER_ROUTES,
  ],
  "l_and_d": [
    ...SHARED_ROUTES,
    ...MARKETING_ROUTES,
    ...BRANCH_ROUTES,
    ...CHANNEL_ROUTES,
    ...COURSE_ROUTES,
    ...CUSTOMER_PITCH_ROUTES,
    ...INDEPENDENT_CONTENT_ROUTES,
    ...LEARNING_PATH_ROUTES,
    ...USER_MANAGEMENT_ROUTES,
    ...ADMIN_CONTROLS_ROUTES,
    ...USER_ROUTES,
  ],
  tech: [
    ...SHARED_ROUTES,
    ...MARKETING_ROUTES,
    ...BRANCH_ROUTES,
    ...CHANNEL_ROUTES,
    ...COURSE_ROUTES,
    ...CUSTOMER_PITCH_ROUTES,
    ...INDEPENDENT_CONTENT_ROUTES,
    ...LEARNING_PATH_ROUTES,
    ...USER_MANAGEMENT_ROUTES,
    ...ADMIN_CONTROLS_ROUTES,
    ...USER_ROUTES,
  ],
  branch_admin: [
    ...SHARED_ROUTES,
    ...MARKETING_ROUTES,
    ...BRANCH_ROUTES,
    ...CHANNEL_ROUTES,
    ...COURSE_ROUTES,
    ...CUSTOMER_PITCH_ROUTES,
    ...INDEPENDENT_CONTENT_ROUTES,
    ...LEARNING_PATH_ROUTES,
    ...USER_MANAGEMENT_ROUTES,
    ...ADMIN_CONTROLS_ROUTES,
    ...USER_ROUTES,
  ],
  support: [],
  common_routes: [...COMMON_ROUTES],
};
