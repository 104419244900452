// ANCHOR Common Variable
const __isMobile = width => (width || window.innerWidth) <= 768;
const __isTablet = width => {
  const clcWidth = width || window.innerWidth;
  let value;
  if (clcWidth > 768 && clcWidth < 960) {
    value = true;
  }
  return value;
};
const __getCookie = (name) =>{
  var pattern = RegExp(name + "=.[^;]*")
  var matched = document.cookie.match(pattern)
  if(matched){
      var cookie = matched[0].split('=')
      return decodeURIComponent(cookie[1])
  }
  return false
}


// NOTE GTM Layer
export const _gtmDataLayer = (event, _data) => {
    _data.event = event;
    console.log('GTM DataLayer', _data);
    try {
        if (window && window.dataLayer) {
          window.dataLayer.push(_data);
        }
    } catch(err) {
        console.error(err.message);
    }
}


// NOTE Common data
export const _commonData = (params) => {
  const { source } = params || {};
  const { localStorage } = window || {};
  let _store = {};

  if (['mintpro'].includes(source)) {
    const { userName } = localStorage || {};
    _store.userName = userName;
  } else if (['vertical'].includes(source)) {
    const dealerUserName = __getCookie('dealerUserName') || 'Not found';
    _store.userName = dealerUserName;
  } else if (['ninja'].includes(source)) {
    const userName = __getCookie('username');
    _store.userName = userName;
  } else if (['turtlemintacademy'].includes(source)) {
    const partnerId = __getCookie('partner_id');
    _store.userName = partnerId;
  }
  const { userName } = _store || {};
  const _obj = {};
  _obj.partnerId = userName;
  _obj.distinctId = userName; // NOTE Added Distinct ID Key
  _obj.isPushedByWrapper = true; // NOTE Added isPushedByWrapper Key for identifying the way of the implementation. 
  _obj.source = source; // NOTE Source provide where are you triggered from.
  return _obj;
}


// NOTE APP Information
export const _appInfo = () => {
    const { mpAppInfo } = window || {};
    const { version: appVersion, buildNumber, os } = mpAppInfo || {};
    const _obj = {};
    _obj._appInfo = {
      appVersion,
      buildNumber,
      os,
    };
    return mpAppInfo ? _obj : {};
}

// NOTE Checking for WEB || APP
export const _deviceInfo = () => {
    const { mpIsApp, navigator } = window || {};
    const { userAgent } = navigator || {};
    const _obj = {};
    _obj._deviceInfo = {
      isAndroidApp: !!mpIsApp,
      visualViewport: !!mpIsApp ? 'app' : __isMobile() ? 'mobile' : __isTablet() ? 'tablet' : 'desktop', // 'web || msite || app';
      userAgent,
    };
    return _obj;
}

// NOTE Extra info
export const _extraInfo = paramObj => {
  const { profileStatus, ipAddress, language, userFlow, vertical, REQUESTID, source } = paramObj || {}; // NOTE: Make sure these data comes from events.
  const { partnerId } = _commonData({ source });
  const _obj = {};
  _obj._sessionInfo = {
    profileStatus,
    ipAddress,
    partnerId,
    language,
    userFlow,
    vertical,
    REQUESTID,
  };
  return _obj;
}