import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Mixpanel } from "Mixpanel";
import { NOT_FOUND_PAGE_LOADED } from 'constants/mixpanel';

import './style/style.css'
import { handleBackToHome } from 'utils/app-redirection';

const PageNotFound = (props) => {

  useEffect(() => {
    const { loggedProfileReducer, history } = props;
    const { branch, userEmail } = loggedProfileReducer;

    Mixpanel.track(NOT_FOUND_PAGE_LOADED, {
      "original_url_accessed": history.location.state?.from,
      "branch_name": branch.name,
      "branch_key": branch.key,
      "email": userEmail
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-not-found">
      <h1>404 - Page not found!</h1>
      <Link title="Go To Homepage"
        onClick={(e) => { handleBackToHome(() => { window.location.replace('/')})}}>
        Go to Homepage
      </Link>
    </div>
  );
};

export default PageNotFound;
