import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const CreateCoursePage = React.lazy(() => import("pages/createCourse"));
const CreateContentPage = React.lazy(() => import("pages/createContent"));
const CreateModulePage = React.lazy(() => import("pages/createModule"));
const CreateExamPage = React.lazy(() => import("pages/createExam"));
const AddMCQPage = React.lazy(() => import("pages/addmcq"));

export default [
  /*=============================== Courses Create/Edit =============================== */
  {
    path: "/branches/:id/courses/create",
    Component: CreateCoursePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/courses/:course/edit",
    Component: CreateCoursePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/courses/:course/modules/create",
    Component: CreateModulePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/courses/:course/modules/:module/edit",
    Component: CreateModulePage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },

  //Modules Exam creation
  {
    path: "/branches/:id/courses/:course/modules/:module/exam/:exam/edit",
    Component: CreateExamPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/courses/:course/modules/:module/createexam",
    Component: CreateExamPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/courses/:course/modules/:module/exam/:exam/addmcq",
    Component: AddMCQPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  // Modules Content create/edit
  {
    path: "/branches/:id/courses/:course/modules/:module/editcontent/:content",
    Component: CreateContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/courses/:course/modules/:module/createcontent",
    Component: CreateContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
];
