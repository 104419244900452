
export const handleBackToHome = (webBackAction) => {
  if (window.mpIsApp) {
    const featurePayload = {
      type: 'close',
      payload: {
        screenUrl: "https://app.mintpro.in/academy", // screen to which app should navigate
      },
    };
    window.postMessage(JSON.stringify(featurePayload));
  }
  else {
    webBackAction();
  }
};
