import { combineReducers } from 'redux';

import * as staticReducers from './index';

const appReducer = combineReducers({
  ...staticReducers,
});

export const rootReducer = (state, action) => {
  //Resetting store on user logout
  return appReducer(state, action);
};
