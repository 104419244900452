// import React from 'react'

import { v4 as uuidv4 } from "uuid";

import { getAllRoles } from "utils";

import Base from "pages/index";

export default [
  {
    path: "/",
    Component: Base,
    id: uuidv4(),
    permission: [...getAllRoles],
  },
];
