import { Modal, Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { isMobile } from "../utils/common";

const ModalDiv = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
	width: ${() => (isMobile() ? "306px" : "386px")};
  height:${() => (isMobile() ? "245px" : "221px")};
`;
const TextDiv = styled.div`
	font-size: 18px;
	font-weight: bold;
	text-align: center;
`;

const ButtonDiv = styled.div`
	display: flex;
	margin-top: 16px;
	justify-content: center;
`;

class ExitModal extends React.Component {

	render() {
		return (
			<Modal
				open={this.props.onOpen}
				showCloseIcon={false}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<ModalDiv>
					<TextDiv>
						Are you sure you want to exit the assesment?
					</TextDiv>
					<div style={{ color: '#6F6F6F', fontSize: '14px', textAlign: 'center', padding: 16 }}>
						Your progress will be saved. You can complete this at anytime.
					</div>
					<ButtonDiv>
						<Button
							onClick={this.props.onCancel}
							style={{
								color: "#009C5A",
								backgroundColor: "#FFFFFF",
								border: "1px solid #009C5A",
								boxSizing: "border-box",
								boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
								borderRadius: "4px",
								textTransform: "none",
								width: '143px',
								height: '36px'
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={this.props.onExit}
							style={{
								marginLeft: "8px",
								border: "1px solid #FFFFFF",
								boxSizing: "border-box",
								color: "#FFFFFF",
								boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)",
								backgroundColor: "#009C5A",
								textTransform: "none",
								width: '143px',
								height: '36px'
							}}
						>
							Yes, Exit
						</Button>
					</ButtonDiv>
				</ModalDiv>
			</Modal>
		);
	}
}

export default ExitModal;
