import React from "react";
import { v4 as uuidv4 } from "uuid";

import { constants } from "utils";

const CreateIndependentContentPage = React.lazy(() =>
  import("pages/createIndependentContent")
);

const CreateFlashcardPage = React.lazy(() => import("pages/AdminControls/CreateFlashCard"))

export default [
  /* ===============================Independent Content Creation and edit ===============================*/
  {
    path: "/branches/:id/createcontent",
    Component: CreateIndependentContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/branches/:id/editcontent/:content",
    Component: CreateIndependentContentPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/flashcards/create",
    Component: CreateFlashcardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
  {
    path: "/flashcards/:flashcard/edit",
    Component: CreateFlashcardPage,
    id: uuidv4(),
    permission: [
      constants.ROLES.admin,
      constants.ROLES.branch_admin,
      constants.ROLES.tech,
      constants.ROLES.content,
      constants.ROLES["l&d"],
    ],
  },
];
