import React from "react";

import { PAGE_SKELETON, BRANCHES_ENDPOINT_V2 } from "constants/endpoints";
import { fetchGet } from "utils/ajax-util";
import { getDeviceDetail } from "utils";
import { ACADEMY_LOADED } from "constants/mixpanel";
import { Mixpanel } from "Mixpanel";

const Container = (Main) =>
  class HomePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobile: null,
        isLoading: false,
        pageSkeleton: [],
      };
    }

    checkWindowWidth = () => {
      if (window.innerWidth < 768 && !this.state.isMobile) {
        this.setState({
          isMobile: true,
        });
      } else if (window.innerWidth > 768 && this.state.isMobile) {
        this.setState({
          isMobile: false,
        });
      }
    };

    _fetchPageSkeleton = (adminBroker, adminTenant, branchName) => {
      this.setState({
        isLoading: true,
      });

      const {
        loggedProfileReducer: { authKey, broker, tenant, user_cohort },
      } = this.props;

      fetchGet(
        `${PAGE_SKELETON}?broker=${adminBroker || broker}&tenant=${
          adminTenant || tenant
        }&device=web&location=home&page_number=1&results_per_page=99&${
          branchName === "MintPro" ? `cohort=${user_cohort}` : ""
        }`,
        authKey
      )
        .then((data) => {
          const { success, page_skeleton } = data;

          if (success) {
            this.setState({
              isLoading: false,
              pageSkeleton: page_skeleton ? page_skeleton : [],
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log(error);
        });
    };

    fetchBranchDetails = (branchKey, authKey) => {
      fetchGet(`${BRANCHES_ENDPOINT_V2}${branchKey}/`, authKey)
        .then((response) => {
          if (response.success) {
            const {
              data: {
                branch_features,
                broker,
                tenant,
                name,
                key,
                support_email,
                logo_url,
                home_url,
              },
            } = response;

            window.sessionStorage.setItem("branchName", name);
            window.sessionStorage.setItem("branchKey", key);

            if (Array.isArray(branch_features) && branch_features.length) {
              const branchInfo = branch_features[0];
              const { enable_course_timer, show_search, enable_sharing } =
                branchInfo;

              const {
                setBranchName,
                setSupportEmail,
                setUserBranch,
                setCourseTimer,
                setLogo,
                setBranchHomeUrl,
                setSearchBarBox,
                setEnableSharing,
              } = this.props;

              setEnableSharing(enable_sharing);
              setBranchName(name);
              setSupportEmail(support_email || "pospsupport@turtlemint.com");
              setUserBranch(key, name);
              setCourseTimer(enable_course_timer);
              this.setState({
                branchInfo: response.data,
              });
              setLogo(logo_url);
              // Used in the 'Back to home' button on top header bar
              if (home_url) {
                setBranchHomeUrl(home_url);
              } else {
                setBranchHomeUrl("/");
              }
              if (show_search) {
                setSearchBarBox(true);
              }
            }

            if (broker && tenant) {
              this._fetchPageSkeleton(broker, tenant, name);
            }
          }
        })
        .catch((_data) => {
          console.log("err", _data);
        });
    };

    initialize = () => {
      const {
        loggedProfileReducer: { authKey, isAdminRole, branch },
        setBreadCrumbsIndex,
        setBranchPage,
        setCurrentBranch,
        setTabs,
        selectTab,
        setHeading,
        branchInfo,
        match,
      } = this.props;

      if (!authKey) {
        this.props.history.push({ pathname: "/login", search: "?redirect" });
      }
      setBreadCrumbsIndex(1);
      setBranchPage(true, match.params.id);
      setCurrentBranch(match.params.id);

      if (isAdminRole) {
        setTabs(true, "branch", 0);
        selectTab(0, true);
        setHeading("Branches");
      } else {
        const branch =
          JSON.parse(window.localStorage.getItem("branchInfo")) || branchInfo;
        if (!branch) return;
        const branchDataKey =
          (branch && branch.data && branch.data.key) || branchInfo.key;
        window.sessionStorage.setItem("userBranchKey", branchDataKey);
      }

      let branchKey = isAdminRole ? match.params.id : branch.key;

      this.fetchBranchDetails(branchKey, authKey);
    };

    callMixpanelEvent = (eventName, attributes) => {
      const device = getDeviceDetail();
      const {
        loggedProfileReducer: { userEmail, branch },
      } = this.props;

      Mixpanel.track(eventName, {
        ...attributes,
        email: userEmail,
        device: device,
        branch_key: branch?.key,
        branch_name: branch?.name
          ? branch.name
          : window.sessionStorage.getItem("branchName"),
      });
    };

    componentDidMount() {
      window.addEventListener("load", this.checkWindowWidth);
      window.addEventListener("resize", this.checkWindowWidth);

      this.callMixpanelEvent(ACADEMY_LOADED);
      this.initialize();
    }

    componentDidUpdate(prevProps) {
      const {
        branchInfo,
        loggedProfileReducer: { user_type },
      } = this.props;

      if (branchInfo?.id !== prevProps.branchInfo?.id && user_type === "user") {
        this.initialize();
      }
      this.checkWindowWidth();
    }

    componentWillUnmount() {
      window.removeEventListener("load", this.checkWindowWidth);
      window.removeEventListener("resize", this.checkWindowWidth);
    }

    render() {
      return <Main {...this.state} {...this.props} />;
    }
  };

export default Container;
