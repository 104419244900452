import styled from "styled-components";

export const MainDivContainer = styled.div`
  margin: 32px 0;

	.site-wrapper {
		padding-bottom: 0;

		@media screen and (max-width: 540px) { 
				padding: 0 16px;
		}
		
	}

  .heading-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    h3 {
      flex-basis: calc(100% - 160px);
			padding-bottom: 18px;

      @media screen and (max-width: 995px) {
        flex-basis: calc(100% - 115px);
      }

      @media screen and (max-width: 767px) {
        flex-basis: calc(100% - 35px);
      }
    }
	}

  .purchased-title-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .purchased-div-container {
    display: block;

		.slick-disabled {
			display: none !important;
		}

		.slick-next, .slick-prev {
			top: 45%;
			right: -24px;
			transform: translate(0, -50%);

			@media screen and (max-width: 1140px) {
				right: 6px;
			}
		}

		.slick-prev {
			top: 45%;
			left: 0;
			transform: translate(-50%, -50%);

			@media screen and (max-width: 1140px) {
				left: -24px;
			}
		}

		.slick-slide {
			padding-right: 18px;
		}

    .grid-container-h3 {
			.grid-h3 {
				margin-bottom: 0;
			}
		}

    @media screen and (max-width: 767px) {
       display: block;
       padding-right: ${props => props.pospCourseLength === 1 ? '-1px' : 0};
    }
    
    .grid-h3 {
      width: 100%;

      figure {
        margin-bottom: 8px;
				border-radius: 12px;
        border: 0.5px solid #ccc;

				img {
          width: 100%;
          border-radius: 12px;
        }
      }

			&:nth-child(3n) {
				margin-right: 0;
			}

      @media screen and (max-width: 767px) {
				width: ${props => props.pospCourseLength === 1 ? 'calc(100% - 75px)' : '100%'};
				margin: 0 0 16px;
				
				&:nth-child(3n) {
					margin-right: 0;
				}

				figure {
					flex-basis: 100%;
				}

				.course-info-section {
					flex-basis: 100%;
					padding: ${(props) =>
            props.pospCourseLength > 2 ? "9px 0 0 0" : "9px 20px 0 0"};
				}

				.course-meta {
						position: static;
				}
			}
		}
  }
}
`;
