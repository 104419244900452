const getDeviceDetail = () => {
  const windowWidth = window.innerWidth;

  if(windowWidth > 995) {
    return 'Desktop'
  } else if(windowWidth > 767) {
    return 'Tablet'
  } else {
    return 'Mobile'
  }
}

export default getDeviceDetail;
