import React from "react";

import { fetchGet } from "utils/ajax-util";
import { COURSE_ENTITIES_ENDPOINT_V2 } from "constants/endpoints";
import { LockedCourses } from "./duck/constants";

const Container = (Main) =>
  class CategoryCourses extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        courseData: [],
        isPospLoading: true,
        currentIndex: 0,
        examModal: false,
        examModalMessage: "",
      };
    }

    slideCtaClickHandler = (index) => {
      this.setState({ currentIndex: index });
    };

    closeClickHandler = () => {
      this.setState({ examModal: false });
    };

    setExamModalAndMessage = (modalData) => {
      this.setState({
        examModalMessage: modalData.examModalMessage,
        examModal: true,
      });
    };

    fetchPospCourses = () => {
      const {
        loggedProfileReducer: { authKey, branch, isAdminRole },
        match,
      } = this.props;

      if (!(branch && branch.key) || !authKey) {
        this.setState({
          isPospLoading: false,
        });
        return;
      }

      const branchKey = isAdminRole ? match.params.id : branch?.key;

      fetchGet(
        `${COURSE_ENTITIES_ENDPOINT_V2}fetch_compliance_courses/?branch_key=${branchKey}`,
        authKey
      )
        .then((response) => {
          const { success, data } = response;
          if (success) {
            this.setState({
              courseData: data.results.length
                ? data.results
                : window.sessionStorage.getItem("branchName") === "MintPro"
                ? []
                : LockedCourses,
              isPospLoading: false,
            });
          } else {
            this.setState({
              isPospLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isPospLoading: false,
          });
          console.log(error);
        });
    };

    componentDidMount() {
      this.fetchPospCourses();
    }

    render() {
      return (
        <Main
          {...this.state}
          {...this.props}
          slideCtaClickHandler={this.slideCtaClickHandler}
          setExamModalAndMessage={this.setExamModalAndMessage}
          closeClickHandler={this.closeClickHandler}
        />
      );
    }
  };

export default Container;
