import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import "./styles.css";
import { withCookies } from "react-cookie";
import ChannelDetailsHeader from "../pages/channel/channelDetailsHeader";
import ChannelListHeader from "../pages/channel/channelListHeader";
import CourseListHeader from "./courseListHeader";
import MobileSearchHeader from "./mobileSearchHeader";

const breadcrumbs = ["Home", "Course", "Module"];

const useStyles = (theme) => ({
  root: {
    width: "100%",
    padding: "1em 0",
  },
  rowLayout: {
    width: "100%",
    display: "flex",
    minHeight: "80px",
    margin: "auto",
  },
  breadcrumbOl: {
    paddingTop: "0px!important",
  },
  icon: {
    transform: "translate(25%, 10%);",
  },
});

const HeaderWrapper = styled.div`
  padding-bottom: 0;
`

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchAnchor: null,
      branchName: "",
      updatedBranchList: [],
    };
  }

  handleBranchAnchorOpen = (event, id) => {
    this.setState({ branchAnchor: event.currentTarget });
  };

  handleBranchAnchorClose = () => {
    this.setState({ branchAnchor: null });
  };

  componentDidUpdate(prevProps) {
    if (this.props.branches !== prevProps.branches) {
      const {
        loggedProfileReducer: { hasSwitchBranchAccess, branch },
        branches,
      } = this.props;
      if (!hasSwitchBranchAccess && branch) {
        const filteredBranches = branches.filter(
          (item) => item.name === branch.name
        );
        this.setState({
          updatedBranchList: filteredBranches,
        });
      } else {
        this.setState({
          updatedBranchList: branches,
        });
      }
    }
  }

  getCommonHeader = () => {
    const {
      loggedProfileReducer: {
        hasUserManagementAccess,
        hasSwitchBranchAccess,
        user_type,
        isAdminRole,
        currentBranchName,
        branch
      },
      total_viewers
    } = this.props;

    return (
      <AppBar position="static" style={{ backgroundColor: "#272929" }}>
        <HeaderWrapper className="site-wrapper">
          <Grid className={this.props.classes.root}>
            <Grid
              container
              alignItems="center"
              className={this.props.classes.rowLayout}
            >
              <Grid item xs={8} md={10}>
                <Typography
                  variant="h5"
                  style={{ color: "#f8f6f4", fontSize: "1.5rem" }}
                >
                  {this.props.isBranchPage || this.props.disable_breadcrumbs ? (
                    this.props.heading
                  ) : (
                    <div>
                      {breadcrumbs.length !== 0 && (
                        <Breadcrumbs
                          classes={{ ol: this.props.classes.breadcrumbOl }}
                        >
                          {breadcrumbs.map((item, index) => {
                            if (index === 1 && this.props.courseName) {
                              item = this.props.courseName;
                            }
                            if (index + 1 === this.props.breadcrumbsIndex) {
                              return (
                                <Link
                                  key={index}
                                  style={{
                                    color: "#62636b",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.props.changePage(index + 1)
                                  }
                                >
                                  <Typography variant="body1">
                                    {item}
                                  </Typography>
                                </Link>
                              );
                            }
                            if (index + 1 < this.props.breadcrumbsIndex) {
                              return (
                                <Link
                                  key={index}
                                  style={{
                                    color: "#62636b",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.props.changePage(index + 1)
                                  }
                                >
                                  <Typography variant="body1">
                                    {item}
                                  </Typography>
                                </Link>
                              );
                            }
                            return null;
                          })}
                        </Breadcrumbs>
                      )}
                      <Typography
                        variant="h5"
                        style={{ color: "#f8f6f4", fontSize: "1.5rem" }}
                      >
                        {this.props.heading}
                      </Typography>
                    </div>
                  )}
                  {this.props.isBranchPage && isAdminRole && this.state.updatedBranchList ? (
                    <>
                      <Typography
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={this.handleBranchAnchorOpen}
                        variant="inherit"
                      >
                        &nbsp;/&nbsp;
                        {currentBranchName || branch?.name || ""}
                        <ArrowDropDownIcon />
                      </Typography>
                      <Menu
                        id="branches"
                        anchorEl={this.state.branchAnchor}
                        keepMounted
                        open={Boolean(this.state.branchAnchor)}
                        onClose={this.handleBranchAnchorClose}
                      >
                        {this.state.updatedBranchList.map((item) => {
                          return (
                            <MenuItem key={item.name}>
                              <Grid container>
                                <Grid
                                  onClick={() => {
                                    this.props.setBranchName(item.name);
                                    this.setState({ branchAnchor: null });
                                    this.props.changeBranch(item.key, true, false, item.logo_url, true, item.name);
                                  }}
                                  item
                                  md={10}
                                >
                                  {item.name}
                                </Grid>
                                {hasSwitchBranchAccess ||
                                user_type === "branch_admin" ? (
                                  <Grid align="right" item md={2}>
                                    <EditIcon
                                      onClick={() => {
                                        this.setState({ branchAnchor: null });
                                        this.props.editBranch(item.key);
                                      }}
                                    />
                                  </Grid>
                                ) : null}
                              </Grid>
                            </MenuItem>
                          );
                        })}
                        {user_type === "super_admin" || user_type === "tech" ? (
                          <MenuItem
                            onClick={() => {
                              this.setState({ branchAnchor: null });
                              this.props.createBranch();
                            }}
                          >
                            {" "}
                            <AddIcon /> Create Branch
                          </MenuItem>
                        ) : null}
                      </Menu>
                    </>
                  ) : (
                    null
                  )}
                </Typography>
              </Grid>
              {this.props.backButton || this.props.backToHome ? (
                <Hidden smDown>
                  <Grid item md={2}>
                    <div style={{ float: "right" }}>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.backToHome
                            ? this.props.history.push("/")
                            : this.props.goBack();
                        }}
                        variant="contained"
                      >
                        {this.props.backToHome
                          ? "Browse Courses"
                          : "Back to Course"}
                      </Button>
                    </div>
                  </Grid>
                </Hidden>
              ) : (
                this.props.browseWebinarButton && (
                  <Hidden smDown>
                    <Grid item md={2}>
                      <div style={{ float: "right" }}>
                        <Button
                          onClick={() => this.props.history.push("/webinars")}
                          variant="contained"
                        >
                          Browse Webinars
                        </Button>
                      </div>
                    </Grid>
                  </Hidden>
                )
              )}
              <Hidden smDown>
                {this.props.show_rating && this.props.total_ratings > 1 ? (
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Rating
                        value={this.props.avg_rating}
                        precision={0.5}
                        readOnly
                      />
                      <Typography
                        style={{ fontWeight: "700", margin: "0 1em 0 1em" }}
                      >
                        {this.props.avg_rating}
                      </Typography>
                      <Typography>
                        ({this.props.total_ratings} ratings)
                      </Typography>
                      <Typography style={{ margin: "0 1em 0 1em" }}>
                        {total_viewers} people subscribed
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null}
              </Hidden>
              {this.props.tabs && (
                <Grid
                  item
                  xs={12}
                  style={{ position: "relative", top: "14px" }}
                >
                  <Tabs
                    value={
                      this.props.qaPage && this.props.moderator
                        ? this.props.selectedTab > 2
                          ? this.props.selectedTab - 2
                          : this.props.selectedTab
                        : this.props.selectedTab || 0
                    }
                    textColor="inherit"
                  >
                    {this.props.tabsList.map((item, index) => {
                      if (
                        (item === "Questions" && !this.props.qaPage) ||
                        (item === "Answers" && !this.props.qaPage)
                      ) {
                        return null;
                      }
                      if (
                        this.props.qaPage &&
                        this.props.moderator &&
                        (index === 1 || index === 2)
                      ) {
                        return null;
                      }

                      if (!hasUserManagementAccess && item === "Users")
                        return null;

                      return (
                        <Tab
                          key={index}
                          onClick={() => this.props.selectTab(index)}
                          textColor="inherit"
                          label={item === "Courses" ? "My Courses" : item}
                        />
                      );
                    })}
                  </Tabs>
                </Grid>
              )}
            </Grid>
          </Grid>
        </HeaderWrapper>
      </AppBar>
    );
  };

  renderHeader = () => {
    const pathname = window.location.pathname;


    if (/^\/channels\/[a-zA-Z0-9]+$/.test(pathname)) {
      return <ChannelDetailsHeader />;
    } else if (/^\/branches\/[a-zA-Z0-9]+\/channels$/.test(pathname)) {
      return <ChannelListHeader />;
    } else if (
      /^\/branches\/[a-zA-Z0-9]+\/category\/[a-zA-Z0-9_]+$/.test(pathname)
    ) {
      return <CourseListHeader heading={this.props.heading} />;
    } else if (
      /^\/channels\/[a-zA-Z0-9]+\/channel-category\/[a-zA-Z0-9_]+$/.test(pathname)
    ) {
      return <CourseListHeader heading={this.props.heading} />;
    } else if (
      /^\/branches\/[a-zA-Z0-9]+\/contents\/[a-zA-Z0-9_]+$/.test(pathname)
    ) {
      return <CourseListHeader heading={this.props.heading} />;
    } 
    else if (/^\/mobilesearch$/.test(pathname)) {
      return <MobileSearchHeader {...this.props} />;
    }  else if((/^\/branches\/[a-zA-Z0-9]+$/.test(pathname))|| (/^\/search+$/.test(pathname))) {
      return this.getCommonHeader()
    }
    return <></>
  };

  render() {
    return this.renderHeader();
  }
}

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  loggedProfileReducer: state.loggedProfileReducer,
});

export default connect(
  mapStateToProps,
  null
)(withCookies(withStyles(useStyles)(Header)));
